import React from "react";
import PropTypes from "prop-types";
import { getTranslation } from "../../utils/translate/translation";

const translate = {
  ar: {
    PALESTINE: "دولة فلسطين",
    UNITED_ARAB_EMIRATES: "الإمارات العربية المتحدة",
    BAHRAIN: "البحرين",
    KUWAIT: "الكويت",
    SAUDI_ARABIA: "السعودية",
    YEMEN: "اليمن",
    QATAR: "قطر",
    OMAN: "سلطنة عمان",
    JORDAN: "الأردن",
    SYRIA: "سوريا",
    LEBANON: "لبنان",
    IRAQ: "العراق",
    EGYPT: "مصر",
    MOROCCO: "المغرب",
    LIBYA: "ليبيا",
    TUNISIA: "تونس",
    ALGERIA: "الجزائر",
    SUDAN: "السودان",
    MAURITANIA: "موريتانيا",
    DJIBOUTI: "جيبوتي",
    SOMALIA: "الصومال",
    NUMBER_OF_UNIVERSITIES_BY_COUNTRY: " عدد الجامعات حسب البلد ",
  },
  en: {
    PALESTINE: "Palestine",
    UNITED_ARAB_EMIRATES: "United Arab Emirates",
    BAHRAIN: "Bahrain",
    KUWAIT: "Kuwait",
    SAUDI_ARABIA: "Saudi Arabia",
    YEMEN: "Yemen",
    QATAR: "Qatar",
    OMAN: "Oman",
    JORDAN: "Jordan",
    SYRIA: "Syria",
    LEBANON: "Lebanon",
    IRAQ: "Iraq",
    EGYPT: "Egypt",
    MOROCCO: "Morocco",
    LIBYA: "Libya",
    TUNISIA: "Tunisia",
    ALGERIA: "Algeria",
    SUDAN: "Sudan",
    MAURITANIA: "Mauritania",
    DJIBOUTI: "Djibouti",
    SOMALIA: "Somalia",
    NUMBER_OF_UNIVERSITIES_BY_COUNTRY: "Number of universities by country",
  },
  fr: {
    PALESTINE: "Palestine",
    UNITED_ARAB_EMIRATES: "Émirats arabes unis",
    BAHRAIN: "Bahreïn",
    KUWAIT: "Koweït",
    SAUDI_ARABIA: "Arabie saoudite",
    YEMEN: "Yémen",
    QATAR: "Qatar",
    OMAN: "Oman",
    JORDAN: "Jordanie",
    SYRIA: "Syrie",
    LEBANON: "Liban",
    IRAQ: "Irak",
    EGYPT: "Égypte",
    MOROCCO: "Maroc",
    LIBYA: "Libye",
    TUNISIA: "Tunisie",
    ALGERIA: "Algérie",
    SUDAN: "Soudan",
    MAURITANIA: "Mauritanie",
    DJIBOUTI: "Djibouti",
    SOMALIA: "Somalie",
    NUMBER_OF_UNIVERSITIES_BY_COUNTRY: "Nombre d'universités par pays",
  },
};
const CountryNumber = ({ country }) => {
  return (
    <div className="w-full">
      <span className="font-bold text-indigo-900 text-1xl">
        {getTranslation(translate, "NUMBER_OF_UNIVERSITIES_BY_COUNTRY")}
      </span>
      <div className="grid gap-6 pt-2 " data-testid="country">
        {country?.map((element) => (
          <div className="flex" key={element}>
            <span className="font-bold text-black">
              {getTranslation(translate, element[0])} :{" "}
              <span>{element[1]}</span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
CountryNumber.propTypes = {
  country: PropTypes.array,
};
export default CountryNumber;
