import React from "react";
import PropTypes from "prop-types";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { getTranslation } from "../../../../utils/translate/translation";
const translate = {
  ar: {
    DIPLOMA: "الشهادة",
    CLOSE: "غلق النافذة ",
  },
  en: {
    DIPLOMA: "Diploma",
    CLOSE: "Close",
  },
  fr: {
    DIPLOMA: "Diplome",
    CLOSE: "Ferme",
  },
};
const DiplomaPdfModal = ({ setOpen, pdf }) => {
  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="relative lg:w-auto sm:w-[360px] sm:h-fit mx-auto  max-w-auto">
          {/*content*/}
          <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-2 bg-[#435ebe] border-slate-200 rounded-t-md ">
              <h3 className="text-lg font-semibold text-white">
                {getTranslation(translate, "DIPLOMA")}
              </h3>
              <h3 className="text-lg font-semibold text-white"></h3>
            </div>
            {/*body*/}
            <div className="relative w-full flex-auto ">
              <Document file={pdf} className="  rounded-xl">
                <Page pageNumber={1} />
              </Document>
            </div>
            {/*footer*/}
            <div className="flex items-end justify-end p-2 border-t border-solid rounded-b border-slate-200">
              <button
                onClick={() => setOpen(false)}
                className="bg-[#e6eaee] text-[#1a2026]  leading-normal font-medium uppercase text-base p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
              >
                {getTranslation(translate, "CLOSE")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 bg-black opacity-25 z-40"></div>
    </>
  );
};
DiplomaPdfModal.propTypes = {
  setOpen: PropTypes.func,
  pdf: PropTypes.string,
};
export default DiplomaPdfModal;
