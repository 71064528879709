/* eslint-disable react/jsx-no-target-blank */
import React from "react";

// eslint-disable-next-line react/prop-types
function FantomScannerLink({ hashTx }) {
  return (
    <div className="flex items-center justify-center w-20 h-10 my-4 text-white bg-blue-500 rounded-md cursor-pointer hover:shadow-lg">
      <a
        target="_blank"
        href={`https://testnet.ftmscan.com/tx/${hashTx}`}
        className="text-blue-900 hover:text-blue-900"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="white"
          className="w-6 h-6 "
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
          />
        </svg>
      </a>
    </div>
  );
}

export default FantomScannerLink;
