/* eslint-disable react/prop-types */
import React from "react";
import { Select, Option } from "@material-tailwind/react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";

function SelectGender({ value, setSex, language }) {
  const [valueSelect, setValueSelect] = useState("");
  const handleOnChange = (e) => {
    setSex(e);
  };

  useEffect(() => {
    typeof value === "string"
      ? setValueSelect(value)
      : setValueSelect(value.en);
  });
  return (
    <form className="w-full ">
      <div className="relative mb-6">
        {language === "ar" && (
          <Select
            defaultValue={"ذكر"}
            placeholder={"ذكر"}
            variant="static"
            label=""
            value={valueSelect}
            onChange={handleOnChange}
            className={
              "dropdown_style disabled:pt-4 hover:outline-blue-400 disabled:bg-slate-200 text-ellipsis overflow-hidden  outline-blue-300/70   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  w-full pr-10 p-2.5 flex items-start justify-start "
            }
          >
            <Option value="ذكر">{language === "ar" && "ذكر"}</Option>
            <Option value="أنثى">{language === "ar" && "أنثى"}</Option>
          </Select>
        )}
        {language === "fr" && (
          <Select
            defaultValue={"Homme"}
            placeholder={"Homme"}
            variant="static"
            label=""
            value={valueSelect}
            onChange={handleOnChange}
            className={
              "dropdown_style disabled:pt-4 hover:outline-blue-400 disabled:bg-slate-200 text-ellipsis overflow-hidden  outline-blue-300/70   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  w-full pr-10 p-2.5 flex items-start justify-start "
            }
          >
            <Option value="homme">{language === "fr" && "Homme"}</Option>
            <Option value="femme">{language === "fr" && "Femme"}</Option>
          </Select>
        )}
        {language === "en" && (
          <Select
            defaultValue={"male"}
            placeholder={"Male"}
            variant="static"
            label=""
            value={valueSelect}
            onChange={handleOnChange}
            className={
              "dropdown_style disabled:pt-4 hover:outline-blue-400 disabled:bg-slate-200 text-ellipsis overflow-hidden  outline-blue-300/70   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  w-full pr-10 p-2.5 flex items-start justify-start "
            }
          >
            <Option value="male">{language === "en" && "Male"}</Option>
            <Option value="female">{language === "en" && "Female"}</Option>
          </Select>
        )}
      </div>
    </form>
  );
}

SelectGender.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setSex: PropTypes.func,
  language: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
export default SelectGender;
