import React from "react";

import PropTypes from "prop-types";
import { getTranslation } from "../../utils/translate/translation";
import { isNumberValid } from "../../utils/Validators/Validators";

const translate = {
  ar: {
    DAY: "اليوم",
    MONTH: " الشهر",
    YEAR: "السنة",
  },
  en: {
    DAY: "Day",
    MONTH: " Month",
    YEAR: "Year",
  },
  fr: {
    DAY: "Jour",
    MONTH: "Mois",
    YEAR: "Année",
  },
};
function DateInput({
  dayOfBirth,
  setDayOfBirth,
  monthOfBirth,
  setMonthOfBirth,
  yearOfBirth,
  setYearOfBirth,
}) {
  const is_day_valid = dayOfBirth.length > 0;
  const is_month_valid = monthOfBirth.length > 0;
  const is_year_valid = yearOfBirth.length > 0;
  const day_className = is_day_valid
    ? "w-1/4 disabled:pt-4 hover:outline-blue-400 disabled:bg-slate-200 text-ellipsis overflow-hidden  outline-blue-300/70   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block  pr-10 p-2.5"
    : "hover:outline-blue-400 border-red-400 disabled:bg-slate-200 text-ellipsis overflow-hidden focus:border-2 outline-blue-300/70    bg-white border-2  text-gray-900 text-sm rounded-md block w-full pr-10 p-2.5";
  const month_className = is_month_valid
    ? "w-1/4 disabled:pt-4 hover:outline-blue-400 disabled:bg-slate-200 text-ellipsis overflow-hidden  outline-blue-300/70   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block  pr-10 p-2.5"
    : "hover:outline-blue-400 border-red-400 disabled:bg-slate-200 text-ellipsis overflow-hidden focus:border-2 outline-blue-300/70    bg-white border-2  text-gray-900 text-sm rounded-md block w-full pr-10 p-2.5";
  const year_className = is_year_valid
    ? "w-1/2 disabled:pt-4 hover:outline-blue-400 disabled:bg-slate-200 text-ellipsis overflow-hidden  outline-blue-300/70   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block  pr-10 p-2.5"
    : "hover:outline-blue-400 border-red-400 disabled:bg-slate-200 text-ellipsis overflow-hidden focus:border-2 outline-blue-300/70    bg-white border-2  text-gray-900 text-sm rounded-md block w-full pr-10 p-2.5";

  return (
    <div className="flex items-center justify-center w-full space-x-4 ">
      <input
        type="number"
        min={1900}
        className={year_className}
        placeholder={getTranslation(translate, "YEAR")}
        value={yearOfBirth}
        onChange={(e) => {
          setYearOfBirth(e.target.value);
        }}
      />
      <span className="p-4">/</span>
      <input
        type="number"
        max={12}
        min={1}
        className={month_className}
        placeholder={getTranslation(translate, "MONTH")}
        value={monthOfBirth}
        onChange={(e) => {
          if (
            parseInt(e.target.value) > 0 &&
            parseInt(e.target.value) < 13 &&
            isNumberValid(e.target.value)
          ) {
            setMonthOfBirth(e.target.value);
          }
        }}
      />
      <span className="p-4">/</span>
      <input
        type="number"
        max={31}
        min={1}
        className={day_className}
        placeholder={getTranslation(translate, "DAY")}
        value={dayOfBirth}
        onChange={(e) => {
          if (
            parseInt(e.target.value) > 0 &&
            parseInt(e.target.value) < 32 &&
            isNumberValid(e.target.value)
          ) {
            setDayOfBirth(e.target.value);
          }
        }}
      />
    </div>
  );
}
DateInput.propTypes = {
  dayOfBirth: PropTypes.object,
  monthOfBirth: PropTypes.object,
  yearOfBirth: PropTypes.object,
  setDayOfBirth: PropTypes.func,
  setMonthOfBirth: PropTypes.func,
  setYearOfBirth: PropTypes.func,
};
export default DateInput;
