/* eslint-disable no-undef */
import React from "react";
import ModalLayout from "../../../../layouts/ModalLayout";
import HeaderModal from "../LayoutsModal/HeaderModal";
import PropTypes from "prop-types";
import FooterModal from "../LayoutsModal/FooterModal";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { translate } from "../Translate/Translate";
import UpdateModeThree from "../updateDiplomaModal/modeThree/UpdateModeThree";
import DigitalDiplomaPreview from "../../../../pages/DigitalDiplomaPage/DigitalDiplomaPreview";
import DeleteDiplomaModal from "../confirmDiplomaModal/DeleteDiplomaModal";

const RejectedDiplomaModalModeThree = ({
  item,
  role,
  setOpen,
  open,
  _deleteDiploma,
  showUpdate,
  setShowUpdate,
  filtered,
  setFiltered,
}) => {
  const [isShowing, setIsShowing] = useState(false);
  useEffect(() => {
    setIsShowing(true);
  }, [isShowing]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [diplomaDelete, setDiplomaDelete] = useState([]);
  const handleOpenDeleteModal = (item) => {
    setDiplomaDelete(item);
    setOpenDeleteModal(!openDeleteModal);
  };
  return (
    <Fragment>
      <ModalLayout>
        <div className="relative w-auto w-max-[auto]  mx-auto my-6   overflow-hidden">
          {openDeleteModal ? (
            <DeleteDiplomaModal
              setOpenModal={setOpen}
              openModal={open}
              DiplomaToDelete={diplomaDelete}
              filtered={filtered}
              setFiltered={setFiltered}
              deleteDiploma={_deleteDiploma}
            />
          ) : (
            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
              <HeaderModal translate={translate} item={item} />
              {showUpdate && item?.mode === "modeThree" ? (
                <div className="w-full h-full p-2">
                  <UpdateModeThree
                    translate={translate}
                    item={item}
                    showUpdate={showUpdate}
                    setShowUpdate={setShowUpdate}
                    open={open}
                    setOpen={setOpen}
                    filtered={filtered}
                    setFiltered={setFiltered}
                  />
                </div>
              ) : (
                <div className="w-full h-full p-2">
                  <DigitalDiplomaPreview diploma={item} />
                </div>
              )}

              <FooterModal
                translate={translate}
                role={role}
                item={item}
                open={open}
                setOpen={setOpen}
                _deleteDiploma={_deleteDiploma}
                setShowUpdate={setShowUpdate}
                showUpdate={showUpdate}
                handleOpenDeleteModal={handleOpenDeleteModal}
              />
            </div>
          )}
        </div>
      </ModalLayout>
    </Fragment>
  );
};
RejectedDiplomaModalModeThree.propTypes = {
  item: PropTypes.object,
  role: PropTypes.string,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  filtered: PropTypes.array,
  setFiltered: PropTypes.func,
  _deleteDiploma: PropTypes.func,
  showUpdate: PropTypes.bool,
  setShowUpdate: PropTypes.func,
};
export default RejectedDiplomaModalModeThree;
