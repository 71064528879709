import React, { useState, useEffect } from "react";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import logo from "../../assets/certified_logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../store/service/usersService";
import { passwordIcon } from "../../assets/svg/Index";
import { toastErrorServer, toastSucces } from "../../components/toast/Toast";
import { getTranslation } from "../../utils/translate/translation";

const translate = {
  ar: {
    TOAST_SUCCES: "تمت العملية بنجاح ",
    TOAST_ERROR: "انتهت صلاحية الرابط",
    PAGE_TITLE: "تغير كلمة السر ",
    PASSWORD: "كلمة السر",
    PASSWORD_CONFIRM: "إعادة كلمة السر ",
    BUTTON_TITLE: "تأكيد",
    BACK: "الرجوع إلى صفحة ",
    REGISTER: "  تسجيل الدخول .",
  },
  en: {
    TOAST_SUCCES: "Operation accomplished successfully",
    TOAST_ERROR: "Link has expired",
    PAGE_TITLE: "Change the password",
    PASSWORD: "Password",
    PASSWORD_CONFIRM: " Password confirmation  ",
    BUTTON_TITLE: "To be sure",
    BACK: "Back to page",
    REGISTER: "Sign in .",
  },
  fr: {
    TOAST_SUCCES: "Opération réussie",
    TOAST_ERROR: "Le lien a expiré",
    PAGE_TITLE: "Changer le mot de passe",
    PASSWORD: "Mot de passe",
    PASSWORD_CONFIRM: "Confirmation mot de passe",
    BUTTON_TITLE: "Etre sûr",
    BACK: "  Retour à la page  ",
    REGISTER: "Se connecter .",
  },
};
function ForgotPasswordPage() {
  let { token } = useParams();
  let navigate = useNavigate();
  let [updatePassword] = useResetPasswordMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [rePassword, setRePassword] = useState("");

  const ForgotPassword = () => {
    const data = {
      password,
      token,
    };
    updatePassword(data).then((res) => {
      if (res.data) {
        setIsLoading(true);

        setTimeout(() => {
          setIsLoading(false);
          toastSucces(`${getTranslation(translate, "TOAST_SUCCES")}`);
          navigate("/login");
        }, 3000);
      } else {
        setIsLoading(true);

        setTimeout(() => {
          setIsLoading(false);
          toastErrorServer(`${getTranslation(translate, "TOAST_ERROR")}`);
        }, 1000);
      }
    });
  };
  useEffect(() => {
    if (isLoading) {
      setPassword("");
      setRePassword("");
    }
  }, [isLoading]);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate(-1);
    }
  }, []);

  return (
    <div className="flex flex-col items-start justify-start space-y-8 p-14">
      <img src={logo} alt="" className="mb-2" />
      <h1 className="text-5xl font-bold text-blue-800">
        {getTranslation(translate, "PAGE_TITLE")}
      </h1>

      <Input
        icon={passwordIcon}
        placeholder={getTranslation(translate, "PASSWORD")}
        password
        value={password}
        inputChange={setPassword}
        setValid={setIsPasswordValid}
        type="PASSWORD"
      />
      <Input
        icon={passwordIcon}
        placeholder={getTranslation(translate, "PASSWORD_CONFIRM")}
        password
        value={rePassword}
        inputChange={setRePassword}
        setValid={setIsPasswordValid}
        type="PASSWORD"
      />
      <MainButton
        title={getTranslation(translate, "BUTTON_TITLE")}
        action={ForgotPassword}
        disabled={
          !isPasswordValid ||
          (password === "" && rePassword === "") ||
          password !== rePassword
        }
        isLoading={isLoading}
      />
      <div className="flex items-center justify-center w-full mt-20 text-2xl font-bold">
        <p> {getTranslation(translate, "BACK")}</p>
        <p
          className="mr-2 text-blue-800 cursor-pointer"
          onClick={() => {
            navigate("/login");
          }}
        >
          {getTranslation(translate, "REGISTER")}
        </p>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
