import { utils, writeFile } from "xlsx";

function downloadFileStudent() {
  let xlsData = [
    {
      firstName: "",
      lastName: "",
      nin: "",
      dateOfBirth: "",
      countryOfBirth: "",
      sex: "",
      nationalty: "",
      phone: "",
      email: "",
      language: "",
    },
  ];
  const worksheet = utils.json_to_sheet(xlsData);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Sheet1");
  writeFile(workbook, "data.xls");
}

export { downloadFileStudent };
