export const multilanguageReader = (field) => {
  if (field) {
    return Object.values(field)[0];
  }
};

export const keyReturn = (key) => {
  return Object.keys(key)[0];
};

export const lengthReturn = (value) => {
  return Object.keys(value).length;
};

export function getCommunValues(array1, array2) {
  return array1.filter((value) =>
    array2.map((item) => item.toLowerCase()).includes(value.toLowerCase())
  );
}

export function isThereCommunValues(array1, array2) {
  return getCommunValues(array1, array2).length > 0;
}
