import React from "react";
import { getTranslation } from "../../utils/translate/translation";

import StudentsTable from "./StudentsTable";

const StudentsList = () => {
  const translate = {
    ar: {
      STUDENTS_LIST: "قائمة الطلبة",
      VIEW_SEARCH_MODIFY: "للاطلاع والبحث والتعديل على قائمة الطلبة",
    },
    fr: {
      STUDENTS_LIST: "Liste des étudiants",
      VIEW_SEARCH_MODIFY:
        "Pour afficher, rechercher et modifier la liste des étudiants",
    },
    en: {
      STUDENTS_LIST: "Students List",
      VIEW_SEARCH_MODIFY: "To view, search, and modify the students list",
    },
  };

  return (
    <div>
      <div className="flex flex-col justify-end mt-10 px-[3%]">
        <h1 className="mb-2 text-2xl font-bold text-[#25396f]">
          {" "}
          {getTranslation(translate, "STUDENTS_LIST")}
        </h1>
        <h5 className="mb-2 text-[#7c8db5] text-1xl">
          {getTranslation(translate, "VIEW_SEARCH_MODIFY")}
        </h5>
        <div className="flex flex-col w-full p-2 bg-white rounded-md h-fit">
          <div className="w-full">
            <StudentsTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentsList;
