/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ADD_DIPLOMAS_FROM_CSV } from "../../store/features/diplomasSlice/diplomasSlice";
import { useGetDiplomasWithErrorMutation } from "../../store/service/diplomasService";
import { read, utils, set_cptable } from "xlsx";
import * as cptable from "xlsx/dist/cpexcel.full.mjs";
set_cptable(cptable);
import FileInput from "../../components/input/FileInput";
import { isSignatureDateBeforeApprovalDate } from "../../utils/dateUtils/checkDate";
import { downloadFileDiploma } from "../../utils/downloadFile";
import { toastErrorServer } from "../../components/toast/Toast";
import { isXlsFieldNotValid } from "../../utils/Validators/Validators";
import {
  DIPLOMA_METADATA_HEADER,
  DIPLOMA_METADATA_REQUIRED_FIELDS,
  isDiplomaFileHeaderValid,
} from "../../utils/SheetUtils/header_validators";
import {
  convertDateFromXlsFileToString,
  formulateDiplomaJsonAndDeleteDuplication,
  isApprovalDateValid,
  isSignatureDateValid,
} from "../../utils/SheetUtils/SheetReader";

import { getTranslation } from "../../utils/translate/translation";
import { getFields } from "../../utils/converts/convert";

const translate = {
  ar: {
    TOAST_ERROR: "الرجاء التثبت في المعطيات !",
    MESSAGE_: "  قم برفع ملف اكسل مثل هذا الأنموذج'Excel' ",
    CLICK: "اضغط هنا",
    MESSAGE_ONE: "   يتضمن بيانات الشهادات.",
    DATESIGNATURE: "تاريخ المصادقة يجب أن يكون قبل تاريخ الإمضاء",
    APPROVAL: "تاريخ المصادقة ",
    SIGNATURE: "تاريخ الإمضاء ",
  },
  en: {
    TOAST_ERROR: "Please check the data!",
    MESSAGE_: "Upload an Excel file like this template 'Excel'",
    CLICK: "Click here",
    MESSAGE_ONE: "It includes certificate data.",
    DATESIGNATURE: "Approval date must be before the signature date",
    APPROVAL: "Approval date",
    SIGNATURE: "Signature date",
  },
  fr: {
    TOAST_ERROR: "Veuillez vérifier les données !",
    MESSAGE_: "Téléchargez un fichier Excel comme ce modèle 'Excel'",
    CLICK: "Cliquez ici",
    MESSAGE_ONE: "Il inclut les données des certificats.",
    DATESIGNATURE:
      "La date d'approbation doit être antérieure à la date de signature",
    APPROVAL: "Date d'approbation",
    SIGNATURE: "Date de signature",
  },
};
function AddDiplomasUploader({
  setOpen,
  setNonExistingStudents,
  setAlreadyExistingDiplomas,
  setIsLoadingData,
  setMissingFields,
  open,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [getErroredTable] = useGetDiplomasWithErrorMutation();
  const [isThereInputErrors, setIsThereInputErrors] = useState(false);
  const diplomasFromRedux = useSelector((state) => state.diploma);
  let mode = useSelector((state) => state.diploma.modeSelected);
  function onClear() {
    let emptyStudents = [];
    switch (mode) {
      case "modeOne":
        dispatch(ADD_DIPLOMAS_FROM_CSV({ diplomasToAdd: emptyStudents }));
        break;
      case "modeTwo":
        dispatch(ADD_DIPLOMAS_FROM_CSV({ diplomasToAddTwo: emptyStudents }));
        break;
      case "modeThree":
        dispatch(ADD_DIPLOMAS_FROM_CSV({ diplomasToAddThree: emptyStudents }));
        break;
    }
  }

  const validateAndExtractErrors = (diplomas) => {
    let missingFields = [];
    let cleanDiplomas = [];
    for (let i = 0; i < diplomas.length; i++) {
      const diploma = diplomas[i];
      let missingFieldsColumns = [];

      DIPLOMA_METADATA_REQUIRED_FIELDS.forEach((field) => {
        if (isXlsFieldNotValid(diploma[field])) {
          missingFieldsColumns.push(getFields([field]));
        }
      });

      if (
        isSignatureDateBeforeApprovalDate(
          convertDateFromXlsFileToString(diploma["approvalDate"]),
          convertDateFromXlsFileToString(diploma["signatureDate"])
        )
      ) {
        missingFieldsColumns.push(
          `${getTranslation(translate, "DATESIGNATURE")}`
        );
      }
      if (isApprovalDateValid(diploma["approvalDate"], i)) {
        missingFieldsColumns.push(`${getTranslation(translate, "APPROVAL")}`);
      }
      if (isSignatureDateValid(diploma["signatureDate"], i)) {
        missingFieldsColumns.push(`${getTranslation(translate, "SIGNATURE")}`);
      }
      if (missingFieldsColumns.length > 0) {
        missingFields.push({
          ligne: i + 1,
          colonnes: missingFieldsColumns.join(" , "),
        });
      } else {
        const cleanDiploma = {
          firstName: diploma["firstName"],
          lastName: diploma["lastName"],
          nin: diploma["nin"],
          type: diploma["type"],
          speciality: diploma["speciality"],
          year: diploma["year"],
          signatureDate: convertDateFromXlsFileToString(
            diploma["signatureDate"]
          ),
          honors: diploma["honors"],
          approvalDate: convertDateFromXlsFileToString(diploma["approvalDate"]),
          examsPvDate: convertDateFromXlsFileToString(diploma["examsPvDate"]),
          internshipPvDate: convertDateFromXlsFileToString(
            diploma["internshipPvDate"]
          ),
          paperPvDate: convertDateFromXlsFileToString(diploma["paperPvDate"]),
          average: diploma["average"],
          diplomaName: diploma["diplomaName"],
          language: diploma["language"] ?? "en",
          remark: diploma["remark"],
          ligne: i + 2,
          mode: mode,
        };

        cleanDiplomas.push(cleanDiploma);
      }
    }

    return { missingFields, cleanDiplomas };
  };
  function getErrorsAndDisplayResults(data, missingFields) {
    getErroredTable({ data }).then((res) => {
      if (
        isThereInputErrors ||
        res.data.data.errToReturn.length > 0 ||
        res.data.data.existingDiploma.length > 0
      ) {
        setOpen(!open);
        if (res.data.data.errToReturn.length > 0) {
          setNonExistingStudents(res.data.data.errToReturn);
        }
        if (res.data.data.existingDiploma.length > 0) {
          setAlreadyExistingDiplomas(res.data.data.existingDiploma);
        }
      }
      setIsLoadingData(false);
      if (
        missingFields.length > 0 ||
        res.data.data.existingDiploma.length > 0 ||
        res.data.data.errToReturn.length > 0
      ) {
        setMissingFields(missingFields);
        setIsThereInputErrors(true);
        setOpen(!open);
      }
      onSelectedModeDispatch(
        DIPLOMA_METADATA_HEADER,
        res.data.data.cleanStudents
      );
      setLoading(false);
    });
  }
  const modeSelected = useSelector((state) => state.diploma.modeSelected);

  function handleFileChange(file) {
    const reader = new FileReader();
    reader.onload = function (event) {
      const dataNew = new Uint8Array(event.target.result);
      const workbook = read(dataNew, {
        type: "array",
        cellDates: true,
        cellNF: false,
        cellText: false,
      });
      setLoading(true);
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const results = utils.sheet_to_json(worksheet, { raw: true });
      const header = utils.sheet_to_json(worksheet, {
        header: 1,
      })[0];

      if (isDiplomaFileHeaderValid(header)) {
        let { missingFields, cleanDiplomas } =
          validateAndExtractErrors(results);
        getErrorsAndDisplayResults(
          formulateDiplomaJsonAndDeleteDuplication(cleanDiplomas, mode),
          missingFields
        );
      } else {
        toastErrorServer(`${getTranslation(translate, "TOAST_ERROR")}`);
      }
    };

    reader.readAsArrayBuffer(file);
  }

  const onSelectedModeDispatch = (MetadataHeader, myDiplomas) => {
    switch (modeSelected) {
      case "modeOne":
        dispatch(
          ADD_DIPLOMAS_FROM_CSV({
            diplomasToAdd: [MetadataHeader, ...myDiplomas],
          })
        );
        break;
      case "modeTwo":
        dispatch(
          ADD_DIPLOMAS_FROM_CSV({
            diplomasToAddTwo: [MetadataHeader, ...myDiplomas],
          })
        );
        break;
      case "modeThree":
        dispatch(
          ADD_DIPLOMAS_FROM_CSV({
            diplomasToAddThree: [MetadataHeader, ...myDiplomas],
          })
        );
    }
  };
  return (
    <>
      <div className="flex flex-col items-center justify-end w-full p-4 rounded bg-[#f2f7ff]">
        <p className="w-full ">
          {getTranslation(translate, "MESSAGE_")}
          <span
            className="font-bold text-red-700 cursor-pointer"
            onClick={downloadFileDiploma}
          >
            ({getTranslation(translate, "CLICK")})
          </span>
          {getTranslation(translate, "MESSAGE_ONE")}
        </p>
        <div className="w-full">
          <FileInput
            onChange={handleFileChange}
            OnClear={onClear}
            loading={loading}
            isUploaded={
              (mode === "modeOne" &&
                diplomasFromRedux.diplomasToAdd.length > 1) ||
              (mode === "modeTwo" &&
                diplomasFromRedux.diplomasToAddTwo.length > 1) ||
              (mode === "modeThree" &&
                diplomasFromRedux.diplomasToAddThree.length > 1)
            }
          />
        </div>
      </div>
    </>
  );
}
AddDiplomasUploader.propTypes = {
  setOpen: PropTypes.func,
  error: PropTypes.array,
  nonExistingStudents: PropTypes.array,
  setMissingFields: PropTypes.func,
  errorObject: PropTypes.array,
  setNonExistingStudents: PropTypes.func,
  setAlreadyExistingDiplomas: PropTypes.func,
  setIsLoadingData: PropTypes.func,
  errorObj: PropTypes.array,
  open: PropTypes.bool,
};
export default AddDiplomasUploader;
