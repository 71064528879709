/* eslint-disable react/prop-types */
import ministreLogo from "../../assets/ministre-logo.svg";
import QRCode from "react-qr-code";
const DiplomaTemplate = ({ diploma, selectedLanguage }) => {
  const candidate_name = {
    en: "has been conferred upon Mr. /Mrs :",
    fr: "a été conféré à M. /Mme :",
    ar: "م منحه للسيد / السيدة:",
  };
  const birthplace_header = {
    en: "Birthplace :",
    fr: "Lieu de naissance :",
    ar: "مدينة الولادة :",
  };
  const birthdate_header = {
    en: "Birthdate :",
    fr: "Date de naissance :",
    ar: "تاريخ الولادة :",
  };
  const nin_header = {
    en: "  Identity Card Number :",
    fr: "Numéro de carte d'identité :",
    ar: "رقم الهوية الوطنية :",
  };
  const legal_header = {
    en: {
      first:
        "- Having regard to law n° 2001-1912 of August 14, 2001 establishing the Higher School of Statistics and Information Analysis,",
      second:
        "- Considering the decree n° 92-1932 of November 02, 1992 fixing the competent authority to sign the national scientific diplomas,",
      third:
        "- Having regard to decree n° 95-2602 of December 25, 1995 setting the general framework of the study system and the conditions for obtaining the national engineering diploma and in particular its article 16,",
      fourth:
        "- Having regard to the minutes of the deliberations of the examination jury for the academic year,",
      fifth:
        "- Having regard to the minutes of the deliberations of the internship validation jury dated",
      sixth:
        "- Having regard to the minutes of the deliberations of the jury for the defense of the end-of-studies project dated",
    },
    fr: {
      first:
        "- Vu la loi n° 2001-1912 du 14 Août 2001 portant création de l'École Supérieure de la Statistique et de l'Analyse de l’Information,",
      second:
        "- Vu le décret n° 92-1932 du 02 Novembre 1992 fixant l’autorité compétente pour signer les diplômes scientifiques nationaux,",
      third:
        "- Vu le décret n° 95-2602 du 25 décembre 1995 fixant le cadre général du régime des études et les conditions d’obtention du diplôme national d’ingénieur et notamment son article le 16,",
      fourth:
        "- Vu le procès-verbal des délibérations du jury des examens pour l'année universitaire",
      fifth:
        "- Vu le procès-verbal des délibérations du jury de validation des stages en date de",
      sixth:
        "- Vu le procès-verbal des délibérations du jury de soutenance du projet de fin d’études en date de",
    },
    ar: {
      first:
        "- وعلى القانون عدد 1912 لسنة 2001 المؤرخ في 14 أوت 2001 بشأن إنشاء المدرسة العليا للإحصاء وتحليل المعلومات ،",
      second:
        "- واعتبارا للمرسوم رقم 92-1932 المؤرخ في 2 نوفمبر 1992 القاضي بتحديد السلطة المختصة بالتوقيع على الشهادات العلمية الوطنية ،",
      third:
        "- وعلى المرسوم رقم 2602 95 لسنة 1995 المؤرخ في 25 ديسمبر 1995 الذي يحدد الإطار العام لمنظومة الدراسة وشروط الحصول على دبلوم الهندسة الوطني ولا سيما المادة 16 منه ،",
      fourth: "- مع مراعاة محضر مداولات لجنة تحكيم الامتحانات للعام الدراسي",
      fifth: "- مع مراعاة محضر مداولات هيئة تحكيم التحقق من التدرج المؤرخ",
      sixth:
        "مع مراعاة محضر مداولات هيئة التحكيم للدفاع عن مشروع نهاية الدراسة المؤرخ-",
    },
  };
  const diploma_header = {
    fr: "Est décerné le",
    en: "Is awarded the :",
    ar: "حصل على",
  };
  const signature_header = {
    fr: "Directeur",
    en: "Director",
    ar: "المشرف",
  };
  const republic_header = {
    fr: "République Tunisienne Ministère de l’Enseignement Supérieur et de la Recherche Scientifique",
    en: "Republic of Tunisia Ministry of Higher Education and Research",
    ar: "الجمهورية التونسية وزارة التعليم العالي والبحث العلمي",
  };
  const countries = {
    ar: {
      PALESTINE: "دولة فلسطين",
      UNITED_ARAB_EMIRATES: "الإمارات العربية المتحدة",
      BAHRAIN: "البحرين",
      KUWAIT: "الكويت",
      SAUDI_ARABIA: "السعودية",
      YEMEN: "اليمن",
      QATAR: "قطر",
      OMAN: "سلطنة عمان",
      JORDAN: "الأردن",
      SYRIA: "سوريا",
      LEBANON: "لبنان",
      IRAQ: "العراق",
      EGYPT: "مصر",
      MOROCCO: "المغرب",
      LIBYA: "ليبيا",
      TUNISIA: "تونس",
      ALGERIA: "الجزائر",
      SUDAN: "السودان",
      MAURITANIA: "موريتانيا",
      DJIBOUTI: "جيبوتي",
      SOMALIA: "الصومال",
    },
    en: {
      PALESTINE: "Palestine",
      UNITED_ARAB_EMIRATES: "United Arab Emirates",
      BAHRAIN: "Bahrain",
      KUWAIT: "Kuwait",
      SAUDI_ARABIA: "Saudi Arabia",
      YEMEN: "Yemen",
      QATAR: "Qatar",
      OMAN: "Oman",
      JORDAN: "Jordan",
      SYRIA: "Syria",
      LEBANON: "Lebanon",
      IRAQ: "Iraq",
      EGYPT: "Egypt",
      MOROCCO: "Morocco",
      LIBYA: "Libya",
      TUNISIA: "Tunisia",
      ALGERIA: "Algeria",
      SUDAN: "Sudan",
      MAURITANIA: "Mauritania",
      DJIBOUTI: "Djibouti",
      SOMALIA: "Somalia",
    },
    fr: {
      PALESTINE: "Palestine",
      UNITED_ARAB_EMIRATES: "Émirats arabes unis",
      BAHRAIN: "Bahreïn",
      KUWAIT: "Koweït",
      SAUDI_ARABIA: "Arabie saoudite",
      YEMEN: "Yémen",
      QATAR: "Qatar",
      OMAN: "Oman",
      JORDAN: "Jordanie",
      SYRIA: "Syrie",
      LEBANON: "Liban",
      IRAQ: "Irak",
      EGYPT: "Égypte",
      MOROCCO: "Maroc",
      LIBYA: "Libye",
      TUNISIA: "Tunisie",
      ALGERIA: "Algérie",
      SUDAN: "Soudan",
      MAURITANIA: "Mauritanie",
      DJIBOUTI: "Djibouti",
      SOMALIA: "Somalie",
    },
  };
  return (
    <div
      className="flex flex-col justify-between h-full overflow-hidden border-2 border-blue-500 digital-diploma "
      dir={selectedLanguage === "ar" ? "rtl" : "ltr"}
    >
      <div className="flex flex-col items-center mb-2">
        <div className="flex flex-col items-center w-full">
          {" "}
          <div className="flex items-center justify-between w-full ">
            <div className="flex flex-col items-center justify-center">
              <span className="font-extrabold text-center w-60">
                {republic_header[selectedLanguage]}
              </span>
              <span className="font-extrabold ">
                {diploma?.university?.name}
              </span>
            </div>

            <img src={ministreLogo} className="h-40 w-60" />
            <img src={diploma?.university?.logo} className="h-40 w-60 " />
          </div>
          <span className="px-4 py-3 text-4xl font-extrabold text-center text-blue-600">
            {diploma?.diplomaName?.[selectedLanguage]}
          </span>
          <div className="flex flex-col items-start w-full ">
            <span className="flex items-start w-full px-4 font-bold text-left text-blue-500 text-md">
              {legal_header[selectedLanguage].first}
            </span>
            <span className="flex items-start w-full px-4 font-bold text-left text-blue-500 text-md">
              {legal_header[selectedLanguage].second}
            </span>
            <span className="flex items-start w-full px-4 font-bold text-left text-blue-500 text-md">
              {legal_header[selectedLanguage].third}
            </span>
            <div className="flex items-start w-full px-4 space-x-2">
              <span className="font-bold text-left text-blue-500 text-md ">
                {legal_header[selectedLanguage].fourth}
              </span>
              <span className="font-extrabold text-left text-blue-500 text-md ">
                {diploma?.examsPvDate}
              </span>
            </div>
            <div className="flex items-start w-full px-4 space-x-2">
              <span className="font-bold text-left text-blue-500 text-md ">
                {legal_header[selectedLanguage].fifth}
              </span>
              <span className="font-extrabold text-left text-blue-500 text-md ">
                {diploma?.internshipPvDate}
              </span>
            </div>
            <div className="flex items-start w-full px-4 space-x-2">
              <span className="font-bold text-left text-blue-500 text-md ">
                {legal_header[selectedLanguage].sixth}
              </span>
              <span className="text-sm font-extrabold text-left text-blue-500 ">
                {diploma?.paperPvDate}
              </span>
            </div>
            <div className="flex flex-col items-center justify-center w-full mt-4">
              <span className="font-bold">
                {diploma_header[selectedLanguage]}
              </span>
              <span className="text-2xl font-extrabold text-blue-500">
                {diploma?.type?.[selectedLanguage]}
              </span>
              <span className="text-2xl font-bold">
                {diploma?.speciality?.[selectedLanguage]}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-start justify-start w-full px-4 ">
          <div className="flex w-full">
            <span className="mr-2 text-2xl text-gray-800">
              {candidate_name[selectedLanguage]}
            </span>
            <span className="mr-2 text-2xl font-bold text-black">
              {diploma?.student?.firstName?.[selectedLanguage]}
            </span>
            <span className="text-2xl font-bold text-black">
              {diploma?.student?.lastName?.[selectedLanguage]}
            </span>
          </div>
        </div>
        <div className="items-center justify-between w-full px-4">
          <div className="flex justify-start w-full ">
            <div className="flex items-start justify-start mr-2">
              <span className="mr-2 text-2xl text-gray-800">
                {birthplace_header[selectedLanguage]}
              </span>

              <span className="text-2xl font-bold text-black">
                {diploma?.student?.countryOfBirth?.[selectedLanguage]}
              </span>
            </div>
            <div className="flex items-start justify-start ">
              <span className="mr-2 text-2xl text-gray-800">
                {birthdate_header[selectedLanguage]}
              </span>

              <span className="text-2xl font-bold text-black">
                {diploma?.student?.dateOfBirth}
              </span>
            </div>
          </div>
          <div className="flex items-start justify-start w-full ">
            <span className="text-2xl text-gray-800 ">
              {nin_header[selectedLanguage]}
            </span>

            <span className="text-2xl font-bold text-black">
              {diploma?.student?.nin}
            </span>
          </div>
        </div>
        <div className="flex w-full text-sm text-left flex-start ">
          <span className="text-sm font-bold text-gray-800 ">
            {diploma?.remark?.[selectedLanguage]}
          </span>
        </div>
        {diploma?.status === "accepted" ? (
          <div className="flex justify-between  w-full h-[172px] p-4">
            <div className="flex w-full h-fit ">
              <QRCode
                id="qr-gen"
                size={180}
                value={`https://saddakni.alecso.org/digital_diploma/${diploma?._id}`}
                viewBox={`0 0 256 256`}
              />
            </div>
            <div className="flex flex-col items-end w-full h-full gap-1 ">
              <div className="flex items-end h-full">
                <span className="font-extrabold">
                  {countries[selectedLanguage][diploma?.university?.country]}
                </span>
                <span className="font-extrabold">,</span>
                <span className="font-extrabold">{diploma?.year}</span>
              </div>

              <span>{signature_header[selectedLanguage]}</span>
              <img src={diploma?.signature} className="h-full w-44" />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DiplomaTemplate;
