import React, { useEffect } from "react";
import PropTypes from "prop-types";
import InputErrorMessage from "./InputErrorMessage";
import {
  isNotEmpty,
  isNotEmptyAndContainsOnlyLetters,
  isNotEmptyAndContainsOnlyLettersAndNumbers,
  isValidDate,
  isValidEmail,
  isValidPassword,
  isValidPhoneNumber,
} from "../../utils/Validators/Validators";

function Input({
  placeholder,
  icon,
  disabled,
  password,
  inputChange,
  value,
  type,
  setValid,
}) {
  const isInputValid = () => {
    switch (type) {
      case "EMAIL":
        return isValidEmail(value);
      case "PASSWORD":
        return isValidPassword(value);
      case "NAME":
        return isNotEmptyAndContainsOnlyLetters(value);
      case "University":
        return isNotEmpty(value);
      case "NUMBER":
        return isValidPhoneNumber(value);
      case "SPECIALITY":
        return isNotEmpty(value);
      case "NIN":
        return !isNotEmptyAndContainsOnlyLettersAndNumbers(value);
      case "NO_CONTROL":
        return isNotEmpty(value);
      case "DATE_FORMAT":
        return isValidDate(value);
      case "DISABLED":
        return true;
      default:
        return false;
    }
  };
  const handleInputChange = (event) => {
    event.preventDefault();
    const inputValue = event.target.value;
    inputChange(inputValue);
    setValid(isInputValid());
  };
  useEffect(() => {
    inputChange(value);
    setValid(isInputValid());
  }, [value]);
  const language = localStorage.getItem("language");
  return (
    <form className="w-full ">
      <div className="relative mb-6">
        <input
          data-testid="input-component"
          disabled={disabled}
          type={password ? "password" : "text"}
          className={
            isInputValid() || value === ""
              ? `disabled:pt-4 hover:outline-blue-400 disabled:bg-slate-200 text-ellipsis overflow-hidden  outline-blue-300/70   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full  ${
                  language === "ar" ? "pr-10" : "pl-10"
                } p-2.5`
              : `hover:outline-blue-400 border-red-400 disabled:bg-slate-200 text-ellipsis overflow-hidden focus:border-2 outline-blue-300/70    bg-white border-2  text-gray-900 text-sm rounded-md block w-full ${
                  language === "ar" ? "pr-10" : "pl-10"
                } p-2.5`
          }
          placeholder={placeholder ? placeholder : ""}
          onChange={handleInputChange}
          value={value}
          autoComplete={"true"}
        />

        <div
          className={`absolute inset-y-0 ${
            language === "ar" ? "right-0 pr-3" : "left-0   pl-3"
          } flex items-center justify-center pr-3 pointer-events-none ${
            !isInputValid() && value.length > 0 && "mb-4"
          }`}
        >
          {icon}
        </div>
        {!isInputValid() && value && (
          <div data-testid="input-component-error-message">
            <InputErrorMessage type={type} />
          </div>
        )}
      </div>
    </form>
  );
}
Input.defaultProps = {
  setValid: () => {
    return true;
  },
  inputChange: () => {},
};
Input.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.object,
  value: PropTypes.string,
  inputChange: PropTypes.func,
  disabled: PropTypes.bool,
  password: PropTypes.bool,
  type: PropTypes.string,
  setValid: PropTypes.func,
};
export default Input;
