import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import admin from "../assets/admin.png";
// eslint-disable-next-line no-unused-vars
import BalanceWrapper from "../components/balanceWrapper/BalanceWrapper";
import { useState } from "react";
import LanguageChooser from "./LanguageChooser";
const Header = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="flex content-end  w-full">
      <div className=" relative flex h-20 items-center  justify-between  rounded-md w-full px-[1%]">
        {role === "ROLE_RESP_UNI" && (
          <div className="p-2 rounded-3xl bg-white">
            <BalanceWrapper />
          </div>
        )}
        <div></div>
        <div className="flex items-center gap-3">
          <div className="flex  items-center w-[auto] gap-3  p-2 bg-white rounded-3xl cursor-pointer">
            <img
              src={admin}
              className="w-10 h-10"
              onClick={() => {
                navigate("/settings");
              }}
            />
            <span
              className="sm:hidden  font-semibold text-[#25396f] md:flex lg:flex"
              onClick={() => {
                navigate("/settings");
              }}
            >
              {user.username}
            </span>

            <div
              className="  rounded-3xl  cursor-pointer"
              onClick={() => setShowMenu(!showMenu)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8 text-[#435ebe]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                />
              </svg>
            </div>
          </div>
        </div>
        {showMenu && <LanguageChooser />}
      </div>
    </div>
  );
};

export default Header;
