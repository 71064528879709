import React from "react";
import { Fragment } from "react";
import ModalLayout from "../../../../layouts/ModalLayout";
import HeaderModal from "../LayoutsModal/HeaderModal";
import { translate } from "../Translate/Translate";
import PropTypes from "prop-types";
import FooterModal from "../LayoutsModal/FooterModal";
import { getTranslation } from "../../../../utils/translate/translation";
import {
  isThereCommunValues,
  multilanguageReader,
} from "../../../../utils/translate/multilanguage-reader";

const PendingDiplomaModalModeTwo = ({
  item,
  setOpen,
  role,
  updateDiplomaRejectOrAccept,
}) => {
  const diplomaLanguage = Object.keys(item?.type);
  const StudentLanguage = Object.keys(item?.student?.firstName);
  return (
    <Fragment>
      <ModalLayout>
        <div className="relative w-auto w-max-[auto]  mx-auto my-6   overflow-hidden">
          <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
            <HeaderModal translate={translate} item={item} />
            {isThereCommunValues(diplomaLanguage, StudentLanguage) === true ? (
              <div className="relative flex-auto p-4 h-fit">
                <div className="w-auto h-auto">
                  <div className="border-solid ">
                    <div className="flex  w-[auto] justify-between">
                      <div className=" w-[500px] ">
                        <h5 className="font-bold text-[#607080]">
                          {getTranslation(translate, "METADATA")}
                        </h5>
                        <div className="mr-8 pt-2">
                          <div className="flex  ">
                            <span className="flex items-center gap-2  w-40 font-semibold text-[#607080]">
                              <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                              {getTranslation(translate, "STUDENT")}
                            </span>
                            <span className="text-[#607080] ">
                              :{multilanguageReader(item?.student?.firstName)}{" "}
                              {multilanguageReader(item?.student?.lastName)}
                            </span>
                          </div>
                          <div className="flex ">
                            <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                              <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                              {getTranslation(translate, "DATE_OF_BRITH")}
                            </span>
                            <span className="text-[#607080]">
                              :
                              {item?.student?.dateOfBirth === "Invalid date"
                                ? null
                                : item?.student?.dateOfBirth}
                            </span>
                          </div>
                          <div className="flex ">
                            <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                              <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                              {getTranslation(translate, "PLACE_OF_BRITH")}
                            </span>
                            <span className="text-[#607080]">
                              :{" "}
                              {multilanguageReader(
                                item?.student?.countryOfBirth
                              )}
                            </span>
                          </div>
                          <div className="flex ">
                            <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                              <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                              {getTranslation(translate, "NIN")}
                            </span>
                            <span className="text-[#607080]">
                              : {item?.student?.nin}
                            </span>
                          </div>
                          <div className="flex ">
                            <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                              <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                              {getTranslation(translate, "TYPE")}
                            </span>
                            <span className="text-[#607080]">
                              : {multilanguageReader(item?.type)}
                            </span>
                          </div>
                          <div className="flex ">
                            <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                              <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                              {getTranslation(translate, "YEAR")}
                            </span>
                            <span className="text-[#607080] ">
                              : {item?.year}
                            </span>
                          </div>
                          <div className="flex ">
                            <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                              <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                              {getTranslation(translate, "SPECIALITY")}
                            </span>
                            <span className="text-[#607080]">
                              : {multilanguageReader(item?.speciality)}
                            </span>
                          </div>
                          <div className="flex ">
                            <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                              <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                              {getTranslation(translate, "HONORS")}
                            </span>
                            <span className="text-[#607080]">
                              : {multilanguageReader(item?.honors)}
                            </span>
                          </div>
                          <div className="flex ">
                            <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                              <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>
                              {getTranslation(translate, "AVERAGE")}
                            </span>
                            <span className="text-[#607080]">
                              : {item?.average}
                            </span>
                          </div>
                          <div className="flex ">
                            <p className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                              <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>
                              {getTranslation(translate, "APPROVAL_DATE")}
                            </p>
                            <span className="text-[#607080]">
                              :
                              {item?.approvalDate === "Invalid date"
                                ? null
                                : item?.approvalDate}
                            </span>
                          </div>
                          <div className="flex ">
                            <p className="flex items-center gap-2  w-40 font-semibold text-[#607080]">
                              <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>
                              {getTranslation(translate, "SIGNATURE_DATE")}
                            </p>
                            <span className="text-[#607080]">
                              :
                              {item?.signatureDate === "Invalid date"
                                ? null
                                : item?.signatureDate}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Fragment>
                <div className="p-3">
                  <div className="text-[#dc3545] font-semibold flex gap-1 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                      />
                    </svg>

                    <span className="text-[#dc3545] font-semibold">
                      بيانات الطالب والشهادة مسجلة بلغات مختلفة
                    </span>
                  </div>
                  <div>
                    <span className="text-[#657584] font-medium">
                      لغات الطلاب المحفوظة :
                    </span>
                    <div className="pt-1">
                      {StudentLanguage.map((lang, key) => (
                        <Fragment key={key}>
                          <div className="flex w-full h-full items-center gap-1">
                            <div className="w-2 h-2 bg-[#607080] rounded-lg "></div>
                            <div className="flex gap-2">
                              <span>
                                {lang === "ar"
                                  ? "العربية"
                                  : lang === "fr"
                                  ? "Français"
                                  : "Anglais"}
                              </span>
                            </div>
                          </div>
                        </Fragment>
                      ))}
                    </div>
                    <span className="text-[#657584] font-medium">
                      لغات شهادة المحفوظة :
                    </span>
                    <div className="pt-1">
                      {diplomaLanguage.map((lang, key) => (
                        <Fragment key={key}>
                          <div className="flex w-full h-full items-center gap-1">
                            <div className="w-2 h-2 bg-[#607080] rounded-lg "></div>
                            <div className="flex gap-2">
                              <span>
                                {lang === "ar"
                                  ? "العربية"
                                  : lang === "fr"
                                  ? "Français"
                                  : "Anglais"}
                              </span>
                            </div>
                          </div>
                        </Fragment>
                      ))}
                    </div>
                    <span>
                      ملاحظة: تحتاج إلى حفظ الدبلوم بلغة الطالب المحفوظة
                    </span>
                  </div>
                </div>
              </Fragment>
            )}

            <FooterModal
              translate={translate}
              role={role}
              item={item}
              open={open}
              setOpen={setOpen}
              updateDiplomaRejectOrAccept={updateDiplomaRejectOrAccept}
            />
          </div>
        </div>
      </ModalLayout>
    </Fragment>
  );
};
PendingDiplomaModalModeTwo.propTypes = {
  item: PropTypes.object,
  role: PropTypes.string,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  filtered: PropTypes.object,
  setFiltered: PropTypes.func,
  updateDiplomaRejectOrAccept: PropTypes.func,
};
export default PendingDiplomaModalModeTwo;
