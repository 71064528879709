/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BarChart from "../../components/charts/Bar";
import CountryNumber from "../../components/charts/CountryNumber";
import DoughnutChart from "../../components/charts/Doughnut";
import { GET_AGENTS } from "../../store/features/diplomasSlice/diplomasSlice";
import {
  useGetDailyVisitsByUniversityMutation,
  useGetDailyVisitsMutation,
  useGetDiplomasBysexByUniOrRespMutation,
  useGetDiplomasBysexMutation,
  useGetNbrOfVistisByMonthAndUniversityMutation,
  useGetNbrOfVistisByMonthMutation,
  useGetNbStudentbyRespMutation,
  useGetNbStudentsMutation,
  useGetNumberDiplomasByadminMutation,
  useGetNumberDiplomasByRespMutation,
  useGetNumberOfUniversitiesByCountryMutation,
} from "../../store/service/dashboardService";
import {
  useGetAgentsByRespMutation,
  useGetRejectedDiplomaByAgentMutation,
} from "../../store/service/diplomasService";
import { logout } from "../../utils/logout";
import { getTranslation } from "../../utils/translate/translation";

import VerifyToken from "../../utils/VerifyToken";
import Card from "./Card";

const translate = {
  ar: {
    PAGE_TITLE: "فضاء الجامعة",
    MALE: "ذكور",
    FEMALE: "إناث",
  },
  en: {
    PAGE_TITLE: "University space",
    MALE: "Males",
    FEMALE: "Females",
  },
  fr: {
    PAGE_TITLE: "Espace universitaire",
    MALE: "Mâles",
    FEMALE: "Femelles",
  },
};

const Dashboard = () => {
  const [nbDlp, setNbDlp] = useState(0);
  const [male, setMale] = useState(0);
  const [female, setFemale] = useState(0);
  const [nbVist, setNbVist] = useState(0);
  const [results, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nbStudents, setNbStudents] = useState(0);
  const [country, setCountry] = useState([]);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  const [getNumberDiplomasByadmin] = useGetNumberDiplomasByadminMutation();
  const [getDiplomasbyresp] = useGetNumberDiplomasByRespMutation();
  const [getDiplomasBysex] = useGetDiplomasBysexMutation();
  const [getDiplomasBysexByUniOrResp] =
    useGetDiplomasBysexByUniOrRespMutation();
  const [getNbrOfVistisByMonth] = useGetNbrOfVistisByMonthMutation();
  const [getNbrOfVistisByMonthAndUniversity] =
    useGetNbrOfVistisByMonthAndUniversityMutation();
  const [getNbStudents] = useGetNbStudentsMutation();
  const [getNbStudentsByResp] = useGetNbStudentbyRespMutation();
  const [getDailyVisits] = useGetDailyVisitsMutation();
  const [getDailyVisitsByUniversity] = useGetDailyVisitsByUniversityMutation();
  const [getNumberOfUniversitiesByCountry] =
    useGetNumberOfUniversitiesByCountryMutation();

  const fetchNumberDiplomasByAdmin = async () => {
    try {
      let res;
      if (role === "ROLE_ADMIN_ALECSO") {
        res = await getNumberDiplomasByadmin();
      } else {
        res = await getDiplomasbyresp();
      }

      if (res.data.length !== 0) {
        setNbDlp(res.data.dataToSend);
      } else {
        setNbDlp(0);
      }
    } catch (error) {
      console.error("Error fetching number of diplomas:", error);
    }
  };

  const fetchDiplomasBySex = async () => {
    try {
      let res;
      if (role === "ROLE_ADMIN_ALECSO") {
        res = await getDiplomasBysex();
      } else {
        res = await getDiplomasBysexByUniOrResp();
      }

      setMale(res.data.dataToSend.male);
      setFemale(res.data.dataToSend.female);
    } catch (error) {
      console.error("Error fetching diplomas by sex:", error);
    }
  };

  const fetchNbrOfVisitsByMonth = async () => {
    try {
      let res;
      if (role === "ROLE_ADMIN_ALECSO") {
        res = await getNbrOfVistisByMonth();
      } else {
        res = await getNbrOfVistisByMonthAndUniversity();
      }

      setResult(res.data.dataToSend);
      setLoading(true);
    } catch (error) {
      console.error("Error fetching number of visits by month:", error);
    }
  };

  const fetchNumberStudents = async () => {
    try {
      let res;
      if (role === "ROLE_ADMIN_ALECSO") {
        res = await getNbStudents();
      } else {
        res = await getNbStudentsByResp();
      }

      if (res.data.length !== 0) {
        setNbStudents(res.data.dataToSend);
      } else {
        setNbStudents(0);
      }
    } catch (error) {
      console.error("Error fetching number of students:", error);
    }
  };

  const fetchDailyVisits = async () => {
    try {
      let res;
      if (role === "ROLE_ADMIN_ALECSO") {
        res = await getDailyVisits();
      } else {
        res = await getDailyVisitsByUniversity();
      }

      setNbVist(res.data.dataToSend);
    } catch (error) {
      console.error("Error fetching daily visits:", error);
    }
  };

  const fetchNumberOfUniversitiesByCountry = async () => {
    try {
      const res = await getNumberOfUniversitiesByCountry();
      setCountry(res.data.dataToSend);
    } catch (error) {
      console.error("Error fetching number of universities by country:", error);
    }
  };

  useEffect(() => {
    if (!VerifyToken()) {
      logout();
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      fetchNumberDiplomasByAdmin();
      fetchDiplomasBySex();
      fetchNbrOfVisitsByMonth();
      fetchNumberStudents();
      fetchDailyVisits();
      fetchNumberOfUniversitiesByCountry();
    }
  }, []);

  const data = {
    nbStudents: nbStudents,
    nbDiplomas: nbDlp,
    nbLogin: nbVist,
  };

  const chartData = {
    labels: [
      `${getTranslation(translate, "MALE")}`,
      `${getTranslation(translate, "FEMALE")}`,
    ],
    datasets: [
      {
        data: [male, female],
        backgroundColor: ["#2e458b", "#ff7682"],
        borderColor: "white",
        hoverOffset: 30,
        borderWidth: 2,
        cutout: "30%",
      },
    ],
  };
  const [getAgents] = useGetAgentsByRespMutation();
  const [agents, setAgent] = useState([]);
  const dataBarChart = {
    labels: results.map((res) => res.month).reverse(),
    datasets: [
      {
        backgroundColor: "#435ebe",
        borderColor: "rgb(67, 94, 190)",
        data: results.map((res) => res.verification).reverse(),
      },
    ],
  };
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const countNbDiplomas = (data) => {
    let countDip = 0;
    for (let i = 0; i < data.length; i++) {
      countDip += data[i].count;
    }
    setCount(countDip);
  };
  const [getDiplomaRejected] = useGetRejectedDiplomaByAgentMutation();
  useEffect(() => {
    if (role === "ROLE_RESP_UNI") {
      getAgents().then((res) => {
        setAgent(res.data.data);
        countNbDiplomas(res.data.data);
        dispatch(GET_AGENTS({ agent: res.data.data, countDiploma: count }));
      });
    }
    if (role === "ROLE_AGENT_UNI") {
      getDiplomaRejected().then((res) => {
        setCount(res.data.data.length);
        dispatch(GET_AGENTS({ countDiploma: count }));
      });
    }
  }, [count]);
  return (
    <div className="w-full p-3  ">
      <h1 className="lg:text-3xl font-bold text-[#25396f] pb-4">
        {getTranslation(translate, "PAGE_TITLE")}
      </h1>
      <div className="lg:flex justify-center items-start h-auto xs:grid gap-8 w-full">
        <div className="lg:w-3/4 md:w-auto   sm:w-auto grid gap-9">
          <Card data={data} />
          <BarChart dataBarChart={dataBarChart} loading={loading} />
        </div>
        <div className="grid lg:w-1/4 md:w-auto h-auto gap-1">
          <div className="p-3 my-2 bg-white rounded-xl  ">
            <CountryNumber country={country} />
          </div>
          {female !== 0 ||
            (male !== 0 && (
              <div className=" my-2 bg-white rounded-xl">
                <DoughnutChart chartData={chartData} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
