import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  useDeleteStudentMutation,
  useGetStudentsMutation,
} from "../../store/service/studentsService";
import { useSelector, useDispatch } from "react-redux";
import { GET_STUDENTS } from "../../store/features/studentsSlice/studentsSlice";

import Table from "./Table";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import Pagination from "../../components/pagination/Pagination";

import Spinner from "../../components/spinner/Spinner";
import VerifyToken from "../../utils/VerifyToken";
import { logout } from "../../utils/logout";
import { toastErrorServer, toastSucces } from "../../components/toast/Toast";

import DeleteStudentWithIDModal from "../../components/modal/Student/DeleteStudentWithIDModal";
import { getTranslation } from "../../utils/translate/translation";
import {
  convertNumbers2English,
  convertSlash2Dash,
} from "../../utils/converts/convert";
import { multilanguageReader } from "../../utils/translate/multilanguage-reader";

const animatedComponents = makeAnimated();
const StudentsTable = () => {
  const translate = {
    ar: {
      STUDENT_DATA: "بيانات الطلبة",
      SEARCH_PLACEHOLDER: "ابحث  ...!",
      SELECT_PLACEHOLDER: "اختار ...!",
      SEARCH_BUTTON: "بحث",
      NATIONAL_ID: "المعرف الوطني",
      PLACE_OF_BIRTH: "مكان الولادة	",
      DATE_OF_BIRTH: "تاريخ الولادة",
      EMAIL: "البريد الالكتروني",
      PHONE: "الهاتف",
      NATIONALITY: "الجنسية",
      GENDER: "الجنس",
      ERROR_MESSAGE: "هناك خطأ من السرفر ",
      SUCCESS_MESSAGE: "تمت العملية بنجاح!",
    },
    fr: {
      STUDENT_DATA: "Données des étudiants",
      SEARCH_PLACEHOLDER: "Rechercher...!",
      SELECT_PLACEHOLDER: "Sélectionner...!",
      SEARCH_BUTTON: "Rechercher",
      NATIONAL_ID: "Identifiant national",
      PLACE_OF_BIRTH: "Lieu de naissance",
      DATE_OF_BIRTH: "Date de naissance",
      EMAIL: "Adresse e-mail",
      PHONE: "Téléphone",
      NATIONALITY: "Nationalité",
      GENDER: "Genre",
      ERROR_MESSAGE: "Il y a un problème avec le serveur",
      SUCCESS_MESSAGE: "La demande a été soumise avec succès!",
    },
    en: {
      STUDENT_DATA: "Student Data",
      SEARCH_PLACEHOLDER: "Search...!",
      SELECT_PLACEHOLDER: "Select...!",
      SEARCH_BUTTON: "Search",
      NATIONAL_ID: "National ID",
      PLACE_OF_BIRTH: "Place of Birth",
      DATE_OF_BIRTH: "Date of Birth",
      EMAIL: "Email",
      PHONE: "Phone",
      NATIONALITY: "Nationality",
      GENDER: "Gender",
      ERROR_MESSAGE: "There is a problem with the server",
      SUCCESS_MESSAGE: "The request was submitted successfully!",
    },
  };
  const options = [
    { value: "nin", label: getTranslation(translate, "NATIONAL_ID") },
    {
      value: "countryOfBirth",
      label: getTranslation(translate, "PLACE_OF_BIRTH"),
    },
    { value: "email", label: getTranslation(translate, "EMAIL") },
    { value: "phone", label: getTranslation(translate, "PHONE") },
    { value: "nationalty", label: getTranslation(translate, "NATIONALITY") },
    { value: "dateOfBirth", label: getTranslation(translate, "DATE_OF_BIRTH") },
    { value: "sex", label: getTranslation(translate, "GENDER") },
  ];
  const role = localStorage.getItem("role");
  const students = useSelector((state) => state.students.students);
  const [getStudents] = useGetStudentsMutation();
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const disptach = useDispatch();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [searchBy, setSearchBy] = useState([]);
  const [pageOne, setPageOne] = useState(false);
  const _getStudents = () => {
    if (!VerifyToken()) {
      logout();
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      getStudents().then((res) => {
        disptach(GET_STUDENTS({ students: res.data.data }));
        if (search === "" && searchBy.length == 0) {
          setFiltered(res.data.data);
          setLoading(true);
        }
      });

      setTimeout(() => {
        setLoading(true);
      }, 1000);
    }
  };
  useEffect(() => {
    _getStudents();
  }, [loading]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteStudent] = useDeleteStudentMutation();

  const searchby = () => {
    const _filtered = students.filter(
      (student) =>
        (searchBy.includes("nin") &&
          student.nin.includes(convertNumbers2English(search))) ||
        (searchBy.includes("email") &&
          student.email.toLowerCase().includes(search.toLowerCase())) ||
        (searchBy.includes("phone") &&
          student.phone.includes(convertNumbers2English(search))) ||
        (searchBy.includes("dateOfBirth") &&
          student.dateOfBirth.includes(convertSlash2Dash(search))) ||
        (searchBy.includes("countryOfBirth") &&
          multilanguageReader(student?.countryOfBirth)
            ?.toLowerCase()
            .includes(search.toLowerCase())) ||
        (searchBy.includes("sex") &&
          multilanguageReader(student?.sex).includes(search.toLowerCase())) ||
        (searchBy.includes("nationalty") &&
          multilanguageReader(student?.nationalty)
            ?.toLowerCase()
            .includes(search.toLowerCase()))
    );
    setFiltered(_filtered);
  };

  const filterage = () => {
    if (searchBy.length > 0) {
      for (let i = 0; i < searchBy.length; i++) {
        if (
          searchBy[i].includes("email") ||
          searchBy[i].includes("nin") ||
          searchBy[i].includes("dateOfBirth") ||
          searchBy[i].includes("phone") ||
          searchBy[i].includes("countryOfBirth") ||
          searchBy[i].includes("sex") ||
          searchBy[i].includes("nationalty")
        ) {
          searchby();
        }
      }
    } else if (setSearch != "") {
      const _filtered = students.filter(
        (student) =>
          multilanguageReader(student?.firstName)
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          multilanguageReader(student?.firstName)
            .toLowerCase()
            .includes(search.toLowerCase())
      );
      setFiltered(_filtered);
    }
  };

  useEffect(() => {
    filterage();
  }, [search, currentPage, searchBy]);
  const handleChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleChangeSearch = (e) => {
    const array = [];
    e?.map((value) => {
      array.push(value.value);
      setSearchBy(array);
    });

    if (e?.length == []) {
      array.push("");
      setSearchBy([]);
    }
    setCurrentPage(1);
  };
  const handleChangeSelect = (e) => {
    setCurrentPage(1);
    setPerPage(e.target.value.toString());
    setPageOne(!pageOne);
  };
  useEffect(() => {
    handleChangeSearch();
  }, [searchBy, loading]);
  //Delete Fonction
  const [userId, setUserId] = useState(0);
  const [dateOfBirth, setDateOfBrith] = useState(null);
  const [id, setId] = useState(null);
  const remove = (nin, dateOfBirth, ID) => {
    let data = { nin, dateOfBirth };
    deleteStudent(data).then((res) => {
      if (res?.error?.status === 400) {
        toastErrorServer(getTranslation(translate, "ERROR_MESSAGE"));
      }
      if (res) {
        const _filtered = students.filter((student) => student._id !== ID);
        toastSucces(getTranslation(translate, "SUCCESS_MESSAGE"));
        dispatch(GET_STUDENTS({ students: _filtered }));
        setFiltered(_filtered);
        setOpen(!open);
      }
    });
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="flex flex-col justify-start ">
        <h1 className="p-2 ">{getTranslation(translate, "STUDENT_DATA")} </h1>
        <div className="w-full gap-3 px-2 lg:flex lg:flex-row md:flex md:flex-row sm:flex sm:flex-col">
          <input
            type={"text"}
            className="h-10 transition ease-in-out delay-200 border rounded lg:w-1/4 md:w-1/4 focus:outline-blue-600 "
            placeholder={getTranslation(translate, "SEARCH_PLACEHOLDER")}
            value={search}
            onChange={handleChange}
          />
          <Select
            options={options}
            className="lg:w-2/3 md:w-2/3"
            closeMenuOnSelect={false}
            placeholder={getTranslation(translate, "SELECT_PLACEHOLDER")}
            components={animatedComponents}
            onChange={handleChangeSearch}
            styles={{ height: "3rem", borderWidth: "2px" }}
            isMulti
          />
          {filtered.length > 5 ? (
            <select
              className="h-10 border rounded lg:w-1/12 md:w-1/12 focus:outline-blue-600 focus:border-none"
              onChange={handleChangeSelect}
              type="number"
              value={perPage}
            >
              <option type="number" value={5}>
                5
              </option>
              <option type="number" value={10}>
                10
              </option>
              <option type="number" value={20}>
                20
              </option>
              <option type="number" defaultValue={30}>
                30
              </option>

              <option type="number" value={50}>
                50
              </option>
            </select>
          ) : null}
        </div>

        <div className="relative w-full h-auto mt-8 overflow-x-auto sm:rounded-lg ">
          {loading ? (
            <Table
              filtered={filtered}
              students={students}
              role={role}
              navigate={navigate}
              handleOpen={handleOpen}
              setUserId={setUserId}
              setDateOfBrith={setDateOfBrith}
              setId={setId}
              currentPage={currentPage}
              perPage={perPage}
              setFiltered={setFiltered}
            />
          ) : (
            <div className="h-[300px] flex items-center justify-center ">
              <Spinner />
            </div>
          )}
        </div>
        <div className="flex justify-start">
          {filtered.length > 5 && perPage < filtered.length ? (
            <Pagination
              perPage={perPage}
              total={filtered.length}
              paginate={paginate}
              pageOne={pageOne}
            />
          ) : null}
        </div>
      </div>
      {open ? (
        <DeleteStudentWithIDModal
          remove={remove}
          userId={userId}
          id={id}
          dateOfBirth={dateOfBirth}
          setOpen={setOpen}
          open={open}
        />
      ) : null}
    </>
  );
};

export default StudentsTable;
