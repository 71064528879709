/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import DiplomaTemplate from "./DiplomaTemplate";
import PropTypes from "prop-types";
import LanguageChooser from "../../components/language-chooser/LanguageChooser";
// ES2015 module syntax
import ReactToPrint from "react-to-print";
import PrintButton from "../../components/PrintButton/PrintButton";
import { useParams } from "react-router-dom";
import { useVerifyDiplomaMutation } from "../../store/service/diplomasService";
import FantomScannerLink from "../../components/fantom-scanner-link/FantomScannerLink";
import { getCommunValues } from "../../utils/translate/multilanguage-reader";
import AcceptedBy from "../../components/acceptedBy/AcceptedBy";

function DigitalDiplomaPage() {
  const [diploma, setDiploma] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [addressResponsable, setAddressResponsable] = useState("");
  let componentRef = useRef(null);

  let id = useParams();
  let [verify] = useVerifyDiplomaMutation();
  const _verifyDiploma = () => {
    let data = {
      diplomaId: id.id,
    };
    verify(data).then((res) => {
      setDiploma(res?.data?.data);
      setAddressResponsable(res?.data?.responsable);
      setSelectedLanguage(
        getCommunValues(
          Object.keys(res?.data?.data?.type),
          Object.keys(res?.data?.data?.student?.firstName)
        )[0]
      );
    });
  };

  useEffect(() => {
    _verifyDiploma();
  }, []);

  return (
    <div className="flex items-center justify-between w-full h-[100vh] p-2 ">
      <div className="flex items-center justify-between h-full ">
        <div className="flex flex-col items-start justify-start h-full p-1 ">
          <ReactToPrint
            trigger={() => (
              <div>
                <PrintButton />
              </div>
            )}
            content={() => componentRef.current}
          />
          {diploma && (
            <LanguageChooser
              setSelectedLanguage={setSelectedLanguage}
              firstnameObject={diploma?.student?.firstName}
              diplomaType={diploma?.type}
            />
          )}
          {diploma && <FantomScannerLink hashTx={diploma?.hashTx} />}
          {diploma && <AcceptedBy addressResponsable={addressResponsable} />}
        </div>
      </div>
      {diploma && (
        <div ref={componentRef} className=" w-full p-2 h-fit  ">
          <DiplomaTemplate
            diploma={diploma}
            selectedLanguage={selectedLanguage}
          />
        </div>
      )}
    </div>
  );
}
DigitalDiplomaPage.propTypes = {
  diploma: PropTypes.object,
};
export default DigitalDiplomaPage;
