import { read, utils, set_cptable } from "xlsx";
import * as cptable from "xlsx/dist/cpexcel.full.mjs";
import { convertNumbers2English } from "../converts/convert";
import moment from "moment";
// import { excelSerialNumberToDate } from "../dateUtils/convertDateTimeStambyFormatDate";
import { isDateNotValid, isXlsFieldNotValid } from "../Validators/Validators";
set_cptable(cptable);

const readXlsFile = (e) => {
  try {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      // Process the worksheet data as needed
      const _data = utils.sheet_to_json(worksheet, { raw: false });

      return _data;
    };
    reader.readAsArrayBuffer(file);
  } catch (e) {
    console.error(e);
  }
};
function convertSerialNumberToDate(serialNumber) {
  const millisecondsPerDay = 86400000;
  const epoch = new Date("1900-01-01T00:00:00Z");
  const offset = (serialNumber - 1) * millisecondsPerDay;
  const date = new Date(epoch.getTime() + offset);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}-${month}-${year}`;
}
function convertDateToString(dateString) {
  const date = moment(dateString);
  return date.format("DD-MM-YYYY");
}
function convertDateFromXlsFileToString(field) {
  if (typeof field === "string") {
    return moment(
      convertNumbers2English(field.toString()),
      "DD-MM-YYYY"
    ).format("DD-MM-YYYY");
  } else if (typeof field === "number") {
    return convertSerialNumberToDate(convertNumbers2English(field?.toString()));
  } else {
    return convertDateToString(field?.toString());
  }
}
function formulateDiplomasJson(data) {
  let diplomas = [];
  for (let i = 0; i < data.length; i++) {
    if (
      data[i]["firstName"] &&
      data[i]["lastName"] &&
      data[i]["nin"] &&
      data[i]["type"] &&
      data[i]["speciality"] &&
      data[i]["year"]
    ) {
      const res = {
        firstName: data[i]["firstName"] ?? "",
        lastName: data[i]["lastName"] ?? "",
        nin: data[i]["nin"]?.toString() ?? "",
        type: data[i]["type"] ?? "",
        speciality: data[i]["speciality"] ?? "",
        year: data[i]["year"] ?? "",
        signatureDate: data[i]["signatureDate"],
        honors: data[i]["honors"] ?? "",
        approvalDate: data[i]["approvalDate"],
        average: data[i]["average"] ?? "",
        language: data[i]["language"] ?? "en",
        examsPvDate: data[i]["examsPvDate"],
        internshipPvDate: data[i]["internshipPvDate"],
        paperPvDate: data[i]["paperPvDate"],
        diplomaName: data[i]["diplomaName"],
        ligne: i + 2,
      };
      diplomas.push(res);
    }
  }

  return diplomas;
}

function deleteDuplicatedDiplomas(diplomas, mode) {
  if (mode === "modeThree") {
    return diplomas.reduce((acc, current) => {
      const x = acc.find(
        (item) => item.nin === current.nin && current.language === item.language
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  } else {
    return diplomas.reduce((acc, current) => {
      const x = acc.find(
        (item) => item.nin === current.nin && item.language !== current.language
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  }
}

function formulateDiplomaJsonAndDeleteDuplication(data, mode) {
  const diplomas = formulateDiplomasJson(data);
  const uniqueDiplomas = deleteDuplicatedDiplomas(diplomas, mode);

  return uniqueDiplomas;
}

function isDiplomaligneFromXlsContainsAllObligatoryFields(ligneJson, index) {
  return (
    isXlsFieldNotValid(ligneJson[index]["firstName"]) ||
    isXlsFieldNotValid(ligneJson[index]["lastName"]) ||
    isXlsFieldNotValid(ligneJson[index]["nin"]) ||
    isXlsFieldNotValid(ligneJson[index]["type"]) ||
    isXlsFieldNotValid(ligneJson[index]["speciality"]) ||
    isXlsFieldNotValid(ligneJson[index]["year"])
  );
}

function isApprovalDateValid(field, index) {
  return index !== 0 && isXlsFieldNotValid(field) && isDateNotValid(field);
}

function isSignatureDateValid(field, index) {
  return index !== 0 && !isXlsFieldNotValid(field) && isDateNotValid(field);
}

export {
  readXlsFile,
  convertDateFromXlsFileToString,
  formulateDiplomaJsonAndDeleteDuplication,
  isDiplomaligneFromXlsContainsAllObligatoryFields,
  isApprovalDateValid,
  isSignatureDateValid,
};
