/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import ModalLayout from "../../../layouts/ModalLayout";
import { getTranslation } from "../../../utils/translate/translation";

const translate = {
  ar: {
    TITLE_MODAL_SUCCES: "العملية ناجحة",
    TITLE_MODAL_ERROR: "التنبيه",
    MISTAKE: "خطأ",

    MESSAGE_0: "لم يقع إضافة هؤلاء الطلبة نظراً لوجودهم",
    NIN: "المعرف",
    MESSAGE_1:
      " تمت إضافة بيانات الطلبة بنجاح مع العلم أن هناك طلبة سبق أن وقع  تسجيلهم بجامعات أخرى وهم : ",
    CLOSE: "أغلق النافذة ",
  },
  en: {
    TITLE_MODAL_SUCCES: "The operation is successful",
    TITLE_MODAL_ERROR: "Alert",
    MISTAKE: "Mistake",
    MESSAGE_0: "These students were not added due to their presence",
    NIN: "Identifier",
    MESSAGE_1:
      "Students' data has been added successfully, knowing that there are students who have previously been registered in other universities, and they are: ",
    CLOSE: "Close ",
  },
  fr: {
    TITLE_MODAL_SUCCES: "L'opération est réussie",
    TITLE_MODAL_ERROR: "Alerte",
    MISTAKE: "Erreur",
    MESSAGE_: "Ces étudiants n'ont pas été ajoutés en raison de leur présence",
    NIN: "Identifiant",
    MESSAGE_1:
      "Les données des étudiants ont été ajoutées avec succès, sachant qu'il y a des étudiants qui ont déjà été inscrits dans d'autres universités, et ils sont :",
    CLOSE: "Ferme ",
  },
};
const AddStudentAfterErrorModal = ({
  setErrorAdd,
  errorTable,
  updateTable,
}) => {
  return (
    <ModalLayout>
      <div className="relative  my-6 mx-auto w-[500px]">
        {/*content*/}
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-3 bg-[#0dcaf0] border-b border-solid rounded-t border-slate-200">
            <h3 className="text-2xl font-semibold text-white">
              {errorTable?.length === 0
                ? `${getTranslation(translate, "TITLE_MODAL_SUCCES")}`
                : `${getTranslation(translate, "TITLE_MODAL_ERROR")}`}
            </h3>
          </div>
          {/*body*/}
          <div className="relative p-4 flex-auto max-h-[60vh] overflow-y-auto ">
            <span className="pb-3 text-2xl font-semibold"></span>
            <div className="grid justify-start  text-right">
              {errorTable[0][0]?.length === 0 ? null : (
                <span> {getTranslation(translate, "MESSAGE_0")} </span>
              )}
              {errorTable &&
                errorTable[0][0]?.map((e, key) => (
                  <div key={key} className="grid justify-start text-right">
                    <span className="font-semibold text-blue-700">
                      {getTranslation(translate, "NIN")}: {e.nin}
                    </span>
                  </div>
                ))}
              {updateTable[0][0]?.length === 0 ? null : (
                <span>{getTranslation(translate, "MESSAGE_1")}</span>
              )}
              {updateTable &&
                updateTable[0][0]?.map((e, key) => (
                  <div key={key} className="grid justify-start text-right">
                    <span className="font-semibold text-blue-700">
                      {getTranslation(translate, "NIN")}: {e.nin}
                    </span>
                  </div>
                ))}
            </div>
          </div>
          {/*footer*/}

          <div className="flex items-center justify-center p-2 border-t border-solid rounded-b border-slate-200">
            <button
              className="px-5 py-2 mb-1 mr-1 text-sm font-bold text-black uppercase transition-all duration-150 ease-linear bg-[#e6eaee] rounded shadow outline-none active:bg-gray-900 hover:shadow-lg focus:outline-none"
              onClick={() => setErrorAdd(false)}
            >
              {getTranslation(translate, "CLOSE")}
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
AddStudentAfterErrorModal.propTypes = {
  setErrorAdd: PropTypes.func,
  errorTable: PropTypes.array,
  updateTable: PropTypes.array,
};
export default AddStudentAfterErrorModal;
