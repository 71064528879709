import jwtDecode from "jwt-decode";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import {
  useCreateAgentUniMutation,
  useCreateRespUniMutation,
} from "../../store/service/usersService";
import {
  mailIcon,
  mobileIcon,
  specialityIcon,
  univestyIcon,
  userIcon,
} from "../../assets/svg/Index";
import SelectCountry from "../../components/input/SelectCountry";
import { clearLocalStorage } from "../../utils/logout";
import { toastErrorServer } from "../../components/toast/Toast";
import { getTranslation } from "../../utils/translate/translation";

const translate = {
  ar: {
    TOAST_ERROR_TOKEN_EXPIRED:
      "فترة التسجيل الخاصة بك انتهت. أعد الدخول مجدداً",
    TOAST_ERROR_METADATA: "المعطيات ناقصة ، الرجاء إدخال المعطيات كاملة",
    TOAST_SUCCESS: "تمت الاضافة بنجاح ",
    TOAST_ERROR_EMAIL_EXIST:
      "هذا المستخدم يوجد ، حاول إستخدام عنوان إلكتروني أخر ",
    TOAST_ERROR_SERVER_: "هناك خطأ في سرفير ",
    PAGE_TITLE: "إضافة مستخدم",
    MESSAGE_:
      "      لإضافة مستخدم يجب تعمير البيانات التالية لإرسال رابط تسجيل خاص لإكمال عملية تسجيل الموظف ضمن مستخدمي المنصّة",
    NAME: " الاسم الكامل ",
    PLACEHOLDER_NAME: "اسم ولقب المستخدم",
    EMAIL: "البريد الالكتروني ",
    PLACEHOLDER_EMAIL: "البريد الالكتروني لإرسال طلب التسجيل",
    PHONE: "الهاتف",
    PLACEHOLDER_PHONE: "هاتف الموظف ",
    SPECIALITY: "إدارة",
    UNIVERSITY: "الجامعة",
    PLACEHOLDER_UNIVERSITY: " إسم الجامعة ",
    COUNTRY: "البلد",
    BUTTON_TITLE: "ارسال طلب تسجيل",
    BACK: "  الرجوع إلى القائمة",
  },
  en: {
    TOAST_ERROR_TOKEN_EXPIRED:
      "Your registration period has expired. Re-enter again",
    TOAST_ERROR_METADATA: " Data is missing, please enter complete information",
    TOAST_SUCCESS: "  Added successfully ",
    TOAST_ERROR_EMAIL_EXIST:
      "This user exists, try using another email address",
    TOAST_ERROR_SERVER_: "There is an error in the server",
    PAGE_TITLE: "Add user",
    MESSAGE_:
      "To add a user, the following data must be filled in to send a special registration link to complete the employee registration process among the users of the platform",
    NAME: " Full name",
    PLACEHOLDER_NAME: "Username and surname",
    EMAIL: "Email",
    PLACEHOLDER_EMAIL: "The email to send the registration request",
    PHONE: "Phone",
    PLACEHOLDER_PHONE: " Agent phone ",
    SPECIALITY: "Administration",
    UNIVERSITY: "University",
    PLACEHOLDER_UNIVERSITY: " University Name",
    COUNTRY: "Country",
    BUTTON_TITLE: "Submit a registration request",
    BACK: " Back to list",
  },
  fr: {
    TOAST_ERROR_TOKEN_EXPIRED:
      "Votre période d'enregistrement a expiré. Entrez à nouveau",
    TOAST_ERROR_METADATA:
      "Les données sont manquantes, veuillez saisir les informations complètes",
    TOAST_SUCCESS: "Ajouté avec succès",
    TOAST_ERROR_EMAIL_EXIST:
      "Cet utilisateur existe, essayez d'utiliser une autre adresse e-mail",
    TOAST_ERROR_SERVER_: "Il y a une erreur dans le serveur",
    PAGE_TITLE: "Ajouter un utilisateur",
    MESSAGE_:
      "Pour ajouter un utilisateur, les données suivantes doivent être fournies pour envoyer un lien d'inscription spécial afin de compléter le processus d'inscription des employés parmi les utilisateurs de la plateforme",
    NAME: "Nom complet",
    PLACEHOLDER_NAME: "Nom d'utilisateur et prénom",
    EMAIL: "Email",
    PLACEHOLDER_EMAIL: "L'email pour envoyer la demande d'inscription",
    PHONE: "Téléphone",
    PLACEHOLDER_PHONE: " Téléphone de l'agent ",
    SPECIALITY: "Administration",
    UNIVERSITY: "L'Université",
    PLACEHOLDER_UNIVERSITY: "Nom de l'université",
    COUNTRY: "Pays",
    BUTTON_TITLE: "Soumettre une demande d'inscription",
    BACK: " Retour à la liste",
  },
};
function AddUserPage() {
  const [name, setName] = useState("");
  const [isNameValid, setIsNameValid] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isSpecialityValid, setIsSpecialityValid] = useState(false);
  const [numero, setNumero] = useState("");
  const [isNumeroValid, setIsNumeroValid] = useState(false);
  const [universityName, setUniversityName] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [country, setCountry] = useState("");
  const [isUniversityNameValid, setIsUniversityNameValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createRespUniv] = useCreateRespUniMutation();
  const [createAgentUniv] = useCreateAgentUniMutation();
  const token = localStorage.getItem("token");
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  /**
   * @function notify
   * @description this function to create notify
   *
   * @param(msg)
   * @param(type)
   *
   * @return
   */
  const notify = (msg, type) => {
    if (type === "info") {
      toast.info(msg, {
        position: "top-left",
      });
    } else {
      toast.error(msg, {
        position: "top-left",
      });
    }
  };

  /**
   * @function addUser
   * @description this function to add User
   *
   * @return
   */
  const addUser = () => {
    if (jwtDecode(token).exp * 1000 < Date.now()) {
      clearLocalStorage();
      toastErrorServer(
        `${getTranslation(translate, "TOAST_ERROR_TOKEN_EXPIRED")}`
      );
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      setIsLoading(true);
      if (name === "" || email === "" || numero === "") {
        toastErrorServer(
          `${getTranslation(translate, "TOAST_ERROR_METADATA")}`
        );
      } else {
        switch (role) {
          case "ROLE_ADMIN_ALECSO": {
            let data = {
              username: name,
              email: email,
              adminName: user.username,
              adminMail: user.email,
              universityName: universityName,
              numero: numero,
              country,
            };

            return createRespUniv(data).then((res) => {
              if (res.data) {
                setIsLoading(false);
                setEmail("");
                setName("");
                setNumero("");
                setUniversityName("");
                setCountry("");
                notify(`${getTranslation(translate, "TOAST_SUCCESS")}`, "info");
              }
              if (res.error.status === 400) {
                setIsLoading(false);
                notify(
                  `${getTranslation(translate, "TOAST_ERROR_EMAIL_EXIST")}`,
                  "info"
                );
              } else {
                setIsLoading(false);
                notify(
                  `${getTranslation(translate, "TOAST_ERROR_SERVER_")}`,
                  "error"
                );
              }
            });
          }

          case "ROLE_RESP_UNI": {
            let data = {
              username: name,
              email: email,
              adminName: user.username,
              adminMail: user.email,
              universityName: user.universityName,
              speciality: speciality,
              numero: numero,
              country,
            };
            return createAgentUniv(data).then((res) => {
              if (res.data) {
                setIsLoading(false);
                setEmail("");
                setName("");
                setNumero("");
                setSpeciality("");
                notify(`${getTranslation(translate, "TOAST_SUCCESS")}`, "info");
              }
              if (res.error.status === 400) {
                setIsLoading(false);
                notify(
                  `${getTranslation(translate, "TOAST_ERROR_EMAIL_EXIST")}`,
                  "info"
                );
              } else {
                setIsLoading(false);
                notify(
                  `${getTranslation(translate, "TOAST_ERROR_SERVER_")}`,
                  "error"
                );
              }
            });
          }
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full p-4 space-y-2">
      <h1 className="mt-10 text-2xl font-bold text-[#25396f]">
        {getTranslation(translate, "PAGE_TITLE")}
      </h1>
      <p className="text-gray-600">{getTranslation(translate, "MESSAGE_")}</p>
      <div className="flex flex-col items-start justify-center w-full p-8 bg-white rounded h-fit">
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">
            {" "}
            {getTranslation(translate, "NAME")}
          </p>
          <Input
            icon={userIcon}
            type={"NAME"}
            placeholder={getTranslation(translate, "PLACEHOLDER_NAME")}
            inputChange={setName}
            value={name}
            setValid={setIsNameValid}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">
            {" "}
            {getTranslation(translate, "EMAIL")}
          </p>
          <Input
            icon={mailIcon}
            type={"EMAIL"}
            placeholder={getTranslation(translate, "PLACEHOLDER_EMAIL")}
            inputChange={setEmail}
            value={email}
            setValid={setIsEmailValid}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full">
          <p className="text-xl text-blue-900">
            {" "}
            {getTranslation(translate, "PHONE")}
          </p>
          <Input
            icon={mobileIcon}
            type={"NUMBER"}
            placeholder={getTranslation(translate, "PLACEHOLDER_PHONE")}
            inputChange={setNumero}
            value={numero}
            setValid={setIsNumeroValid}
          />
        </div>
        {role === "ROLE_RESP_UNI" && (
          <>
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-blue-900">
                {" "}
                {getTranslation(translate, "SPECIALITY")}{" "}
              </p>
              <Input
                icon={specialityIcon}
                type={"SPECIALITY"}
                placeholder={getTranslation(translate, "SPECIALITY")}
                inputChange={setSpeciality}
                value={speciality}
                setValid={setIsSpecialityValid}
              />
            </div>
          </>
        )}
        {role === "ROLE_ADMIN_ALECSO" && (
          <>
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-blue-900">
                {" "}
                {getTranslation(translate, "UNIVERSITY")}{" "}
              </p>
              <Input
                icon={univestyIcon}
                type={"University"}
                placeholder={getTranslation(
                  translate,
                  "PLACEHOLDER_UNIVERSITY"
                )}
                inputChange={setUniversityName}
                value={universityName}
                setValid={setIsUniversityNameValid}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-blue-900">
                {" "}
                {getTranslation(translate, "COUNTRY")}{" "}
              </p>
              <SelectCountry country={country} setCountry={setCountry} />
            </div>
          </>
        )}

        <div className="flex justify-end w-full">
          <div className="lg:w-1/3 sm:w-full md:w-1/3">
            {role === "ROLE_RESP_UNI" && (
              <MainButton
                isLoading={isLoading}
                action={addUser}
                title={getTranslation(translate, "BUTTON_TITLE")}
                disabled={
                  !isNameValid ||
                  !isEmailValid ||
                  !isNumeroValid ||
                  !isSpecialityValid
                }
              />
            )}

            {role === "ROLE_ADMIN_ALECSO" && (
              <>
                <MainButton
                  isLoading={isLoading}
                  action={addUser}
                  title={getTranslation(translate, "BUTTON_TITLE")}
                  disabled={
                    !isNameValid ||
                    !isEmailValid ||
                    !isNumeroValid ||
                    !isUniversityNameValid ||
                    country === ""
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUserPage;
