function isDiplomaFileHeaderValid(header) {
  return (
    header.includes("firstName") &&
    header.includes("lastName") &&
    header.includes("nin") &&
    header.includes("type") &&
    header.includes("speciality") &&
    header.includes("year")
  );
}
const DIPLOMA_METADATA_HEADER = {
  approvalDate: "approvalDate",
  average: "average",
  firstName: "firstName",
  honors: "honors",
  lastName: "lastName",
  ligne: 0,
  nin: "nin",
  signatureDate: "signatureDate",
  speciality: "speciality",
  type: "type",
  year: "year",
  language: "language",
  examsPvDate: "examsPvDate",
  paperPvDate: "paperPvDate",
  internshipPvDate: "internshipPvDate",
  diplomaName: "diplomaName",
};
const DIPLOMA_METADATA_REQUIRED_FIELDS = [
  "firstName",
  "lastName",
  "nin",
  "type",
  "speciality",
  "year",
];
export {
  isDiplomaFileHeaderValid,
  DIPLOMA_METADATA_HEADER,
  DIPLOMA_METADATA_REQUIRED_FIELDS,
};
