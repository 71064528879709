import React from "react";
import { useNavigate } from "react-router-dom";
import { getTranslation } from "../../utils/translate/translation";

const translate = {
  ar: {
    PAGE_TITLE: "     الصفحة غير موجودة",
    BACK: " رجوع",
  },
  en: {
    PAGE_TITLE: "Page not found",
    BACK: " Back",
  },
  fr: {
    PAGE_TITLE: "Page non trouvée",
    BACK: "Retoure",
  },
};
const NotFound = () => {
  const navigate = useNavigate();
  const back = () => {
    navigate("/dashboard");
  };
  return (
    <>
      <div className="flex items-center  justify-center h-[95vh] bg-[#f2f7ff]">
        <div className="grid gap-3">
          <span className="text-indigo-900 text-center font-extrabold text-9xl">
            404
          </span>
          <span className=" text-center text-blue-gray-900 font-bold text-5xl">
            {getTranslation(translate, "PAGE_TITLE")}
          </span>
        </div>
      </div>
      <div className="flex items-end justify-end p-1 bg-[#f2f7ff]">
        <button
          onClick={back}
          className="p-1  text-white bg-indigo-600 rounded text-md w-fi h-fit"
        >
          {getTranslation(translate, "BACK")}
        </button>
      </div>
    </>
  );
};

export default NotFound;
