/* eslint-disable no-undef */
import React from "react";
import ModalLayout from "../../../../layouts/ModalLayout";
import HeaderModal from "../LayoutsModal/HeaderModal";
import PropTypes from "prop-types";
import FooterModal from "../LayoutsModal/FooterModal";
import { useEffect } from "react";
import { useState } from "react";
import { getTranslation } from "../../../../utils/translate/translation";
import {
  isThereCommunValues,
  multilanguageReader,
} from "../../../../utils/translate/multilanguage-reader";
import { Fragment } from "react";
import { translate } from "../Translate/Translate";
import UpdateModeOneAndTwo from "../updateDiplomaModal/modeOneAndModaTwo/UpdateModeOneAndTwo";
import DeleteDiplomaModal from "../confirmDiplomaModal/DeleteDiplomaModal";
import Alert from "../../../alert/Alert";

const RejectedDiplomaModalModeTwo = ({
  item,
  role,
  setOpen,
  open,
  _deleteDiploma,
  showUpdate,
  setShowUpdate,
  filtered,
  setFiltered,
}) => {
  const [isShowing, setIsShowing] = useState(false);
  useEffect(() => {
    setIsShowing(true);
  }, [isShowing]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [diplomaDelete, setDiplomaDelete] = useState([]);
  const handleOpenDeleteModal = (item) => {
    setDiplomaDelete(item);
    setOpenDeleteModal(!openDeleteModal);
  };
  const diplomaLanguage = Object.keys(item?.type);
  const StudentLanguage = Object.keys(item?.student?.firstName);
  const exclamationIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
      />
    </svg>
  );
  return (
    <Fragment>
      <ModalLayout>
        <div className="relative w-auto w-max-[auto]  mx-auto my-6   overflow-hidden">
          {openDeleteModal ? (
            <DeleteDiplomaModal
              setOpenModal={setOpen}
              openModal={open}
              DiplomaToDelete={diplomaDelete}
              filtered={filtered}
              setFiltered={setFiltered}
              deleteDiploma={_deleteDiploma}
            />
          ) : (
            <div className="relative flex flex-col w-full bg-white border-0  rounded-lg shadow-sm outline-none focus:outline-none">
              <HeaderModal translate={translate} item={item} />
              {(showUpdate && item?.mode === "modeOne") ||
              (item?.mode === "modeTwo" && showUpdate) ? (
                <UpdateModeOneAndTwo
                  translate={translate}
                  item={item}
                  showUpdate={showUpdate}
                  setShowUpdate={setShowUpdate}
                  open={open}
                  setOpen={setOpen}
                  filtered={filtered}
                  setFiltered={setFiltered}
                />
              ) : (
                <div className="p-2">
                  {isThereCommunValues(diplomaLanguage, StudentLanguage) ===
                    false && (
                    <Alert
                      type={"error"}
                      message={" بيانات الطالب والشهادة مسجلة بلغات مختلفة"}
                      icon={exclamationIcon}
                    />
                  )}
                  <div className="relative flex-auto p-4 h-fit">
                    <div className="w-auto h-auto">
                      <div className="border-solid ">
                        <div className="flex  w-[auto] justify-between">
                          <div className=" w-[500px] ">
                            <h5 className="font-bold text-[#607080]">
                              {getTranslation(translate, "METADATA")}
                            </h5>
                            <div className="mr-8 pt-2">
                              <div className="flex  ">
                                <span className="flex items-center gap-2  w-40 font-semibold text-[#607080]">
                                  <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                                  {getTranslation(translate, "STUDENT")}
                                </span>
                                <span className="text-[#607080] ">
                                  :
                                  {multilanguageReader(
                                    item?.student?.firstName
                                  )}{" "}
                                  {multilanguageReader(item?.student?.lastName)}
                                </span>
                              </div>
                              <div className="flex ">
                                <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                                  <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                                  {getTranslation(translate, "DATE_OF_BRITH")}
                                </span>
                                <span className="text-[#607080]">
                                  :
                                  {item?.student?.dateOfBirth === "Invalid date"
                                    ? null
                                    : item?.student?.dateOfBirth}
                                </span>
                              </div>
                              <div className="flex ">
                                <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                                  <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                                  {getTranslation(translate, "PLACE_OF_BRITH")}
                                </span>
                                <span className="text-[#607080]">
                                  :{" "}
                                  {multilanguageReader(
                                    item?.student?.countryOfBirth
                                  )}
                                </span>
                              </div>
                              <div className="flex ">
                                <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                                  <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                                  {getTranslation(translate, "NIN")}
                                </span>
                                <span className="text-[#607080]">
                                  : {item?.student?.nin}
                                </span>
                              </div>
                              <div className="flex ">
                                <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                                  <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                                  {getTranslation(translate, "TYPE")}
                                </span>
                                <span className="text-[#607080]">
                                  : {multilanguageReader(item?.type)}
                                </span>
                              </div>
                              <div className="flex ">
                                <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                                  <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                                  {getTranslation(translate, "YEAR")}
                                </span>
                                <span className="text-[#607080] ">
                                  : {item?.year}
                                </span>
                              </div>
                              <div className="flex ">
                                <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                                  <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                                  {getTranslation(translate, "SPECIALITY")}
                                </span>
                                <span className="text-[#607080]">
                                  : {multilanguageReader(item?.speciality)}
                                </span>
                              </div>
                              <div className="flex ">
                                <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                                  <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>{" "}
                                  {getTranslation(translate, "HONORS")}
                                </span>
                                <span className="text-[#607080]">
                                  : {multilanguageReader(item?.honors)}
                                </span>
                              </div>
                              <div className="flex ">
                                <span className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                                  <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>
                                  {getTranslation(translate, "AVERAGE")}
                                </span>
                                <span className="text-[#607080]">
                                  : {item?.average}
                                </span>
                              </div>
                              <div className="flex ">
                                <p className="flex items-center gap-2 w-40 font-semibold text-[#607080]">
                                  <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>
                                  {getTranslation(translate, "APPROVAL_DATE")}
                                </p>
                                <span className="text-[#607080]">
                                  :
                                  {item?.approvalDate === "Invalid date"
                                    ? null
                                    : item?.approvalDate}
                                </span>
                              </div>
                              <div className="flex ">
                                <p className="flex items-center gap-2  w-40 font-semibold text-[#607080]">
                                  <div className="w-[5px] h-[5px] bg-[#607080] rounded-lg "></div>
                                  {getTranslation(translate, "SIGNATURE_DATE")}
                                </p>
                                <span className="text-[#607080]">
                                  :
                                  {item?.signatureDate === "Invalid date"
                                    ? null
                                    : item?.signatureDate}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <FooterModal
                translate={translate}
                role={role}
                item={item}
                handleOpenDeleteModal={handleOpenDeleteModal}
                open={open}
                setOpen={setOpen}
                _deleteDiploma={_deleteDiploma}
                setShowUpdate={setShowUpdate}
                showUpdate={showUpdate}
              />
            </div>
          )}
        </div>
      </ModalLayout>
    </Fragment>
  );
};
RejectedDiplomaModalModeTwo.propTypes = {
  item: PropTypes.object,
  role: PropTypes.string,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  filtered: PropTypes.array,
  setFiltered: PropTypes.func,
  _deleteDiploma: PropTypes.func,
  showUpdate: PropTypes.bool,
  setShowUpdate: PropTypes.func,
  updateDiplomaRejectOrAccept: PropTypes.func,
};
export default RejectedDiplomaModalModeTwo;
