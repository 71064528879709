import React from "react";
import { getTranslation } from "../../utils/translate/translation";

import DiplomasTable from "./DiplomasTable";
const translate = {
  ar: {
    PAGE_TITLE: "طلبات إضافة شهادات بانتظار التأكيد",
    PAGE_TITLE_ONE: "قائمة الشهادات",
  },
  en: {
    PAGE_TITLE: "Requests to add certificates are awaiting confirmation",
    PAGE_TITLE_ONE: "Diplomas list",
  },
  fr: {
    PAGE_TITLE:
      "Les demandes d'ajout de certificats sont en attente de confirmation",
    PAGE_TITLE_ONE: " Liste des Diplomes",
  },
};
const DiplomaPendingList = () => {
  return (
    <div>
      <div className="flex flex-col justify-end mt-10 px-[3%] ">
        <h1 className="mb-2 text-2xl font-bold text-[#25396f]">
          {getTranslation(translate, "PAGE_TITLE")}
        </h1>
        <div className="max-w-[auto] flex flex-col items-center justify-center w-full p-4 bg-white h-fit rounded-md">
          <div className="w-full">
            <div>
              <h5 className="px-6 font-bold text-blue-900 ">
                {getTranslation(translate, "PAGE_TITLE_ONE")}
              </h5>
              <DiplomasTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiplomaPendingList;
