import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainButton from "../../components/button/MainButton";
import PropTypes from "prop-types";
import {
  ADD_FROM_CSV,
  SELECT_STUDENT,
} from "../../store/features/studentsSlice/studentsSlice";
import { useAddStudentsMutation } from "../../store/service/studentsService";
import VerifyToken from "../../utils/VerifyToken";
import { logout } from "../../utils/logout";
import { toastErrorServer, toastSucces } from "../../components/toast/Toast";
import { getTranslation } from "../../utils/translate/translation";

const translate = {
  ar: {
    TOAST_ERROR: "هناك خطأ من السرفر ",
    TOAST_SUCCESS: "تمت العملية بنجاح !",
    PAGE_TITLE: "بيانات الطلبة",
    FIRSTNAME: "الاسم الأول ",
    LASTNAME: "اسم العائلة ",
    NIN: "المعرف الوطني ",
    DATE_OF_BRITH: "تاريخ الولادة",
    BRITH_PLACE: "مكان الولادة ",
    GENDER: "الجنس",
    NATIONALITY: "الجنسية",
    EMAIL: "البريد الالكتروني ",
    PHONE: "الهاتف",
    EDIT: "تعديل",
    DELETE: "حذف",
    BUTTON_TITEL: "تأكيد الإضافة",
  },
  en: {
    TOAST_ERROR: " There is an error from the server",
    TOAST_SUCCESS: "Operation accomplished successfully !",
    PAGE_TITLE: "Student data",
    FIRSTNAME: "First Name",
    LASTNAME: "Last Name",
    NIN: "Identifier",
    DATE_OF_BRITH: "Birth date",
    BRITH_PLACE: "Place of birth ",
    GENDER: "Sex",
    NATIONALITY: "Nationality",
    EMAIL: "Email",
    PHONE: "Phone",
    EDIT: "Update",
    DELETE: "Delete",
    BUTTON_TITEL: "Add",
  },
  fr: {
    TOAST_ERROR: "Il y a une erreur du serveur ",
    TOAST_SUCCESS: " Opération réussie !",
    PAGE_TITLE: "Données étudiantes",
    FIRSTNAME: "Prénom",
    LASTNAME: "Nom",
    NIN: "Identifiant ",
    DATE_OF_BRITH: "Date de naissance",
    BRITH_PLACE: "Lieu de naissance",
    GENDER: "Sexe",
    NATIONALITY: "Nationalité",
    EMAIL: "Email",
    PHONE: "Téléphone",
    EDIT: "Mise à jour",
    DELETE: "Supprimer",
    BUTTON_TITEL: "Ajouter",
  },
};
function AddStudentsTable({
  setErrorAdd,
  errorTable,
  updateTable,
  setOpenModal,
  setKey,
  setStudentToDelete,
}) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let [addStudents] = useAddStudentsMutation();
  const studentsToAdd = useSelector((state) => state.students.studentsToAdd);
  const [isLoading, setIsLoading] = useState(false);
  const studentsData = [...studentsToAdd];

  /**
   * @function openModal
   * @description this function to open Model and  delete Student
   *
   * @param(key)
   * @param(student)
   *
   * @return
   */
  const openModal = (key, student) => {
    setOpenModal((prev) => !prev);
    setKey(key);
    setStudentToDelete(student);
  };

  function mergeObjects(arr) {
    const grouped = arr.reduce((acc, curr) => {
      if (!acc[curr.nin]) {
        acc[curr.nin] = {
          nin: curr.nin,
          firstName: {},
          lastName: {},
          countryOfBirth: {},
          dateOfBirth: curr.dateOfBirth,
          sex: {},
          nationalty: {},
          phone: curr.phone,
          email: curr.email,
        };
      }
      acc[curr.nin].firstName[curr.language] = curr.firstName;
      acc[curr.nin].lastName[curr.language] = curr.lastName;
      acc[curr.nin].sex[curr.language] = curr.sex;
      acc[curr.nin].nationalty[curr.language] = curr.nationalty;
      acc[curr.nin].countryOfBirth[curr.language] = curr.countryOfBirth;

      return acc;
    }, {});

    return Object.values(grouped);
  }
  const uploadJSON = () => {
    let mergedData = mergeObjects(studentsData);
    let data = mergedData.map((student) => {
      return {
        firstName: student.firstName,
        lastName: student.lastName,
        nin: student.nin,
        dateOfBirth: student.dateOfBirth,
        countryOfBirth: student.countryOfBirth,
        sex: student.sex ? student.sex : "",
        nationalty: student.nationalty ? student.nationalty : "",
        phone: student.phone ? student.phone : "",
        email: student.email ? student.email : "",
      };
    });

    return data;
  };

  /**
   * @function addStudentsClick
   * @description this function to Add Students
   *
   * @return
   */
  const addStudentsClick = () => {
    setIsLoading(true);
    if (!VerifyToken()) {
      logout();
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      addStudents({ students: uploadJSON() }).then(async (res) => {
        if (res?.error?.status === 400) {
          toastErrorServer(`${getTranslation(translate, "TOAST_ERROR")}`);
          setIsLoading(false);
        } else if (
          ((await res?.data?.notAddedStudents) !== undefined &&
            res?.data?.notAddedStudents.length !== 0) ||
          ((await res?.data?.updatedStudents) !== undefined &&
            res?.data?.updatedStudents.length !== 0)
        ) {
          await setErrorAdd(true);

          await errorTable.push([res?.data?.notAddedStudents]);
          await updateTable.push([res?.data?.updatedStudents]);
          setIsLoading(false);
        } else {
          toastSucces(`${getTranslation(translate, "TOAST_SUCCESS")}`);
          setIsLoading(false);
        }
      });
    }
    dispatch(ADD_FROM_CSV({ studentsToAdd: [] }));
  };

  return (
    <div className="flex flex-col justify-start ">
      <div className="flex items-center w-full justify-between">
        <h1 className="p-4 w-full ">
          {" "}
          {getTranslation(translate, "PAGE_TITLE")}
        </h1>
        <div className="w-full  flex justify-end p-2 ">
          <div className="flex  w-32">
            <MainButton
              title={getTranslation(translate, "BUTTON_TITEL")}
              action={addStudentsClick}
              isLoading={isLoading}
              disabled={studentsToAdd?.length == 0}
            />
          </div>
        </div>
      </div>

      <div className="relative  overflow-x-auto  sm:rounded-lg h-[auto] ">
        <table className="w-full text-sm  text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "FIRSTNAME")}
              </th>
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "LASTNAME")}
              </th>
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "NIN")}
              </th>
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "DATE_OF_BRITH")}
              </th>
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "BRITH_PLACE")}
              </th>
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "GENDER")}
              </th>
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "NATIONALITY")}
              </th>

              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "PHONE")}
              </th>
              <th scope="col" className="px-6 py-3">
                {getTranslation(translate, "EMAIL")}
              </th>
              <th scope="col" className="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody className="overflow-x-hidden h-[auto]">
            {studentsToAdd?.map((student, key) => {
              return (
                <tr
                  key={key}
                  className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                    (key + 1) % 2 !== 0 ? `bg-blue-gray-50` : `bg-white`
                  }`}
                >
                  <th className="px-6 py-2"> {student.firstName}</th>
                  <td className="px-6 py-2"> {student.lastName}</td>
                  <td className="px-6 py-2">{student.nin}</td>
                  <td className="px-6 py-2"> {student.dateOfBirth}</td>
                  <td className="px-6 py-2"> {student.countryOfBirth}</td>
                  <td className="px-6 py-2"> {student.sex}</td>
                  <td className="px-6 py-2"> {student.nationalty}</td>
                  <td className="px-6 py-2"> {student.phone}</td>
                  <td className="px-6 py-2"> {student.email}</td>

                  <td className="flex items-center justify-center px-6 py-2 ">
                    <button
                      onClick={() => {
                        dispatch(
                          SELECT_STUDENT({
                            studentSelected: { ...student, key },
                          })
                        );
                        navigate("/update_one_student");
                      }}
                      className="p-1 mx-1 text-xs font-bold flex gap-1 items-center text-white bg-blue-400 rounded w-fi h-fit"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                      {getTranslation(translate, "EDIT")}
                    </button>

                    <button
                      onClick={() => {
                        openModal(key, student);
                      }}
                      className="p-1 mx-1 text-xs font-bold flex gap-1 items-center text-white bg-red-400 rounded w-fi h-fit"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                      {getTranslation(translate, "DELETE")}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
AddStudentsTable.propTypes = {
  setErrorAdd: PropTypes.func,
  errorTable: PropTypes.array,
  updateTable: PropTypes.array,
  setOpenModal: PropTypes.func,
  setKey: PropTypes.func,
  setStudentToDelete: PropTypes.func,
};
export default AddStudentsTable;
