/* eslint-disable react/prop-types */
import React, { useState } from "react";
import SmallButton from "../../components/button/SmallButton";
import PropTypes from "prop-types";
import moment from "moment";
import { GET_DIPLOMAS } from "../../store/features/diplomasSlice/diplomasSlice";
import { useDispatch } from "react-redux";
import { DownArrow, UpArrow } from "../../assets/svg/Index";
import { getTranslation } from "../../utils/translate/translation";
import {
  isThereCommunValues,
  multilanguageReader,
} from "../../utils/translate/multilanguage-reader";
import { reverseDate } from "../../utils/converts/reverseDate";

const translate = {
  ar: {
    NAME: "الطالب(ة)",
    NIN: "   المعرف الوطني",
    TYPE: "نوع الشهادة	",
    SPECIALITY: "الاختصاص",
    HONORS: "الملاحظة",
    SIGNATURE_DATE: "تاريخ الإمضاء",
    OPEN: "اطّلاع",
    REJECTED: "إلغاء",
  },
  en: {
    NAME: "Student",
    NIN: "ID",
    TYPE: " Type Diploma",
    SPECIALITY: "Speciality",
    HONORS: "Note",
    SIGNATURE_DATE: "Signature date",
    OPEN: "Look",
    REJECTED: "Reject",
  },
  fr: {
    NAME: "Étudiant",
    NIN: "Identifiant",
    TYPE: "Type de Diplome",
    SPECIALITY: "Spécialité",
    HONORS: "Note",
    SIGNATURE_DATE: "Date de la signature",
    OPEN: "Regarder",
    REJECTED: "Rejeter",
  },
};
export const Table = ({
  filtered,
  getDiploma,
  updateDiplomaRejectOrAccept,
  handleOpen,
  // eslint-disable-next-line react/prop-types
  setFiltered,
  currentPage,
  perPage,
}) => {
  const dispatch = useDispatch();
  const [nameClicked, setNameClicked] = useState(false);
  const [typeClicked, setTypeClicked] = useState(false);
  const [specialityClicked, setSpecialityClicked] = useState(false);
  const [dateClicked, setDateClicked] = useState(false);

  const sortByName = (field, status) => {
    return (a, b) => {
      if (a.student[field].en > b.student[field].en) {
        return status ? -1 : 1;
      }
      if (b.student[field].en > a.student[field].en) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };
  const sortBySpeciality = (field, status) => {
    return (a, b) => {
      if (a[field].en > b[field].en) {
        return status ? -1 : 1;
      }
      if (b[field].en > a[field].en) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };
  const sortByType = (field, status) => {
    return (a, b) => {
      if (a[field].en > b[field].en) {
        return status ? -1 : 1;
      }
      if (b[field].en > a[field].en) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };
  const onNameClick = () => {
    setNameClicked(!nameClicked);
    const sorted = [...filtered].sort(sortByName("firstName", nameClicked));
    setFiltered(sorted);
    dispatch(GET_DIPLOMAS({ diplomas: sorted }));
  };

  const onSpecialityClick = () => {
    setSpecialityClicked(!specialityClicked);
    const sorted = [...filtered].sort(
      sortBySpeciality("speciality", specialityClicked)
    );
    setFiltered(sorted);
    dispatch(GET_DIPLOMAS({ diplomas: sorted }));
  };

  const onDateClick = () => {
    setDateClicked(!dateClicked);
    if (dateClicked) {
      const sorted = [...filtered].sort((a, b) => {
        return moment(b.signatureDate).isBefore(moment(a.signatureDate))
          ? 1
          : -1;
      });
      setFiltered(sorted);
      dispatch(GET_DIPLOMAS({ diplomas: sorted }));
    } else {
      const sorted = [...filtered].sort((a, b) => {
        return moment(a.signatureDate).isBefore(moment(b.signatureDate))
          ? 1
          : -1;
      });
      setFiltered(sorted);
      dispatch(GET_DIPLOMAS({ diplomas: sorted }));
    }
  };

  const onTypeClick = () => {
    setTypeClicked(!typeClicked);
    const sorted = [...filtered].sort(sortByType("type", typeClicked));
    setFiltered(sorted);
    dispatch(GET_DIPLOMAS({ diplomas: sorted }));
  };
  const language = localStorage.getItem("language");
  const rejectedIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-5 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );

  return (
    <table
      className={
        language === "ar"
          ? "w-full text-sm text-gray-500 text-right  dark:text-gray-400 "
          : "w-full text-sm text-gray-500 text-left  dark:text-gray-400 "
      }
    >
      <thead className="text-xs text-gray-900 uppercase dark:bg-gray-700 dark:text-gray-400 ">
        <tr>
          <th scope="col" className="px-2 py-3 cursor-pointer text-[#607080]">
            <div
              className="flex items-center justify-start"
              onClick={onNameClick}
            >
              <span>{getTranslation(translate, "NAME")} </span>
              {nameClicked ? (
                <div className="cursor-pointer text-[#607080] ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-2 py-3 text-[#607080] ">
            {getTranslation(translate, "NIN")}
          </th>
          <th scope="col" className="px-2 py-3 cursor-pointer text-[#607080]">
            <div
              className="flex items-center justify-start"
              onClick={onTypeClick}
            >
              <span> {getTranslation(translate, "TYPE")}</span>
              {typeClicked ? (
                <div className="cursor-pointer text-[#607080] ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-1 py-3 cursor-pointer text-[#607080]">
            <div
              className="flex items-center justify-start"
              onClick={onSpecialityClick}
            >
              <span> {getTranslation(translate, "SPECIALITY")}</span>
              {specialityClicked ? (
                <div className="cursor-pointer text-[#607080] ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer text-[#607080] ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-2 py-3 text-[#607080]">
            {getTranslation(translate, "HONORS")}
          </th>
          <th scope="col" className="px-2 py-3 cursor-pointer text-[#607080]">
            <div
              className="flex items-center justify-start text-[#607080]"
              onClick={onDateClick}
            >
              <span> {getTranslation(translate, "SIGNATURE_DATE")} </span>
              {dateClicked ? (
                <div className="cursor-pointer text-[#607080] ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer text-[#607080] ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-2 py-3 "></th>
        </tr>
      </thead>
      <tbody className="overflow-x-hidden h-10vh text-[#607080]">
        {filtered
          ?.slice(currentPage * perPage - perPage, currentPage * perPage)
          .map((item, key) => (
            <tr
              key={key}
              className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                (key + 1) % 2 !== 0 &&
                isThereCommunValues(
                  Object.keys(item?.type),
                  Object.keys(item?.student?.firstName)
                ) === false
                  ? `border-2 border-[#dc3545] bg-[#f2f2f2] `
                  : `bg-white  `
              }`}
            >
              <td className="px-2 py-2">
                {multilanguageReader(item?.student?.firstName)}{" "}
                {multilanguageReader(item?.student?.lastName)}
              </td>
              <td className="px-2 py-2">{item?.student?.nin}</td>
              <td className="px-2 py-2">{multilanguageReader(item?.type)}</td>
              <td className="px-2 py-2">
                {multilanguageReader(item?.speciality)}
              </td>
              <td className="px-2 py-2">{multilanguageReader(item?.honors)}</td>
              <td className="px-2 py-2">
                {item?.signatureDate === "Invalid date"
                  ? null
                  : reverseDate(item?.signatureDate)}
              </td>
              <td className="flex items-center  px-2 py-2">
                <button
                  className="px-2 py-1 mx-1 text-xs flex items-center gap-1 font-semibold text-white bg-[#198754] rounded w-fi h-fit"
                  onClick={() => {
                    getDiploma({ item, key });
                    handleOpen();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59"
                    />
                  </svg>
                  {getTranslation(translate, "OPEN")}
                </button>

                <SmallButton
                  title={getTranslation(translate, "REJECTED")}
                  className="px-2 py-1 mx-1 text-xs font-semibold text-white bg-[#dc3545] rounded w-fi h-fit"
                  action={() => {
                    updateDiplomaRejectOrAccept({ item });
                  }}
                  isLoading={false}
                  icon={rejectedIcon}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
Table.propTypes = {
  filtered: PropTypes.array,
  getDiploma: PropTypes.func,
  updateDiplomaRejectOrAccept: PropTypes.func,
  isLoading: PropTypes.bool,
  handleOpen: PropTypes.func,
};
