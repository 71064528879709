import { getTranslation } from "../translate/translation";

const translate = {
  ar: {
    FIRSTNAME: "الاسم الأول",
    LASTNAME: "اسم العائلة",
    NIN: "المعرّف",
    TYPE: "نوع الشهادة",
    SPECIALITY: "الاختصاص",
    YEAR: "ألسنة",
  },
  en: {
    FIRSTNAME: "First Name",
    LASTNAME: "Last Name",
    NIN: "ID",
    TYPE: "Diploma Type",
    SPECIALITY: "Specialty",
    YEAR: "Year",
  },
  fr: {
    FIRSTNAME: "Prénom",
    LASTNAME: "Nom de famille",
    NIN: "Identifiant",
    TYPE: "Type de Diplome",
    SPECIALITY: "Spécialité",
    YEAR: "Année",
  },
};
/**
 * @function exports.convertNumbers2English
 * @description convert number arabic to English
 *
 * @param {*} string
 *
 * @returns
 */
const convertNumbers2English = (string) => {
  return string
    .replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (c) {
      return c.charCodeAt(0) - 1632;
    })
    .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (c) {
      return c.charCodeAt(0) - 1776;
    })
    .replaceAll("/", "-");
};

/**
 * @function exports.convertSlash2Dash
 * @description convert slash to dash
 *
 * @param {*} string
 *
 * @returns
 */
const convertSlash2Dash = (string) => {
  return string
    .replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (c) {
      return c.charCodeAt(0) - 1632;
    })
    .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (c) {
      return c.charCodeAt(0) - 1776;
    })
    .replaceAll("/", "-");
};
const getFields = (fields) => {
  const Fields = {
    firstName: `${getTranslation(translate, "FIRSTNAME")}`,
    lastName: `${getTranslation(translate, "LASTNAME")}`,
    nin: `${getTranslation(translate, "NIN")}`,
    type: `${getTranslation(translate, "TYPE")}`,
    speciality: `${getTranslation(translate, "SPECIALITY")}`,
    year: `${getTranslation(translate, "YEAR")}`,
  };

  return fields.map((field) => Fields[field]);
};

export { convertNumbers2English, convertSlash2Dash, getFields };
