import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import {
  ADD_FROM_CSV_DIPLOMAS,
  SELECTED_MODE,
} from "../../store/features/diplomasSlice/diplomasSlice";
import { getTranslation } from "../../utils/translate/translation";
import {
  isNotEmpty,
  isNotEmptyAndContainsOnlyLetters,
  isValidDate,
  isValidNumber,
} from "../../utils/Validators/Validators";

const translate = {
  ar: {
    PAGE_TITLE: "تعديل شهادة ",
    NIN: "المعرف الوطني ",
    TYPE: " نوع الشهادة ",
    SPECIALITY: "الاختصاص",
    APPROVAL_DATE: "تاريخ المصادقة",
    SIGNATURE_DATE: "تاريخ الإمضاء",
    YEAR: "السنة الجامعية",
    HONORS: "الملاحظة",
    AVERAGE: "المعدّل / المجموع",
    EXAMS_PV_DATE: "  تاريخ لجنة التحكيم الامتحانات",
    INERNSHIP_PV_DATE: " تاريخ هيئة التحكيم للدفاع عن مشروع",
    PAPER_PV_DATE: "   تاريخ هيئة التحكيم التحقق من التدرج المؤرخ",
    DIPLOMA_NAME: "إسم الشهادة",
    BUTTON_TITLE: " تعديل",
    BACK: "  الرجوع",
  },
  en: {
    PAGE_TITLE: "Edit Certificate",
    NIN: "National ID",
    TYPE: "Certificate Type",
    SPECIALITY: "Specialty",
    APPROVAL_DATE: "Approval Date",
    SIGNATURE_DATE: "Signature Date",
    YEAR: "Academic Year",
    HONORS: "Honors",
    AVERAGE: "Average/Total",
    EXAMS_PV_DATE: "Examination Committee PV Date",
    INERNSHIP_PV_DATE: "Internship Defense Committee PV Date",
    PAPER_PV_DATE: "Progress Verification Committee PV Date",
    DIPLOMA_NAME: "Diploma Name",
    BUTTON_TITLE: "Edit",
    BACK: "Back",
  },
  fr: {
    PAGE_TITLE: "Modifier le Certificat",
    NIN: "Identifiant National",
    TYPE: "Type de Certificat",
    SPECIALITY: "Spécialité",
    APPROVAL_DATE: "Date d'Approbation",
    SIGNATURE_DATE: "Date de Signature",
    YEAR: "Année Académique",
    HONORS: "Mentions",
    AVERAGE: "Moyenne/Total",
    EXAMS_PV_DATE: "Date du PV du Comité d'Examen",
    INERNSHIP_PV_DATE: "Date du PV du Comité de Soutenance de Stage",
    PAPER_PV_DATE: "Date du PV du Comité de Vérification de Progrès",
    DIPLOMA_NAME: "Nom du Diplôme",
    BUTTON_TITLE: "Modifier",
    BACK: "Retour",
  },
};
function UpdateOneDiploma() {
  const diploma = useSelector((state) => state.diploma);
  const modeSelected = useSelector((state) => state.diploma.modeSelected);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nin, setNin] = useState("");
  const [type, setType] = useState("");
  const [speciality, setSpecialty] = useState("");
  const [year, setYear] = useState("");
  const [approvalDate, setApprovalDate] = useState("");
  const [examsPvDate, setExamsPvDate] = useState("");
  const [internshipPvDate, setInternshipPvDate] = useState("");
  const [paperPvDate, setPaperPvDate] = useState("");
  const [diplomaName, setDiplomaName] = useState("");
  const [signatureDate, setSignatureDate] = useState("");
  const [honors, setHonors] = useState("");
  const [average, setAverage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  /**
   * @function updatedDiploma
   * @description this function to update diploma
   *
   * @return
   */
  const updatedDiploma = () => {
    let diplomasTable = [...diploma.diplomasToAdd];
    let diplomasTableTwo = [...diploma.diplomasToAddTwo];
    let diplomasTableThree = [...diploma.diplomasToAddThree];
    let updatedDiploma = {
      firstName: firstName,
      lastName: lastName,
      nin: nin,
      type: type,
      speciality: speciality,
      honors: honors,
      approvalDate: approvalDate,
      signatureDate: signatureDate,
      average: average,
      examsPvDate: examsPvDate,
      internshipPvDate: internshipPvDate,
      paperPvDate: paperPvDate,
      diplomaName: diplomaName,
      year: year,
      mode: modeSelected,
    };
    switch (modeSelected) {
      case "modeOne":
        diplomasTable[diploma.currentDiploma.key] = updatedDiploma;
        break;
      case "modeTwo":
        diplomasTableTwo[diploma.currentDiploma.key] = updatedDiploma;
        break;
      case "modeThree":
        diplomasTableThree[diploma.currentDiploma.key] = updatedDiploma;
    }
    onSelectedModeDispatch(diplomasTable, diplomasTableTwo, diplomasTableThree);
  };

  const onSelectedModeDispatch = (
    diplomasTable,
    diplomasTableTwo,
    diplomasTableThree
  ) => {
    switch (modeSelected) {
      case "modeOne":
        dispatch(
          ADD_FROM_CSV_DIPLOMAS({
            diplomasToAdd: diplomasTable,
          })
        );
        break;
      case "modeTwo":
        dispatch(
          ADD_FROM_CSV_DIPLOMAS({
            diplomasToAddTwo: diplomasTableTwo,
          })
        );
        break;
      case "modeThree":
        dispatch(
          ADD_FROM_CSV_DIPLOMAS({
            diplomasToAddThree: diplomasTableThree,
          })
        );
    }
  };
  /**
   * @function initializeDiplomaData
   * @description this function to initialze diplomas data
   *
   * @param {*} currentDiploma
   *
   * @return
   */
  const initializeDiplomaData = (currentDiploma) => {
    setFirstName(currentDiploma?.firstName || "");
    setLastName(currentDiploma?.lastName || "");
    setNin(currentDiploma?.nin || "");
    setType(currentDiploma?.type || "");
    setSpecialty(currentDiploma?.speciality || "");
    setYear(currentDiploma?.year?.toString() || "");
    setApprovalDate(currentDiploma?.approvalDate || "");
    setExamsPvDate(currentDiploma?.examsPvDate || "");
    setInternshipPvDate(currentDiploma?.internshipPvDate || "");
    setPaperPvDate(currentDiploma?.paperPvDate || "");
    setDiplomaName(currentDiploma?.diplomaName || "");
    setAverage(
      currentDiploma?.average?.toString() !== undefined
        ? currentDiploma?.average?.toString()
        : ""
    );
    setHonors(currentDiploma?.honors || "");
    setSignatureDate(currentDiploma?.signatureDate || "");
  };

  useEffect(() => {
    const { currentDiploma } = diploma;
    let isCurrentDiplomaHasProperties =
      Object.keys(currentDiploma).length !== 0;
    if (isCurrentDiplomaHasProperties) {
      initializeDiplomaData(currentDiploma);
    } else {
      navigate("/add_diplomas");
    }
  }, []);

  useEffect(() => {
    let _isDisabled =
      !isNotEmptyAndContainsOnlyLetters(type) ||
      !isNotEmptyAndContainsOnlyLetters(speciality) ||
      !isNotEmptyAndContainsOnlyLetters(diplomaName) ||
      !isNotEmpty(honors) ||
      !isValidNumber(average) ||
      !isValidNumber(year) ||
      !isValidDate(signatureDate) ||
      !isValidDate(paperPvDate) ||
      !isValidDate(examsPvDate) ||
      !isValidDate(internshipPvDate) ||
      !isValidDate(approvalDate);

    setIsDisabled(_isDisabled);
  }, [
    type,
    speciality,
    honors,
    average,
    year,
    signatureDate,
    approvalDate,
    isDisabled,
    examsPvDate,
    internshipPvDate,
    paperPvDate,
    diplomaName,
  ]);
  return (
    <div className="flex flex-col p-[3%]">
      <h1 className="text-2xl text-[#25396f] font-semibold">
        {getTranslation(translate, "PAGE_TITLE")}
      </h1>
      <div className="bg-white rounded">
        <div className="items-center justify-center w-full pt-8 lg:flex h-fit">
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]">
                {getTranslation(translate, "NIN")}
              </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={nin}
                inputChange={setNin}
                value={nin}
                disabled
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]">
                {" "}
                {getTranslation(translate, "TYPE")}
              </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={type}
                inputChange={setType}
                value={type}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]">
                {" "}
                {getTranslation(translate, "SPECIALITY")}{" "}
              </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={speciality}
                inputChange={setSpecialty}
                value={speciality}
              />
            </div>
          </div>
        </div>
        <div className="items-center justify-center w-full pt-8 lg:flex h-fit">
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]">
                {" "}
                {getTranslation(translate, "APPROVAL_DATE")}{" "}
              </p>
              <Input
                type={"DATE_FORMAT"}
                placeholder={approvalDate}
                inputChange={setApprovalDate}
                value={approvalDate}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]">
                {" "}
                {getTranslation(translate, "SIGNATURE_DATE")}{" "}
              </p>
              <Input
                type={"DATE_FORMAT"}
                placeholder={signatureDate}
                inputChange={setSignatureDate}
                value={signatureDate}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]">
                {" "}
                {getTranslation(translate, "YEAR")}{" "}
              </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={year}
                inputChange={setYear}
                value={year}
              />
            </div>
          </div>
        </div>
        <div className="items-center justify-center w-full pt-8 lg:flex h-fit">
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]">
                {" "}
                {getTranslation(translate, "HONORS")}{" "}
              </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={honors}
                inputChange={setHonors}
                value={honors}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]">
                {" "}
                {getTranslation(translate, "AVERAGE")}{" "}
              </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={average}
                inputChange={setAverage}
                value={average}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]">
                {getTranslation(translate, "EXAMS_PV_DATE")}
              </p>
              <Input
                type={"DATE_FORMAT"}
                placeholder={examsPvDate}
                inputChange={setExamsPvDate}
                value={examsPvDate}
              />
            </div>
          </div>
        </div>
        <div className="items-center justify-center w-full pt-8 lg:flex h-fit">
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]">
                {getTranslation(translate, "INERNSHIP_PV_DATE")}
              </p>
              <Input
                type={"DATE_FORMAT"}
                placeholder={internshipPvDate}
                inputChange={setInternshipPvDate}
                value={internshipPvDate}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]">
                {getTranslation(translate, "PAPER_PV_DATE")}
              </p>
              <Input
                type={"DATE_FORMAT"}
                placeholder={paperPvDate}
                inputChange={setPaperPvDate}
                value={paperPvDate}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-xl text-[#25396f]">
                {" "}
                {getTranslation(translate, "DIPLOMA_NAME")}
              </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={diplomaName}
                inputChange={setDiplomaName}
                value={diplomaName}
              />
            </div>
          </div>
        </div>
        <div className="items-center justify-center mx-2 lg:flex lg:flex-row lg:w-1/3 lg:gap-5 sm:flex sm:flex-col sm:gap-2  pt-6 pb-6">
          <MainButton
            title={getTranslation(translate, "BUTTON_TITLE")}
            action={() => {
              updatedDiploma();
              navigate("/add_diplomas");
            }}
            disabled={isDisabled}
          />
          <button
            type="submit"
            onClick={() => {
              dispatch(SELECTED_MODE({ modeSelected: modeSelected }));
              navigate(-1);
            }}
            className="bg-[#e6eaee] text-[#1a2026] w-full leading-normal font-medium uppercase text-base p-2 rounded shadow hover:shadow-lg hover:bg-[#e1e3e5] outline-none focus:outline-none  ease-linear transition-all duration-150"
          >
            {getTranslation(translate, "BACK")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default UpdateOneDiploma;
