/* eslint-disable no-undef */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import { toastErrorServer, toastSucces } from "../../components/toast/Toast";
import { useGetUserByIdMutation } from "../../store/service/userService";
import { getTranslation } from "../../utils/translate/translation";

const UpdateUser = () => {
  const translation = {
    en: {
      EDIT_USER: "Edit User",
      NAME: "Name",
      EMAIL: "Email",
      PHONE: "Phone",
      EDIT: "Edit",
      BACK: "Back",
      SUCCESS_MESSAGE: "Operation completed successfully!",
      ERROR_MESSAGE: "This email is not available.",
    },
    ar: {
      EDIT_USER: "تعديل المستعمل",
      NAME: "الاسم",
      EMAIL: "البريد الالكتروني",
      PHONE: "الهاتف",
      EDIT: "تعديل",
      BACK: "رجوع",
      SUCCESS_MESSAGE: "تمت العملية بنجاح !",
      ERROR_MESSAGE: "هذا البريد الإلكتروني ليس متاحًا",
    },
    fr: {
      EDIT_USER: "Modifier l'utilisateur",
      NAME: "Nom",
      EMAIL: "Email",
      PHONE: "Téléphone",
      EDIT: "Modifier",
      BACK: "Retour",
      SUCCESS_MESSAGE: "Opération effectuée avec succès !",
      ERROR_MESSAGE: "Cet email n'est pas disponible.",
    },
  };

  const [user, setUser] = useState([]);
  const [getUserById] = useGetUserByIdMutation();
  const params = useParams();
  useEffect(() => {
    getUserById(params.id).then((res) => {
      setUser(res.data.data);
      setIsLoading(true);
    });
  }, []);
  const setIsUniversityNameValid = () => {
    return true;
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [numero, setNumero] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const role = localStorage.getItem("role");
  const [url, setUrl] = useState(
    process.env.REACT_APP_URL + `/api/user/updateUser/${params.id}`
  );
  useEffect(() => {
    setName(isLoading || user?.username ? user?.username : "");
    setNumero(isLoading || user?.numero ? user?.numero : "");
    setEmail(isLoading || user?.email ? user?.email : "");
    if (role === "ROLE_RESP_UNI") {
      setUrl(process.env.REACT_APP_URL + `/api/user/updateAgent/${params.id}`);
    }
  }, [isLoading]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };
  const updateUser = async () => {
    try {
      const update = await axios({
        method: "PUT",
        url: url,
        data: { username: name, email: email, numero: numero },
        headers: {
          "x-access-token": `${token}`,
        },
      });
      if (update.status == 200) {
        toastSucces(getTranslation(translation, "SUCCESS_MESSAGE"));
      }
    } catch (error) {
      toastErrorServer(getTranslation(translation, "ERROR_MESSAGE"));
    }
  };

  return (
    <div className="flex-col p-10 rounded-md lg:flex">
      <h1 className="p-4 text-3xl font-bold text-blue-900 text-bold">
        {getTranslation(translation, "EDIT_USER")}
      </h1>
      <div className="bg-white rounded">
        <div className="items-center justify-center w-full pt-8 lg:flex h-fit">
          <div className="flex items-center justify-center mx-2 lg:w-1/3">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-blue-900">
                {" "}
                {getTranslation(translation, "NAME")}{" "}
              </p>
              <Input
                type={"NAME"}
                placeholder={name}
                inputChange={setName}
                value={name}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center mx-2 lg:w-1/3">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-blue-900">
                {getTranslation(translation, "EMAIL")}
              </p>
              <Input
                type={"EMAIL"}
                placeholder={email}
                inputChange={setEmail}
                value={email}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center mx-2 lg:w-1/3">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-blue-900">
                {" "}
                {getTranslation(translation, "PHONE")}{" "}
              </p>
              <Input
                type={"NUMBER"}
                placeholder={numero}
                inputChange={setNumero}
                value={numero}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center gap-3 pt-2 pb-3 mx-2 lg:w-1/3">
            <MainButton
              title={getTranslation(translation, "EDIT")}
              disabled={name === "" || email === "" || numero === ""}
              action={() => {
                updateUser();
              }}
            />
            <MainButton
              title={getTranslation(translation, "BACK")}
              action={() => {
                back();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;
