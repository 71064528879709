import PropTypes from "prop-types";
import NavigationBarBody from "../../components/NavigationBarComponents/NavigationBarBody";
import SidebarModel from "../../components/modal/SidebarModel/SidebarModel";
import { useEffect, useState } from "react";

function NavigationBar({ show, setShow }) {
  const [open, setOpen] = useState(-1);

  const [activeBadge, setActiveBadge] = useState(true);

  useEffect(() => {
    setOpen(-1);
  }, [show]);
  return (
    <div className=" h-[100vh] bg-white sticky top-0 z-10">
      {/* Desktop Navigation Bar */}
      <div className="hidden w-full lg:flex" data-cy="desktop-component">
        <NavigationBarBody
          show={show}
          setShow={setShow}
          open={open}
          setOpen={setOpen}
          activeBadge={activeBadge}
          setActiveBadge={setActiveBadge}
        />
      </div>
      {/* Mobile Navigation Bar */}
      <div className="fixed" data-cy="mobile-component">
        {show && (
          <div>
            <div
              className="flex justify-end pb-4 cursor-pointer"
              onClick={() => setShow(!show)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <SidebarModel
              show={show}
              setShow={setShow}
              open={open}
              setOpen={setOpen}
              activeBadge={activeBadge}
              setActiveBadge={setActiveBadge}
            />
          </div>
        )}
      </div>
    </div>
  );
}

NavigationBar.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
};

export default NavigationBar;
