/* eslint-disable no-unused-vars */
import React from "react";
import ModalLayout from "../../../layouts/ModalLayout";
import PropTypes from "prop-types";
import { getTranslation } from "../../../utils/translate/translation";

const translate = {
  ar: {
    BLOCK: "منع",
    BACK: "إعادة",
    MESSAGE_0: " أنت متأكد من منع هذا وكيل !",
    MESSAGE_1: "إعادة تفعيل الحساب",
    YES: "نعم",
    NO: "لا",
  },
  en: {
    BLOCK: "Block",
    BACK: "إعادة",
    MESSAGE_0: " Are you sure for block this user!",
    MESSAGE_1: "  Reactivate account",
    YES: "Yes",
    NO: "No",
  },
  fr: {
    BLOCK: "Bloc",
    BACK: "إعادة",
    MESSAGE_0: "  Es-tu sûr pour bloquer cet utilisateur!",
    MESSAGE_1: "Réactiver le compte",
    YES: "Oui",
    NO: "Non",
  },
};
function BlockUserModal({ setOpen, open, agentId, _blockUser, status }) {
  return (
    <ModalLayout>
      <div className="relative w-auto max-w-3xl mx-auto my-6">
        {/*content*/}
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-2 bg-blue-900 border-b border-solid rounded-t border-slate-200">
            <h3 className="text-3xl font-semibold text-white">
              {status === false
                ? `${getTranslation(translate, "BLOCK")}`
                : `${getTranslation(translate, "BACK")}`}
            </h3>
          </div>
          {/*body*/}
          <div className="relative flex-auto p-4">
            <h2 className="mb-5 text-2xl text-black">
              {status === false
                ? `${getTranslation(translate, "MESSAGE_0")}`
                : `${getTranslation(translate, "MESSAGE_1")}`}
            </h2>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-2 border-t border-solid rounded-b border-slate-200">
            <button
              className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-green-800 rounded shadow outline-none active:bg-red-900 hover:shadow-lg focus:outline-none"
              onClick={() => _blockUser(agentId)}
            >
              {getTranslation(translate, "YES")}
            </button>
            <button
              onClick={() => setOpen(!open)}
              className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-800 rounded shadow outline-none active:bg-red-900 hover:shadow-lg focus:outline-none"
            >
              {getTranslation(translate, "NO")}
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
}
BlockUserModal.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  agentId: PropTypes.string,
  _deleteUser: PropTypes.func,
  value: PropTypes.string,
  _blockUser: PropTypes.func,
  status: PropTypes.bool,
};
export default BlockUserModal;
