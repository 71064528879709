const useTranslation = (translate, key) => {
  function getLanguage() {
    const language = localStorage.getItem("language") || "en";
    if (language !== "en" && language !== "ar" && language !== "fr") {
      return "en";
    } else {
      return language;
    }
  }
  const translation = translate[getLanguage()][key] || "";

  return translation;
};

export default useTranslation;
