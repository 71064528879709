/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import ErrorBoundary from "../../utils/ErrorBoundary";
import AddDiplomasUploader from "./AddDiplomasUploader";
import AddDiplomasTable from "./AddDiplomasTable";
import AddDiplomasPDFUploader from "./AddDiplomasPDFUploader";
import MainButton from "../../components/button/MainButton";
import { useDispatch, useSelector } from "react-redux";
import { useAddDiplomasMutation } from "../../store/service/diplomasService";
import {
  ADD_DIPLOMAS_FROM_CSV,
  ADD_FILES,
  ADD_PDFS,
} from "../../store/features/diplomasSlice/diplomasSlice";
import PropTypes from "prop-types";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/spinner/Spinner";
import DeleteDiplomaModal from "../../components/modal/Diploma/confirmDiplomaModal/DeleteDiplomaModal";

import { clearLocalStorage } from "../../utils/logout";
import {
  toastErrorServer,
  toastInfo,
  toastSucces,
} from "../../components/toast/Toast";
import { getTranslation } from "../../utils/translate/translation";
import { useEffect } from "react";
import AddDiplomaErrorModal from "../../components/modal/Diploma/Error/AddDiplomaErrorModal";
import ModalLayout from "../../layouts/ModalLayout";

const translate = {
  ar: {
    TOAST_SUCCESS: "تمت العملية بنجاح !",
    TOAST_INFO: "العملية ستستغرق بعض الوقت ",
    TOAST_ERROR: "فترة التسجيل الخاصة بك انتهت. أعد الدخول مجدداً ",
    TOAST_ERROR_SERVER: "هنالك خطأ من السرفر  ",
    TOAST_ERROR_MENU_1: "يرجى إضافة شهائد",
    TOAST_ERROR_MENU_2: "يرجى ألتثبت فالبيانات ، يجب رفع ملف شهادة لكل طالب",
    PAGE_TITLE: "إضافة الشهادات",
    MENU_1: "بيانات الشهادات",
    MENU_2: "ملفات الشهادات",
    MENU_3: "تأكيد الإضافة",
    MESSAGE_:
      " يُرجى التثبت في الجدول أدناه من بيانات الشهادات وتطابقها مع ملفات الPDF. علما أن الضغط على زرّ 'تأكيد الإضافة' سيقوم بتحويل بيانات الشهادات المرتبطة بملفات PDF إلى المدير من أجل الاعتماد. وستبقى باقي البيانات الغير مرتبطة بملفات PDF بطور الانتظار إلى أن يتم ربطها أو حذفها يدويا.",
    BUTTON_TITLE: "تأكيد الإضافة",
    MESSAGE_ONE: "   الرجاء الانتظار ، نحن بصدد إجراء العملية",
  },
  en: {
    TOAST_SUCCESS: "Operation accomplished successfully !",
    TOAST_INFO: "The process will take some time",
    TOAST_ERROR: "Your registration period has expired. Re-enter again",
    TOAST_ERROR_SERVER: "There is an error from the server ",
    TOAST_ERROR_MENU_1: "Please add a diploma",
    TOAST_ERROR_MENU_2:
      "Please confirm in the data, a certificate file must be uploaded for each student",
    PAGE_TITLE: "Add diplomas",
    MENU_1: "Diplomas Data",
    MENU_2: "Files Diplomas",
    MENU_3: "Add confirmation",
    MESSAGE_:
      "Please verify in the table below the data of the certificates and their compatibility with the PDF files. Note that pressing the 'Confirm Add' button will transfer the certificate data associated with the PDF files to the manager for approval. The rest of the data that is not linked to the PDF files will remain on hold until it is linked or deleted manually.",
    BUTTON_TITLE: "Add",
    MESSAGE_ONE: "Please wait, we are in the process of processing",
  },
  fr: {
    TOAST_SUCCESS: "Opération réussie !",
    TOAST_INFO: "Le processus prend un certain temps",
    TOAST_ERROR: "Votre période d'enregistrement a expiré. Entrez à nouveau",
    TOAST_ERROR_SERVER: "Il y a une erreur du serveur ",
    TOAST_ERROR_MENU_1: "Veuillez ajouter un Diplome",
    TOAST_ERROR_MENU_2:
      "Veuillez confirmer dans les données, un fichier de certificat doit être téléchargé pour chaque étudiant",
    PAGE_TITLE: "Ajouter des Diplomes",
    MENU_1: "Données de Diplomes",
    MENU_2: "Fichiers de Diplomes",
    MENU_3: " Ajouter",
    MESSAGE_:
      " Veuillez vérifier dans le tableau ci-dessous les données des certificats et leur compatibilité avec les fichiers PDF. Notez qu'appuyer sur le bouton 'Confirmer l'ajout' transférera les données de certificat associées aux fichiers PDF au gestionnaire pour approbation. Le reste des données qui ne sont pas liées aux fichiers PDF resteront en attente jusqu'à ce qu'elles soient liées ou supprimées manuellement.",
    BUTTON_TITLE: "Ajouter",
    MESSAGE_ONE: "Veuillez patienter, nous sommes en cours de traitement  ",
  },
};
function AddDiplomasPage({ selectedMenu, setSelectedMenu }) {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [missingFields, setMissingFields] = useState([]);
  const [key, setKey] = useState(0);
  const mode = useSelector((state) => state.diploma.modeSelected);
  const [alreadyExistingDiplomas, setAlreadyExistingDiplomas] = useState([]);
  const [nonExistingStudents, setNonExistingStudents] = useState([]);
  const [DiplomaToDelete, setDiplomaToDelete] = useState({});
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [openError, setOpenError] = useState(false);
  const [selectMenu, setSelectMenu] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const diploma = useSelector((state) => state.diploma);
  const file = useSelector((state) => state.diploma.files);

  let [addDiplomas] = useAddDiplomasMutation();

  /**
   * @function uploadJSON
   * @description convert table to json
   *
   *
   * @returns data
   */
  function mergeObjects(arr) {
    const grouped = arr.reduce((acc, curr) => {
      if (!acc[curr.nin]) {
        acc[curr.nin] = {
          nin: curr.nin,
          type: {},
          speciality: {},
          honors: {},
          year: curr.year,
          signatureDate: curr.signatureDate,
          approvalDate: curr.approvalDate,
          average: curr.average,
          paperPvDate: curr.paperPvDate,
          internshipPvDate: curr.internshipPvDate,
          examsPvDate: curr.examsPvDate,
          diplomaName: {},
          remark: {},
          mode: mode,
        };
      }
      acc[curr.nin].type[curr.language] = curr.type;
      acc[curr.nin].speciality[curr.language] = curr.speciality;
      acc[curr.nin].honors[curr.language] = curr.honors;
      acc[curr.nin].diplomaName[curr.language] = curr.diplomaName;
      acc[curr.nin].remark[curr.language] = curr.remark;
      return acc;
    }, {});

    return Object.values(grouped);
  }

  const uploadJSON = () => {
    let diplomasData = [...diploma.diplomasToAdd];
    diplomasData.shift();
    let data = mergeObjects(diplomasData, mode);

    return data;
  };

  /**
   * @function deleteDiploma
   * @description Delete one ligne in the Table
   *
   *
   * @returns
   */
  const deleteDiploma = () => {
    let diplomasTable = [...diploma.diplomasToAdd];
    diplomasTable.splice(key, 1);
    dispatch(ADD_DIPLOMAS_FROM_CSV({ diplomasToAdd: diplomasTable }));
    setOpenModal(!openModal);
  };

  /**
   *
   * @param {*} token
   */
  const addDiplomasApiCall = async (token) => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_URL + "/api/diploma/uploadDiplomas",
      data: diploma.pdfs,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": `${token}`,
      },
    }).then(async () => {
      await addDiplomas({ diplomasToAdd: uploadJSON(), mode: "modeOne" }).then(
        async (res) => {
          dispatch(ADD_DIPLOMAS_FROM_CSV({ diplomasToAdd: [] }));

          if (res.data) {
            toastSucces(`${getTranslation(translate, "TOAST_SUCCESS")}`);
            dispatch(ADD_FILES({ files: [] }));
            dispatch(ADD_PDFS({ pdfs: [] }));
            setIsLoading(!isLoading);
          } else if (res.error.originalStatus === 502) {
            toastInfo(`${getTranslation(translate, "TOAST_INFO")}`);
          } else {
            toastErrorServer(
              `${getTranslation(translate, "TOAST_ERROR_SERVER")}`
            );
            setIsLoading(false);
            // setOpenError(true);
            setMissingFields(res.data.error);
            dispatch(ADD_FILES({ files: [] }));
            dispatch(ADD_PDFS({ pdfs: [] }));
          }
          dispatch(ADD_FILES({ files: [] }));
          dispatch(ADD_PDFS({ pdfs: [] }));
          setIsLoading(false);
        }
      );
    });
  };

  /**
   *
   */
  const relocateToInitialPage = () => {
    clearLocalStorage();
    toastErrorServer(`${getTranslation(translate, "TOAST_ERROR")}`);
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  };

  /**
   *
   */
  const addDiplomasClick = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    if (jwtDecode(token).exp * 1000 < Date.now()) {
      relocateToInitialPage();
    } else {
      await addDiplomasApiCall(token);
    }
  };

  /**
   * @function checkMenu2
   *
   * @description This function to get access
   *              to menu 2 with check is diplomas exist or not
   *
   * @returns
   */
  const checkMenu2 = () => {
    if (diploma?.diplomasToAdd?.length <= 1) {
      toastErrorServer(`${getTranslation(translate, "TOAST_ERROR_MENU_1")}`);
    } else {
      setSelectMenu(2);
    }
  };

  /**
   * @function checkMenu3
   *
   * @description This function to get access
   *              to menu 3 with check is lenght diplomas with lenght pdfs
   *
   * @returns
   */
  const checkMenu3 = () => {
    if (file?.length <= 0) {
      toastErrorServer(`${getTranslation(translate, "TOAST_ERROR_MENU_1")}`);
    } else {
      setSelectMenu(3);
    }
  };

  useEffect(() => {
    if (selectedMenu === 1) {
      setSelectedMenu(1);
    }
  }, [selectedMenu]);
  return (
    <div
      data-testid="adddiplomapage"
      className="flex flex-col justify-end mt-10 px-[3%]  "
    >
      <h1 className="mb-2 text-2xl font-bold text-[#25396f]">
        {getTranslation(translate, "PAGE_TITLE")}
      </h1>
      <div className="flex flex-col items-center justify-center w-full p-4 bg-white rounded h-fit">
        <ErrorBoundary>
          <div className="flex w-full ">
            <div
              onClick={() => {
                setSelectMenu(1);
              }}
              className={`cursor-pointer w-48 p-2 mb-2 text-blue-800 ${
                selectMenu === 1 && `border-b-2 border-b-blue-900 bg-blue-50`
              }`}
            >
              1- {getTranslation(translate, "MENU_1")}
            </div>
            <div
              onClick={() => {
                checkMenu2();
              }}
              className={`cursor-pointer w-48 p-2 mb-2 text-blue-800 ${
                selectMenu === 2 && `border-b-2 border-b-blue-900 bg-blue-50`
              }`}
            >
              2- {getTranslation(translate, "MENU_2")}
            </div>
            <div
              onClick={() => {
                checkMenu3();
              }}
              className={`cursor-pointer w-40 p-2 mb-2 text-blue-800 ${
                selectMenu === 3 && `border-b-2 border-b-blue-900 bg-blue-50`
              }`}
            >
              3- {getTranslation(translate, "MENU_3")}
            </div>
          </div>
          {selectMenu === 1 &&
            (setOpen,
            setNonExistingStudents,
            setAlreadyExistingDiplomas,
            setIsLoadingData,
            setMissingFields,
            (
              <AddDiplomasUploader
                setMissingFields={setMissingFields}
                setNonExistingStudents={setNonExistingStudents}
                setOpen={setOpen}
                setAlreadyExistingDiplomas={setAlreadyExistingDiplomas}
                isLoadingData={isLoadingData}
                setIsLoadingData={setIsLoadingData}
              />
            ))}
          {selectMenu === 2 && <AddDiplomasPDFUploader />}
          {selectMenu === 3 && (
            <div className="flex flex-col w-full p-4 rounded bg-[#f2f7ff] h-fit">
              <p>{getTranslation(translate, "MESSAGE_")}</p>
              <div className="w-40 py-4">
                <MainButton
                  title={getTranslation(translate, "BUTTON_TITLE")}
                  action={() => {
                    addDiplomasClick();
                  }}
                  isLoading={isLoading}
                  disabled={diploma?.diplomasToAdd?.length == 0}
                />
              </div>
            </div>
          )}
        </ErrorBoundary>
        <div className="w-full">
          <ErrorBoundary>
            {isLoadingData ? (
              <>
                <div>
                  <span className="mb-4 text-2xl font-bold text-indigo-900">
                    {getTranslation(translate, "MESSAGE_ONE")}
                  </span>
                </div>
                <div className="h-[300px] flex items-center justify-center ">
                  <Spinner />
                </div>
              </>
            ) : (
              <AddDiplomasTable
                isLoadingData={isLoadingData}
                setKey={setKey}
                setOpenModal={setOpenModal}
                setDiplomaToDelete={setDiplomaToDelete}
              />
            )}
          </ErrorBoundary>
        </div>
      </div>
      {open && (
        <AddDiplomaErrorModal
          missingFields={missingFields}
          setOpen={setOpen}
          nonExistingStudents={nonExistingStudents}
          setMissingFields={setMissingFields}
          alreadyExistingDiplomas={alreadyExistingDiplomas}
          setAlreadyExistingDiplomas={setAlreadyExistingDiplomas}
          open={open}
          setNonExistingStudents={setNonExistingStudents}
        />
      )}
      {openModal && (
        <ModalLayout>
          <DeleteDiplomaModal
            setOpenModal={setOpenModal}
            openModal={openModal}
            deleteDiploma={deleteDiploma}
            DiplomaToDelete={DiplomaToDelete}
          />
        </ModalLayout>
      )}
    </div>
  );
}
AddDiplomasPage.propTypes = {
  selectedMenu: PropTypes.number,
  setSelectedMenu: PropTypes.func,
};
export default AddDiplomasPage;
