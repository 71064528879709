import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import { useForgetPasswordMutation } from "../../store/service/usersService";
import { useNavigate } from "react-router-dom";
import { mailIcon } from "../../assets/svg/Index";
import { getTranslation } from "../../utils/translate/translation";

const translate = {
  ar: {
    EMAIL: "البريد الالكتروني",
    SEND_BUTTON: "إرسال",
    BACK: "الرجوع إلى صفحة ",
    REGISTER: "  تسجيل الدخول .",
  },
  en: {
    EMAIL: "Email",
    SEND_BUTTON: "Send",
    BACK: "Back to page",
    REGISTER: "sign in .    ",
  },
  fr: {
    EMAIL: "Email",
    SEND_BUTTON: "Envoyer",
    BACK: "Retour à la page",
    REGISTER: "   Se connecter .",
  },
};
function ForgotPassword({ setForgotPwdClicked }) {
  const [forgetPassword] = useForgetPasswordMutation();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const requestPassword = () => {
    setIsLoading(true);
    const data = {
      email,
    };
    forgetPassword(data).then((res) => {
      setTimeout(() => {
        if (res.data) {
          setIsLoading(false);
          setEmail("");
          setForgotPwdClicked(false);
        }
      }, 2000);
    });
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate(-1);
    }
  }, []);
  return (
    <div className="w-full h-full ">
      <div className="my-8">
        <Input
          icon={mailIcon}
          placeholder={getTranslation(translate, "EMAIL")}
          value={email}
          inputChange={setEmail}
          type={"EMAIL"}
          setValid={setIsEmailValid}
        />
      </div>
      <div className="my-8">
        <MainButton
          title={getTranslation(translate, "SEND_BUTTON")}
          action={requestPassword}
          disabled={!isEmailValid}
          isLoading={isLoading}
        />
      </div>
      <div className="flex items-center justify-center w-full mt-20 text-2xl font-bold">
        <p> {getTranslation(translate, "BACK")}</p>
        <p
          className="mr-2 text-blue-800 cursor-pointer"
          onClick={() => {
            setForgotPwdClicked(false);
          }}
        >
          {getTranslation(translate, "REGISTER")}
        </p>
      </div>
      :
    </div>
  );
}
ForgotPassword.propTypes = {
  setForgotPwdClicked: PropTypes.func.isRequired,
};

export default ForgotPassword;
