import React from "react";
import PropTypes from "prop-types";

const Badge = ({ activeNumber }) => {
  let isNotificationDisplayed = activeNumber > 0;
  return (
    <>
      {isNotificationDisplayed && (
        <div className=" bg-[#dc3545] w-4 h-4 rounded-full flex items-center justify-center">
          <span className="text-white text-xs font-semibold top-1 left-1">
            {activeNumber}
          </span>
        </div>
      )}
    </>
  );
};

Badge.propTypes = {
  activeNumber: PropTypes.number,
};
export default Badge;
