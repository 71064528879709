/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useDispatch } from "react-redux";
import { GET_DIPLOMAS } from "../../store/features/diplomasSlice/diplomasSlice";
import { DownArrow, UpArrow } from "../../assets/svg/Index";
import { getTranslation } from "../../utils/translate/translation";
import { multilanguageReader } from "../../utils/translate/multilanguage-reader";

const translate = {
  ar: {
    NAME: "الطالب(ة)",
    NIN: "   المعرف الوطني",
    TYPE: "نوع الشهادة	",
    SPECIALITY: "الاختصاص",
    HONORS: "الملاحظة",
    SIGNATURE_DATE: "تاريخ الإمضاء",
    DIPLOMA: "الشهادة",
    OPEN: "اطّلاع",
  },
  en: {
    NAME: "Student",
    NIN: "ID",
    TYPE: " Type Diploma	",
    SPECIALITY: "Speciality",
    HONORS: "Note",
    SIGNATURE_DATE: "Signature date",
    DIPLOMA: "Diploma",
    OPEN: "Look",
  },
  fr: {
    NAME: "Étudiant",
    NIN: "Identifiant",
    TYPE: "Type de Diplome",
    SPECIALITY: "Spécialité",
    HONORS: "Note",
    SIGNATURE_DATE: "Date de la signature",
    DIPLOMA: "Diplome",
    OPEN: "Regarder",
  },
};
const Table = ({
  filtered,
  getDiploma,
  handleOpen,
  setFiltered,
  currentPage,
  perPage,
}) => {
  const dispatch = useDispatch();
  const [nameClicked, setNameClicked] = useState(false);
  const [typeClicked, setTypeClicked] = useState(false);
  const [specialityClicked, setSpecialityClicked] = useState(false);
  const [dateClicked, setDateClicked] = useState(false);
  const sortByName = (field, status) => {
    return (a, b) => {
      if (a.student[field].en > b.student[field].en) {
        return status ? -1 : 1;
      }
      if (b.student[field].en > a.student[field].en) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };
  const sortBySpeciality = (field, status) => {
    return (a, b) => {
      if (a[field].en > b[field].en) {
        return status ? -1 : 1;
      }
      if (b[field].en > a[field].en) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };
  const sortByType = (field, status) => {
    return (a, b) => {
      if (a[field].en > b[field].en) {
        return status ? -1 : 1;
      }
      if (b[field].en > a[field].en) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };
  const onNameClick = () => {
    setNameClicked(!nameClicked);
    const sorted = [...filtered].sort(sortByName("firstName", nameClicked));
    setFiltered(sorted);
    dispatch(GET_DIPLOMAS({ diplomas: sorted }));
  };

  const onSpecialityClick = () => {
    setSpecialityClicked(!specialityClicked);
    const sorted = [...filtered].sort(
      sortBySpeciality("speciality", specialityClicked)
    );
    setFiltered(sorted);
    dispatch(GET_DIPLOMAS({ diplomas: sorted }));
  };

  const onDateClick = () => {
    setDateClicked(!dateClicked);
    if (dateClicked) {
      const sorted = [...filtered].sort((a, b) => {
        return moment(b.signatureDate).isBefore(moment(a.signatureDate))
          ? 1
          : -1;
      });
      setFiltered(sorted);
      dispatch(GET_DIPLOMAS({ diplomas: sorted }));
    } else {
      const sorted = [...filtered].sort((a, b) => {
        return moment(a.signatureDate).isBefore(moment(b.signatureDate))
          ? 1
          : -1;
      });
      setFiltered(sorted);
      dispatch(GET_DIPLOMAS({ diplomas: sorted }));
    }
  };

  const onTypeClick = () => {
    setTypeClicked(!typeClicked);
    const sorted = [...filtered].sort(sortByType("type", typeClicked));
    setFiltered(sorted);
    dispatch(GET_DIPLOMAS({ diplomas: sorted }));
  };
  const language = localStorage.getItem("language");
  return (
    <table
      className={
        language === "ar"
          ? "w-full text-sm text-right text-gray-500 dark:text-gray-400 "
          : "w-full text-sm text-left text-gray-500 dark:text-gray-400 "
      }
    >
      <thead className="text-xs text-gray-900 uppercase dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="px-2 py-3 cursor-pointer">
            <div
              className="flex items-center justify-start"
              onClick={onNameClick}
            >
              <span> {getTranslation(translate, "NAME")} </span>
              {nameClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-2 py-3">
            {getTranslation(translate, "NIN")}
          </th>
          <th scope="col" className="px-2 py-3 cursor-pointer">
            <div
              className="flex items-center justify-start"
              onClick={onTypeClick}
            >
              <span> {getTranslation(translate, "TYPE")}</span>
              {typeClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-1 py-3 cursor-pointer">
            <div
              className="flex items-center justify-start"
              onClick={onSpecialityClick}
            >
              <span> {getTranslation(translate, "SPECIALITY")}</span>
              {specialityClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-2 py-3">
            {getTranslation(translate, "HONORS")}
          </th>
          <th scope="col" className="px-2 py-3 cursor-pointer">
            <div
              className="flex items-center justify-start"
              onClick={onDateClick}
            >
              <span> {getTranslation(translate, "SIGNATURE_DATE")}</span>
              {dateClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-2 py-3"></th>
        </tr>
      </thead>
      <tbody className="overflow-x-hidden h-10vh">
        {filtered
          ?.slice(currentPage * perPage - perPage, currentPage * perPage)
          .map((item, key) => (
            <tr
              key={key}
              className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                (key + 1) % 2 !== 0 ? `bg-[#f2f2f2]` : `bg-white`
              }`}
            >
              <td className="px-2 py-2">
                {multilanguageReader(item?.student?.firstName)}{" "}
                {multilanguageReader(item?.student?.lastName)}
              </td>
              <td className="px-2 py-2">{item?.student?.nin}</td>
              <td className="px-2 py-2">{multilanguageReader(item?.type)}</td>
              <td className="px-2 py-2">
                {multilanguageReader(item?.speciality)}
              </td>
              <td className="px-2 py-2">{multilanguageReader(item?.honors)}</td>
              <td className="px-2 py-2">
                {item?.signatureDate === "Invalid date"
                  ? null
                  : item?.signatureDate}
              </td>
              <td className="flex items-center justify-center px-2 py-2">
                {item?.hashipfs ? (
                  <button
                    className="px-2 py-1 mx-1 text-xs font-bold text-white bg-[#0dcaf0] rounded w-fi h-fit"
                    onClick={() => {
                      getDiploma({ item });
                      handleOpen();
                    }}
                  >
                    الشهادة
                  </button>
                ) : (
                  <></>
                )}

                <button
                  className="px-2 py-1 mx-1 flex items-center gap-1 text-xs font-bold text-white bg-[#198754] rounded w-fi h-fit"
                  onClick={() => {
                    getDiploma({ item });
                    handleOpen();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59"
                    />
                  </svg>
                  {getTranslation(translate, "OPEN")}
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
Table.propTypes = {
  filtered: PropTypes.array.isRequired,
  getDiploma: PropTypes.func,
  handleOpen: PropTypes.func,
  handleOpenModal: PropTypes.func,
  role: PropTypes.string,
};
export default Table;
