import React from "react";
import { getTranslation } from "../../../../utils/translate/translation";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useRevokeDiplomaMutation } from "../../../../store/service/diplomasService";
import { GET_DIPLOMAS } from "../../../../store/features/diplomasSlice/diplomasSlice";
const translate = {
  ar: {
    REVOKE: "إبطال",
    CONFIRM_DELETE: " أنت تؤكد إبطال شهادة ",
    YES: "نعم",
    NO: "لا",
  },
  en: {
    REVOKE: "Revoke",
    CONFIRM_DELETE: "You confirm deletion of a Diploma",
    YES: "Yes",
    NO: "No",
  },
  fr: {
    REVOKE: "Révoquer",
    CONFIRM_DELETE: "Vous confirmez la suppression d'un Diplome",
    YES: "Oui",
    NO: "Non",
  },
};

const RevokeDiploma = ({
  setOpen,
  open,
  diplomaRevoke,
  filtered,
  setFiltered,
}) => {
  const [revokeDiploma] = useRevokeDiplomaMutation();
  const dispatch = useDispatch();
  const _revokeDiploma = () => {
    const studentAddress = diplomaRevoke.student.blockchainAddress;
    const diplomaId = diplomaRevoke._id;

    let data = {
      studentAddress,
      diplomaId,
    };
    revokeDiploma(data).then(() => {
      const diplomaAcceptFilter = filtered.filter(
        (diploma) => diploma._id !== diplomaRevoke._id
      );
      setFiltered(diplomaAcceptFilter);
      dispatch(GET_DIPLOMAS({ diplomas: diplomaAcceptFilter }));
    });
    setOpen(!open);
  };
  return (
    <div className="relative w-auto max-w-3xl mx-auto my-6">
      {/*content*/}
      <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
        {/*header*/}
        <div className="flex items-start justify-between p-3 bg-[#dc3545] border-b border-solid rounded-t border-slate-200">
          <h3 className="text-3xl flex items-center justify-between w-full gap-1 font-semibold text-white">
            {getTranslation(translate, "REVOKE")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-7 h-7"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
              />
            </svg>
          </h3>
        </div>
        {/*body*/}
        <div className="relative flex-auto p-4">
          <h2 className="mb-5 text-2xl text-black">
            {getTranslation(translate, "CONFIRM_DELETE")}
          </h2>
        </div>
        {/*footer*/}
        <div className="flex items-center justify-end p-2 gap-2 border-t border-solid rounded-b border-slate-200">
          <button
            className="px-6 py-2 mb-1 mr-1 flex w-full justify-between items-center text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-[#198754] rounded shadow outline-none active:bg-red-900 hover:shadow-lg focus:outline-none"
            onClick={() => {
              _revokeDiploma(diplomaRevoke);
            }}
          >
            {getTranslation(translate, "YES")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </button>
          <button
            onClick={() => setOpen(!open)}
            className="px-6 py-2 mb-1 mr-1 text-sm font-bold flex w-full justify-between items-center text-white uppercase transition-all duration-150 ease-linear bg-[#dc3545] rounded shadow outline-none active:bg-red-900 hover:shadow-lg focus:outline-none"
          >
            {getTranslation(translate, "NO")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
RevokeDiploma.propTypes = {
  setOpen: PropTypes.func,
  diplomaRevoke: PropTypes.object,
  open: PropTypes.bool,
  filtered: PropTypes.object,
  setFiltered: PropTypes.func,
};
export default RevokeDiploma;
