import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Badge from "../badge/Badge";

function NavigationBarButton({
  title,
  action,
  path,
  icon,
  language,
  isBadgeActive,
}) {
  const nbDiploma = useSelector((state) => state.diploma.countDiploma);
  const buttonContainerStyles = `relative cursor-pointer w-77 h-12 flex rounded font-medium ${
    location.pathname === path
      ? "bg-[#435ebe] text-white"
      : "hover:bg-[#f0f1f5] text-[#25396f]"
  }`;
  const buttonStyles = `flex items-center gap-1 ${
    language === "ar" ? "pr-1" : "pl-1"
  }`;
  const titleStyles = "flex w-full items-center gap-4 p-2";
  return (
    <div onClick={() => action(path)} className={buttonContainerStyles}>
      <div className={buttonStyles}>
        {icon}
        <h1 className={titleStyles}>
          <span>{title}</span>
          {isBadgeActive && <Badge activeNumber={nbDiploma} />}
        </h1>
      </div>
    </div>
  );
}

NavigationBarButton.propTypes = {
  title: PropTypes.string,
  action: PropTypes.func,
  path: PropTypes.string,
  icon: PropTypes.element,
  language: PropTypes.string,
  isBadgeActive: PropTypes.bool,
};

export default NavigationBarButton;
