import React from "react";
import FooterModal from "../LayoutsModal/FooterModal";
import { translate } from "../Translate/Translate";
import { Fragment } from "react";
import ModalLayout from "../../../../layouts/ModalLayout";
import HeaderModal from "../LayoutsModal/HeaderModal";
import PropTypes from "prop-types";
import DigitalDiplomaPreview from "../../../../pages/DigitalDiplomaPage/DigitalDiplomaPreview";

const PendingDiplomaModalModeThree = ({
  item,
  setOpen,
  role,
  updateDiplomaRejectOrAccept,
}) => {
  return (
    <Fragment>
      <ModalLayout>
        <div className="relative w-auto w-max-[auto]  mx-auto my-6   overflow-hidden">
          <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
            <HeaderModal translate={translate} item={item} />
            <div className="w-full h-full p-2">
              <DigitalDiplomaPreview diploma={item} />
            </div>
            <FooterModal
              translate={translate}
              role={role}
              item={item}
              open={open}
              setOpen={setOpen}
              updateDiplomaRejectOrAccept={updateDiplomaRejectOrAccept}
            />
          </div>
        </div>
      </ModalLayout>
    </Fragment>
  );
};
PendingDiplomaModalModeThree.propTypes = {
  item: PropTypes.object,
  role: PropTypes.string,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  filtered: PropTypes.object,
  setFiltered: PropTypes.func,
  updateDiplomaRejectOrAccept: PropTypes.func,
};
export default PendingDiplomaModalModeThree;
