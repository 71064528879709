import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LanguageChooser from "../../components/language-chooser/LanguageChooser";
import { useGetStudentbyIdMutation } from "../../store/service/studentsService";
import { keyReturn } from "../../utils/translate/multilanguage-reader";

import Diploma from "./Diploma";
import StudentInfo from "./StudentInfo";

const StudentFich = () => {
  const params = useParams();

  const [selectedLanguage, setSelectedLanguage] = useState("");

  const [getStudentById] = useGetStudentbyIdMutation();
  const [student, setStudent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getStudentById(params.id).then((res) => {
      setStudent(res?.data?.data);
      setSelectedLanguage(keyReturn(res?.data?.data?.firstName));
      setIsLoading(true);
    });
  }, [isLoading]);

  return (
    <div className="lg:flex lg:justify-start md:flex sm:grid  justify-center pt-[40px] pr-[15px] lg:gap-5 h-[100vh] w-full">
      <div className="lg:p-5 sm:p-1">
        <StudentInfo student={student} selectedLanguage={selectedLanguage} />
        <div className="p-2">
          {student?.firstName && (
            <LanguageChooser
              setSelectedLanguage={setSelectedLanguage}
              selectedLanguage={selectedLanguage}
              firstnameObject={student?.firstName}
              diplomaType={student?.diplomas[0]?.type}
            />
          )}
        </div>
      </div>
      <div className="lg:p-5 sm:p-1 lg:pt-36 w-full">
        {student.diplomas?.length > 0 && (
          <div className="flex flex-wrap items-start justify-between gap-3  mt-4 overflow-hidden rounded-xl ">
            {student.diplomas?.map((diploma) => {
              return <Diploma diploma={diploma} key={diploma._id} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentFich;
