import moment from "moment";

const isSignatureDateBeforeApprovalDate = (approval, signature) => {
  return (
    moment(signature, "DD-MM-YYYY").diff(
      moment(approval, "DD-MM-YYYY"),
      "days"
    ) < 0
  );
};

export { isSignatureDateBeforeApprovalDate };
