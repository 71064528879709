import React from "react";
import { getTranslation } from "../../../../utils/translate/translation";
import PropTypes from "prop-types";
import { Fragment } from "react";
const FooterModal = ({
  translate,
  role,
  setOpen,
  open,
  item,
  handleOpenModalRevoke,

  setShowUpdate,
  showUpdate,
  updateDiplomaRejectOrAccept,
  handleOpenDeleteModal,
}) => {
  const handleCloseModalUpdate = () => {
    setOpen(!open);
    setShowUpdate(false);
  };
  return (
    <Fragment>
      {showUpdate ? (
        <></>
      ) : (
        <div className="flex items-center justify-end p-2  gap-2 border-t border-solid rounded-b border-slate-200">
          <button
            onClick={() => handleCloseModalUpdate()}
            className="bg-[#e6eaee] text-[#1a2026] flex gap-1 items-center hover:bg-[#e1e3e5]  leading-normal font-medium uppercase text-base p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            {getTranslation(translate, "CLOSE")}
          </button>
          {role === "ROLE_RESP_UNI" && item.status === "accepted" && (
            <button
              className="bg-[#dc3545] text-[white]  flex gap-1 items-center leading-normal font-normal uppercase text-base p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
              type="button"
              onClick={() => {
                handleOpenModalRevoke(item);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                />
              </svg>

              {getTranslation(translate, "REVOKE")}
            </button>
          )}
          {role === "ROLE_RESP_UNI" && item.status === "pending" && (
            <button
              className="bg-[#dc3545] text-[white]  leading-normal font-normal uppercase text-base p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
              type="button"
              onClick={() => {
                updateDiplomaRejectOrAccept({ item });
              }}
            >
              {getTranslation(translate, "REJECTED")}
            </button>
          )}
          {role === "ROLE_AGENT_UNI" &&
            item.status === "rejected" &&
            !showUpdate && (
              <button
                className="bg-[#dc3545] text-[white] flex gap-1 items-center leading-normal font-medium uppercase text-base p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  handleOpenDeleteModal({ item });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>

                {getTranslation(translate, "DELETE")}
              </button>
            )}
          {role === "ROLE_AGENT_UNI" &&
            item.status === "rejected" &&
            !showUpdate && (
              <button
                className="bg-[#198754] text-[white] flex gap-1 items-center leading-normal font-medium uppercase text-base p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  setShowUpdate(!showUpdate);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                  />
                </svg>

                {getTranslation(translate, "UPDATE")}
              </button>
            )}
          {role === "ROLE_AGENT_UNI" &&
            item.status === "rejected" &&
            showUpdate && (
              <button
                className="bg-[#198754] text-[white]  leading-normal font-medium uppercase text-base p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
                type="button"
              >
                {getTranslation(translate, "UPDATE")}
              </button>
            )}
        </div>
      )}
    </Fragment>
  );
};
FooterModal.propTypes = {
  role: PropTypes.string,
  translate: PropTypes.object,
  item: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleOpenModalRevoke: PropTypes.func,
  _deleteDiploma: PropTypes.func,
  setShowUpdate: PropTypes.func,
  showUpdate: PropTypes.bool,
  updateDiplomaRejectOrAccept: PropTypes.func,
  handleOpenDeleteModal: PropTypes.func,
};
export default FooterModal;
