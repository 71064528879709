/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import ModalLayout from "../../../layouts/ModalLayout";
import { getTranslation } from "../../../utils/translate/translation";

const translate = {
  ar: {
    DELETE: "حذف",
    MESSAGE_0: "  أنت تؤكد حذف الطالب ",
    YES: "نعم",
    NO: "لا",
  },
  en: {
    DELETE: "Delete",
    MESSAGE_0: " You confirm the student's deletion",
    YES: "Yes",
    NO: "No",
  },
  fr: {
    DELETE: "Supprimer",
    MESSAGE_0: " Vous confirmez la suppression de l'élève",
    YES: "Oui",
    NO: "Non",
  },
};
const DeleteStdentModal = ({
  setOpenModal,
  openModal,
  deleteStudent,
  studentToDelete,
}) => {
  return (
    <ModalLayout>
      <div className="relative w-auto max-w-3xl mx-auto my-6 ">
        {/*content*/}
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-3 bg-[#dc3545] border-b border-solid rounded-t border-slate-200">
            <h3 className="text-3xl flex items-center w-full justify-between font-semibold text-white">
              {getTranslation(translate, "DELETE")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-7 h-7"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </h3>
          </div>
          {/*body*/}
          <div className="relative flex-auto p-4">
            <h2 className="mb-5 text-2xl text-black">
              {getTranslation(translate, "MESSAGE_0")}
              {studentToDelete.firstName} {studentToDelete.lastName}
            </h2>
            <p></p>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end p-2 border-t border-solid rounded-b border-slate-200">
            <button
              className="px-6 py-2 mb-1 mr-1 text-sm font-bold flex  justify-between w-full items-center text-white uppercase transition-all duration-150 ease-linear bg-green-800 rounded shadow outline-none active:bg-red-900 hover:shadow-lg focus:outline-none"
              onClick={() => {
                deleteStudent();
              }}
            >
              {getTranslation(translate, "YES")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </button>
            <button
              onClick={() => setOpenModal(!openModal)}
              className="px-6 py-2 mb-1 mr-1 text-sm flex  justify-between w-full items-center font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-800 rounded shadow outline-none active:bg-red-900 hover:shadow-lg focus:outline-none"
            >
              {getTranslation(translate, "NO")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
DeleteStdentModal.propTypes = {
  setOpenModal: PropTypes.func,
  openModal: PropTypes.bool,
  deleteStudent: PropTypes.func,
  studentToDelete: PropTypes.object,
};
export default DeleteStdentModal;
