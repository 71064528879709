/* eslint-disable no-unused-vars */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import { toastErrorServer, toastSucces } from "../../components/toast/Toast";
import { useAddStudentsMutation } from "../../store/service/studentsService";

import { excelSerialNumberToDate } from "../../utils/dateUtils/convertDateTimeStambyFormatDate";
import readDateFromLeftToRight from "../../utils/dateUtils/readDateFromLeftToRight";
import { convertDateFromXlsFileToString } from "../../utils/SheetUtils/SheetReader";

import { convertNumbers2English } from "../../utils/converts/convert";
import { getTranslation } from "../../utils/translate/translation";
import LanguageInput from "./LanguageInput";

const translate = {
  ar: {
    TOAST_ERROR:
      "المعطيات ناقصة ، الرجاء إدخال على الأقل الإسم وإسم العائلة والمعرف",
    TOAST_NIN_ERROR: "هذا المعرف موجود ",
    TOAST_SUCCESS: "تمت العملية بنجاح !",
    PAGE_TITLE: "إضافة طالب",
    FIRSTNAME: "الاسم الأول ",
    LASTNAME: "اسم العائلة ",
    NIN: "المعرف الوطني ",
    DATE_OF_BRITH: "تاريخ الولادة",
    BRITH_PLACE: "مكان الولادة ",
    GENDER: "الجنس",
    NATIONALITY: "الجنسية",
    EMAIL: "البريد الالكتروني ",
    PHONE: "الهاتف",
    BUTTON_TITLE: "إضافة",
    BACK: "  الرجوع",
  },
  en: {
    TOAST_ERROR:
      "The data is missing, please enter at least the name, last name and ID",
    TOAST_NIN_ERROR: "  This identifier exists ",
    TOAST_SUCCESS: "Operation accomplished successfully !",
    PAGE_TITLE: "Add a student",
    FIRSTNAME: "First Name ",
    LASTNAME: "Last Name",
    NIN: " Identifier ",
    DATE_OF_BRITH: " Birth date",
    BRITH_PLACE: " Place of birth ",
    GENDER: "Sex",
    NATIONALITY: "Nationality",
    EMAIL: " Email ",
    PHONE: "Phone",
    BUTTON_TITLE: "Add",
    BACK: "Back ",
  },
  fr: {
    TOAST_ERROR:
      "Les données sont manquantes, veuillez saisir au moins le nom, le prénom et l'identifiant",
    TOAST_NIN_ERROR: "Cet identifiant existe ",
    TOAST_SUCCESS: "Opération réussie !",
    PAGE_TITLE: "Ajouter un étudiant",
    FIRSTNAME: " Prénom ",
    LASTNAME: " Nom ",
    NIN: " L'identifiant national ",
    DATE_OF_BRITH: " Date de naissance",
    BRITH_PLACE: " Lieu de naissance ",
    GENDER: "Sexe",
    NATIONALITY: "Nationalité",
    EMAIL: "Email",
    PHONE: "Téléphone",
    BUTTON_TITLE: "Ajouter",
    BACK: "Retour ",
  },
};
function AddOneStudent() {
  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");
  const [NIN, setNIN] = useState("");
  const [birthdate, setbirthdate] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [nationalty, setNationality] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [numTel, setNumTel] = useState("");
  const [birthdateValid, setbirthdateValid] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  let [addStudents] = useAddStudentsMutation();

  let navigate = useNavigate();

  /**
   * @function setIsUniversityNameValid
   *
   * @return true
   */
  const setIsUniversityNameValid = () => {
    return true;
  };

  /**
   * @function writeDate
   * @description this function to update diploma
   *
   * @param(date)
   *
   * @return
   */
  function writeDate(date) {
    const dateObj = moment(
      convertNumbers2English(date.toString()),
      "DD-MM-YYYY"
    );
    const formattedDate = dateObj.format("DD-MM-YYYY");
    return formattedDate;
  }

  /**
   * @function updateStudent
   * @description this function to update diploma
   *
   * @return
   */
  const updateStudent = () => {
    if (NIN === "" || name === "" || surName === "") {
      toastErrorServer(`${getTranslation(translate, "TOAST_ERROR")}`);
    } else {
      addStudents({
        students: [
          {
            firstName: { [selectedLanguage]: name },
            lastName: { [selectedLanguage]: surName },
            nin: NIN,
            dateOfBirth: convertDateFromXlsFileToString(birthdate),
            countryOfBirth: { [selectedLanguage]: birthPlace },
            sex: { [selectedLanguage]: gender },
            nationalty: { [selectedLanguage]: nationalty },
            phone: numTel,
            email: email,
          },
        ],
      }).then((res) => {
        if (res.data.notAddedStudents.length !== 0) {
          toastErrorServer(`${getTranslation(translate, "TOAST_NIN_ERROR")}`);
        } else {
          toastSucces(`${getTranslation(translate, "TOAST_SUCCESS")}`);
          setTimeout(() => {
            navigate("/student");
          }, 3000);
        }
      });
    }
  };

  useEffect(() => {}, [birthdateValid]);

  return (
    <div className="flex flex-col p-[3%]">
      <h1 className="p-2 text-3xl font-bold text-indigo-900">
        {getTranslation(translate, "PAGE_TITLE")}
      </h1>
      <div className="bg-white rounded">
        <div className="lg:flex items-center justify-center w-full pt-8 h-fit">
          <div className="flex items-center justify-center lg:w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900">
                {getTranslation(translate, "FIRSTNAME")}{" "}
              </p>
              <Input
                type={"NAME"}
                placeholder={getTranslation(translate, "FIRSTNAME")}
                inputChange={setName}
                value={name}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center lg:w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900">
                {getTranslation(translate, "LASTNAME")}{" "}
              </p>
              <Input
                type={"NAME"}
                placeholder={getTranslation(translate, "LASTNAME")}
                inputChange={setSurName}
                value={surName}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center lg:w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900">
                {getTranslation(translate, "NIN")}
              </p>
              <Input
                type={"NIN"}
                placeholder={getTranslation(translate, "NIN")}
                inputChange={setNIN}
                value={NIN}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
        </div>
        <div className="lg:flex items-center justify-center w-full p-2 h-fit">
          <div className="flex items-center justify-center lg:w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900">
                {getTranslation(translate, "DATE_OF_BRITH")}
              </p>
              <Input
                type={"DATE_FORMAT"}
                placeholder={`${getTranslation(
                  translate,
                  "DATE_OF_BRITH"
                )} (dd-mm-yyyy)`}
                inputChange={setbirthdate}
                value={birthdate}
                setValid={setbirthdateValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center lg:w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900">
                {getTranslation(translate, "BRITH_PLACE")}
              </p>
              <Input
                type={"NAME"}
                placeholder={getTranslation(translate, "BRITH_PLACE")}
                inputChange={setBirthPlace}
                value={birthPlace}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center lg:w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900">
                {getTranslation(translate, "GENDER")}
              </p>
              <Input
                type={"NAME"}
                placeholder={getTranslation(translate, "GENDER")}
                inputChange={setGender}
                value={gender}
                setValid={setIsUniversityNameValid}
                isSelect
              />
            </div>
          </div>
        </div>
        <div className="lg:flex items-center justify-center w-full p-2 h-fit">
          <div className="flex items-center justify-center lg:w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900">
                {getTranslation(translate, "NATIONALITY")}{" "}
              </p>
              <Input
                type={"NAME"}
                placeholder={getTranslation(translate, "NATIONALITY")}
                inputChange={setNationality}
                value={nationalty}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center lg:w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900">
                {getTranslation(translate, "EMAIL")}{" "}
              </p>
              <Input
                type={"EMAIL"}
                placeholder={getTranslation(translate, "EMAIL")}
                inputChange={setEmail}
                value={email}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="flex items-center justify-center lg:w-1/3 mx-2">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-xl text-indigo-900">
                {getTranslation(translate, "PHONE")}{" "}
              </p>
              <Input
                type={"NUMBER"}
                placeholder={getTranslation(translate, "PHONE")}
                inputChange={setNumTel}
                value={numTel}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
        </div>
        <div className="relative flex justify-end w-full">
          <div className="flex flex-col w-full">
            <div className="flex w-full">
              <div className="flex items-center justify-between w-full p-2">
                <div
                  className="  rounded-3xl  cursor-pointer"
                  onClick={() => setShowMenu(!showMenu)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8 text-[#435ebe]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                    />
                  </svg>
                </div>
              </div>
              <div className="w-40 p-2">
                <MainButton
                  title={getTranslation(translate, "BUTTON_TITLE")}
                  disabled={
                    name === "" ||
                    surName === "" ||
                    NIN === "" ||
                    (!birthdateValid && birthdate === "")
                  }
                  action={() => {
                    updateStudent();
                  }}
                />
              </div>
              <div className=" w-40 p-2">
                <button
                  type="submit"
                  onClick={() => {
                    navigate("/add_student");
                  }}
                  className={
                    " bg-[#e6eaee] text-[#1a2026]  leading-normal font-normal uppercase text-base w-full h-10   shadow-lg rounded-md hover:bg-[#e7e7e7]"
                  }
                >
                  {getTranslation(translate, "BACK")}
                </button>
              </div>
            </div>
          </div>
          {showMenu && (
            <LanguageInput
              setSelectedLanguage={setSelectedLanguage}
              setShowMenu={setShowMenu}
              selectedLanguage={selectedLanguage}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AddOneStudent;
