import React from "react";
import PropTypes from "prop-types";
import { getTranslation } from "../../utils/translate/translation";

const StudentInfo = ({ student, selectedLanguage }) => {
  const translate = {
    ar: {
      NATIONAL_ID: "المعرف الوطني :",
      DATE_OF_BIRTH: "تاريخ الميلاد :",
      PLACE_OF_BIRTH: "مكان الولادة :",
      EMAIL: "البريد الإلكتروني:",
      PHONE: "الهاتف :",
    },
    fr: {
      NATIONAL_ID: "Identifiant national :",
      DATE_OF_BIRTH: "Date de naissance :",
      PLACE_OF_BIRTH: "Lieu de naissance :",
      EMAIL: "Email :",
      PHONE: "Téléphone :",
    },
    en: {
      NATIONAL_ID: "National ID:",
      DATE_OF_BIRTH: "Date of Birth:",
      PLACE_OF_BIRTH: "Place of Birth:",
      EMAIL: "Email:",
      PHONE: "Phone:",
    },
  };

  return (
    <div className="bg-white h-[auto] lg:w-[300px] sm:max-w-[500px] rounded">
      {student.photo ? (
        <img src={student.photo} className="w-full h-80 rounded-t-xl" />
      ) : (
        <div className="flex items-center justify-center w-full max-w-sm p-6 mx-auto rounded-md cursor-pointer animate-pulse h-80">
          <div className="w-40 h-40 bg-gray-700 rounded-full"></div>
        </div>
      )}
      <div className="p-[1.5rem] flex flex-col gap-2">
        <span className=" text-[1.2rem] font-[700] text-[#25396f]">
          {student?.firstName?.[selectedLanguage].toUpperCase()}
          {"  "}
          {student?.lastName?.[selectedLanguage].toUpperCase()}
        </span>
        <p className="font-[400] text-[1rem] text-[#607080]">
          {student.description}
        </p>
      </div>
      <div className="flex flex-col gap-3  pr-[3rem]">
        <div className="flex items-center w-full  border-b border-b-[#dfdfdf]">
          <div className="flex items-start w-full gap-1 pb-2 pl-1 text-sm border-b-gray-400">
            <span className=" text-[#607080] font-bold">
              {" "}
              {getTranslation(translate, "NATIONAL_ID")}{" "}
            </span>
            <span className="  text-[#607080]"> {student.nin}</span>
          </div>
        </div>
        <div className="flex w-full border-b border-b-[#dfdfdf]">
          <div className="flex items-start w-full gap-1 pb-2 pl-1 text-sm border-b-gray-400">
            <span className=" text-[#607080] font-bold">
              {" "}
              {getTranslation(translate, "DATE_OF_BIRTH")}
            </span>
            <span className="  text-[#607080]"> {student.dateOfBirth}</span>
          </div>
        </div>
        <div className="flex w-full border-b border-b-[#dfdfdf]">
          <div className="flex items-start w-full gap-1 pb-2 pl-1 text-sm border-b-gray-400">
            <span className=" text-[#607080] font-bold">
              {" "}
              {getTranslation(translate, "PLACE_OF_BIRTH")}{" "}
            </span>
            <span className="  text-[#607080]">
              {student.countryOfBirth?.[selectedLanguage]}
            </span>
          </div>
        </div>
        <div className="flex w-full items-center border-b border-b-[#dfdfdf]">
          <div className="flex items-center w-full gap-1 pb-2 pl-1 text-sm border-b-gray-400">
            <span className=" text-[#607080] text-xs font-bold">
              {getTranslation(translate, "EMAIL")}
            </span>
            <span className="  text-[#607080] text-xs"> {student.email}</span>
          </div>
        </div>
        <div className="flex w-full border-b border-b-[#dfdfdf]">
          <div className="flex items-start w-full gap-1 pb-2 pl-1 text-sm border-b-gray-400">
            <span className=" text-[#607080] font-bold">
              {" "}
              {getTranslation(translate, "PHONE")}{" "}
            </span>
            <span className="  text-[#607080]"> {student.phone}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
StudentInfo.propTypes = {
  student: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectedLanguage: PropTypes.string,
};
export default StudentInfo;
