import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GET_USERS } from "../../store/features/studentsSlice/studentsSlice";
import {
  DownArrow,
  UpArrow,
  AgentIcon,
  EditIcon,
  RespIcon,
  CheckIcon,
  BlockIcon,
} from "../../assets/svg/Index";

import { convertStringToNumber } from "../../utils/converts/convertStringToNumber";
import { getTranslation } from "../../utils/translate/translation";

const Table = ({
  agents,
  resendFunction,
  openModel,
  currentPage,
  perPage,
  setAgent,
}) => {
  const translation = {
    ar: {
      NAME: "الاسم",
      EMAIL: "البريد الالكتروني",
      PHONE: "الهاتف",
      UNIVERSITY_NAME: "اسم الجامعة",
      ROLE: "الوظيفة",
      COUNTRY: "البلد",
      SPECIALITY: "إدارة",
      ACTIVE: "نشيط",
      CHOOSE: "اختر...",
      RESEND_LINK: "إعادة إرسال رابط التسجيل",
      BLOCK_USER: "منع المستخدم من الدخول",
      ACTIVATE_ACCOUNT: "إعادة تفعيل الحساب",
      DELETE_USER: "حذف المستخدم",
      UNIVERSITY_DIRECTOR: "مدير الجامعة",
      EMPLOYEE: "موظف",
    },
    en: {
      NAME: "Name",
      EMAIL: "Email",
      PHONE: "Phone",
      UNIVERSITY_NAME: "University Name",
      ROLE: "Role",
      COUNTRY: "Country",
      SPECIALITY: "Specialty",
      ACTIVE: "Active",
      CHOOSE: "Choose...",
      RESEND_LINK: "Resend Registration Link",
      BLOCK_USER: "Block User",
      ACTIVATE_ACCOUNT: "Activate Account",
      DELETE_USER: "Delete User",
      UNIVERSITY_DIRECTOR: "University Director",
      EMPLOYEE: "Employee",
    },

    fr: {
      NAME: "Nom",
      EMAIL: "Email",
      PHONE: "Téléphone",
      UNIVERSITY_NAME: "Nom de l'université",
      ROLE: "Rôle",
      COUNTRY: "Pays",
      SPECIALITY: "Spécialité",
      ACTIVE: "Actif",
      CHOOSE: "Choisir...",
      RESEND_LINK: "Renvoyer le lien d'inscription",
      BLOCK_USER: "Bloquer l'utilisateur",
      ACTIVATE_ACCOUNT: "Activer le compte",
      DELETE_USER: "Supprimer l'utilisateur",
      UNIVERSITY_DIRECTOR: "Directeur de l'université",
      EMPLOYEE: "Employé",
    },
  };

  const [nomClicked, setNomClicked] = useState(false);
  const [specialtyClicked, setSpecialtyClicked] = useState(false);
  const [universityNameClicked, setUniversityNameClicked] = useState(false);
  const [roleClicked, setRoleClicked] = useState(false);
  const [countryClicked, setCountryClicked] = useState(false);
  const dispatch = useDispatch();
  const sortBy = (field, status) => {
    return (a, b) => {
      if (a[field] > b[field]) {
        return status ? -1 : 1;
      }
      if (b[field] > a[field]) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };

  const onRoleClick = () => {
    setRoleClicked(!roleClicked);
    const sorted = [...agents].sort(sortBy("role", roleClicked));
    setAgent(sorted);
    dispatch(GET_USERS({ agent: sorted }));
  };

  const onUniversityNameClick = () => {
    setUniversityNameClicked(!universityNameClicked);
    const sorted = [...agents].sort(
      sortBy("universityName", universityNameClicked)
    );
    setAgent(sorted);
    dispatch(GET_USERS({ agent: sorted }));
  };

  const onSpecialtyClick = () => {
    setSpecialtyClicked(!specialtyClicked);
    const sorted = [...agents].sort(sortBy("speciality", specialtyClicked));
    setAgent(sorted);
    dispatch(GET_USERS({ agent: sorted }));
  };
  const onNomCLick = () => {
    setNomClicked(!nomClicked);
    const sorted = [...agents].sort(sortBy("username", nomClicked));
    setAgent(sorted);
    dispatch(GET_USERS({ agent: sorted }));
  };

  const onCountryClick = () => {
    setCountryClicked(!countryClicked);
    const sorted = [...agents].sort(sortBy("country", countryClicked));
    setAgent(sorted);
    dispatch(GET_USERS({ agent: sorted }));
  };

  const handleChange = (e, _value, email, id, value, status) => {
    switch (_value) {
      case "resend":
        resendFunction(email, _value, value);
        break;
      case "block":
        openModel(id, _value, value, status, e);
        break;
      case "delete":
        openModel(id, _value, value, e);
        break;
    }
    handleReset(e);
  };

  const handleReset = (e) => {
    e.target.value = "choix";
  };
  const navigate = useNavigate();
  const language = localStorage.getItem("language");
  const countries = {
    ar: {
      PALESTINE: "دولة فلسطين",
      UNITED_ARAB_EMIRATES: "الإمارات العربية المتحدة",
      BAHRAIN: "البحرين",
      KUWAIT: "الكويت",
      SAUDI_ARABIA: "السعودية",
      YEMEN: "اليمن",
      QATAR: "قطر",
      OMAN: "سلطنة عمان",
      JORDAN: "الأردن",
      SYRIA: "سوريا",
      LEBANON: "لبنان",
      IRAQ: "العراق",
      EGYPT: "مصر",
      MOROCCO: "المغرب",
      LIBYA: "ليبيا",
      TUNISIA: "تونس",
      ALGERIA: "الجزائر",
      SUDAN: "السودان",
      MAURITANIA: "موريتانيا",
      DJIBOUTI: "جيبوتي",
      SOMALIA: "الصومال",
    },
    en: {
      PALESTINE: "Palestine",
      UNITED_ARAB_EMIRATES: "United Arab Emirates",
      BAHRAIN: "Bahrain",
      KUWAIT: "Kuwait",
      SAUDI_ARABIA: "Saudi Arabia",
      YEMEN: "Yemen",
      QATAR: "Qatar",
      OMAN: "Oman",
      JORDAN: "Jordan",
      SYRIA: "Syria",
      LEBANON: "Lebanon",
      IRAQ: "Iraq",
      EGYPT: "Egypt",
      MOROCCO: "Morocco",
      LIBYA: "Libya",
      TUNISIA: "Tunisia",
      ALGERIA: "Algeria",
      SUDAN: "Sudan",
      MAURITANIA: "Mauritania",
      DJIBOUTI: "Djibouti",
      SOMALIA: "Somalia",
    },
    fr: {
      PALESTINE: "Palestine",
      UNITED_ARAB_EMIRATES: "Émirats arabes unis",
      BAHRAIN: "Bahreïn",
      KUWAIT: "Koweït",
      SAUDI_ARABIA: "Arabie saoudite",
      YEMEN: "Yémen",
      QATAR: "Qatar",
      OMAN: "Oman",
      JORDAN: "Jordanie",
      SYRIA: "Syrie",
      LEBANON: "Liban",
      IRAQ: "Irak",
      EGYPT: "Égypte",
      MOROCCO: "Maroc",
      LIBYA: "Libye",
      TUNISIA: "Tunisie",
      ALGERIA: "Algérie",
      SUDAN: "Soudan",
      MAURITANIA: "Mauritanie",
      DJIBOUTI: "Djibouti",
      SOMALIA: "Somalie",
    },
  };
  return (
    <table
      className={
        language === "ar"
          ? "z-10 w-full text-sm text-right  text-gray-500 dark:text-gray-400 "
          : "z-10 w-full text-sm  text-left text-gray-500 dark:text-gray-400 "
      }
    >
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th
            scope="col"
            className="flex items-center px-6 py-2 cursor-pointer"
            onClick={onNomCLick}
          >
            <>
              <span>{getTranslation(translation, "NAME")}</span>

              {nomClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </>
          </th>
          <th scope="col" className="px-4 py-2">
            {getTranslation(translation, "EMAIL")}
          </th>
          <th scope="col" className="px-4 py-2">
            {getTranslation(translation, "PHONE")}
          </th>
          <th
            scope="col"
            className="px-4 py-2 cursor-pointer"
            onClick={onUniversityNameClick}
          >
            <div className="flex items-center ">
              <span>{getTranslation(translation, "UNIVERSITY_NAME")} </span>
              {universityNameClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th
            scope="col"
            className="px-8 py-2 cursor-pointer"
            onClick={onRoleClick}
          >
            <div className="flex items-center ">
              <span> {getTranslation(translation, "ROLE")} </span>
              {roleClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th
            scope="col"
            className="px-8 py-2 cursor-pointer"
            onClick={onCountryClick}
          >
            <div className="flex items-center ">
              <span> {getTranslation(translation, "COUNTRY")} </span>
              {countryClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          {localStorage.getItem("role") === "ROLE_RESP_UNI" ||
          localStorage.getItem("role") === "ROLE_ADMIN_ALECSO" ? (
            <th
              scope="col"
              className="px-4 py-2 cursor-pointer"
              onClick={onSpecialtyClick}
            >
              <div className="flex items-center ">
                <span>{getTranslation(translation, "SPECIALITY")}</span>
                {specialtyClicked ? (
                  <div className="cursor-pointer ">
                    <DownArrow />
                  </div>
                ) : (
                  <div className="cursor-pointer ">
                    <UpArrow />
                  </div>
                )}
              </div>
            </th>
          ) : (
            <></>
          )}
          <th scope="col" className="px-4 py-2">
            {getTranslation(translation, "ACTIVE")}
          </th>
          <th scope="col" className="px-4 py-2"></th>
          <th scope="col" className="px-4 py-2"></th>
        </tr>
      </thead>
      <tbody className="overflow-x-hidden h-10vh">
        {agents
          ?.slice(currentPage * perPage - perPage, currentPage * perPage)
          .map((agent, key) => (
            <tr
              key={key}
              className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                (key + 1) % 2 !== 0 ? `bg-blue-gray-50` : `bg-white`
              }`}
            >
              <td className="px-6 py-2">{agent?.username}</td>
              <td className="px-6 py-2">{agent?.email}</td>
              <td className="px-6 py-2">
                {convertStringToNumber(agent?.numero)}
              </td>
              <td className="px-6 py-2">{agent?.universityName}</td>

              <td className="px-6 py-2">
                {agent?.role === "مدير الجامعة" ? (
                  <span className="flex items-center justify-start gap-3">
                    <RespIcon />
                    {getTranslation(translation, "UNIVERSITY_DIRECTOR")}
                  </span>
                ) : (
                  <span className="flex items-center justify-start gap-3">
                    <AgentIcon />
                    {getTranslation(translation, "EMPLOYEE")}
                  </span>
                )}
              </td>
              <td className="px-6 py-2">
                {getTranslation(countries, agent.country)}
              </td>
              <td className="px-6 py-2">
                {agent?.speciality === "مدير الجامعة"
                  ? getTranslation(translation, "UNIVERSITY_DIRECTOR")
                  : agent?.speciality}
              </td>
              <td className="px-6 py-2">
                {agent?.block === true ? <BlockIcon /> : <CheckIcon />}
              </td>
              <td className="flex justify-end px-6 py-2">
                <div>
                  <select
                    className={
                      "dropdown_style disabled:pt-4 hover:outline-blue-400 disabled:bg-slate-200 text-ellipsis overflow-hidden  outline-blue-300/70   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  w-44 p-1 flex items-start justify-start "
                    }
                    value="choix"
                    onChange={(e) => {
                      handleChange(
                        e,
                        e.target.value,
                        agent?.email,
                        agent?.userId,
                        agent?.block
                      );
                    }}
                  >
                    <option value="choix">
                      {getTranslation(translation, "CHOOSE")}{" "}
                    </option>
                    {agent?.status === "pending" ? (
                      <option value="resend">
                        {getTranslation(translation, "RESEND_LINK")}{" "}
                      </option>
                    ) : null}
                    <option value="block">
                      {agent?.block === false ? (
                        <> {getTranslation(translation, "BLOCK_USER")} </>
                      ) : (
                        <>{getTranslation(translation, "ACTIVATE_ACCOUNT")} </>
                      )}
                    </option>
                    <option value="delete">
                      {getTranslation(translation, "DELETE_USER")}{" "}
                    </option>
                  </select>
                </div>
              </td>
              <td>
                <button
                  onClick={() => {
                    navigate(`/updateUser/${agent.userId}`);
                  }}
                >
                  <EditIcon />
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
Table.propTypes = {
  agents: PropTypes.array,
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  setAgentId: PropTypes.func,
  setAgent: PropTypes.func,
  openModel: PropTypes.func,
  resendFunction: PropTypes.func,
};
export default Table;
