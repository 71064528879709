import React from "react";
import { getTranslation } from "../../utils/translate/translation";
import UsersListTable from "./UsersListTable";

const UsersList = () => {
  const translation = {
    en: {
      userList: "User List",
    },
    ar: {
      userList: "قائمة المستخدمين",
    },
    fr: {
      userList: "Liste des utilisateurs",
    },
  };

  return (
    <div className="flex flex-col w-full p-8">
      <h1 className="mb-2 text-2xl font-bold text-[#25396f]">
        {getTranslation(translation, "userList")}
      </h1>
      <div className="flex flex-col justify-start w-full p-8 space-y-1 bg-white rounded h-fit">
        <UsersListTable />
      </div>
    </div>
  );
};

export default UsersList;
