/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  dashboardIcon,
  diplomaIcon,
  studentsIcons,
  usersIcon,
} from "../../assets/svg/Index";
import logo from "../../assets/logo.png";
import NavigationBarButton from "./NavigationBarButton";
import NavigationBarAccordion from "./NavigationBarAccordion";
import PropTypes from "prop-types";

const translate = {
  ar: {
    STUDENTS: "قائمة الطلبة",
    ADD_STUDENT: "إضافة طلبة",
    DIPLOMAS: "قائمة الشهادات ",
    DIPLOMAS_NOT_CONFIRM: "شهادات بانتظار التأكيد ",
    DIPLOMAS_REJECTED: "قائمة الشهادات المرفوضة ",
    ADD_DIPLOMAS: "إضافة شهادة ",
    USERS: "قائمة المستخدمين ",
    ADD_USER: "إضافة مستخدم ",
    LIST: "     قائمة الخدمات",
    DASHBOARD: "لوحة التحكّم",
    STUDENT_MANAGEMENT: "إدارة الطلبة",
    DIPLOMAS_MANAGEMENT: "إدارة الشهادات",
    USERS_MANAGEMENT: "إدارة المستخدمين ",
    SETTING: "الإعدادات",
    LOGOUT: "خروج",
  },
  en: {
    STUDENTS: " List of students",
    ADD_STUDENT: "Add students",
    DIPLOMAS: " List of diplomas ",
    DIPLOMAS_NOT_CONFIRM: "Diplomas awaiting confirmation",
    DIPLOMAS_REJECTED: "List of rejected Diplomas",
    ADD_DIPLOMAS: " Add diploma ",
    USERS: " List of users ",
    ADD_USER: " Add user ",
    LIST: "     List of services    ",
    DASHBOARD: " Dashboard",
    STUDENT_MANAGEMENT: " Student management    ",
    DIPLOMAS_MANAGEMENT: " Diplomas Management",
    USERS_MANAGEMENT: " User management ",
    SETTING: "Settings",
    LOGOUT: "Logout",
  },
  fr: {
    STUDENTS: " Liste des étudiants",
    ADD_STUDENT: "Ajouter des étudiants",
    DIPLOMAS: " Liste des diplômes ",
    DIPLOMAS_NOT_CONFIRM: "  Diplômes en attente de confirmation ",
    DIPLOMAS_REJECTED: "   Liste des diplomes rejetés ",
    ADD_DIPLOMAS: " Ajouter un diplome ",
    USERS: " Liste des utilisateurs    ",
    ADD_USER: " Ajouter un utilisateur    ",
    LIST: "      Liste des prestations",
    DASHBOARD: " Tableau de bord",
    STUDENT_MANAGEMENT: "Gestion des étudiants",
    DIPLOMAS_MANAGEMENT: "Gestion des diplomes  ",
    USERS_MANAGEMENT: " Gestion des utilisateurs ",
    SETTING: "Paramètres",
    LOGOUT: "Se déconnecter",
  },
};

import { getTranslation } from "../../utils/translate/translation";

function NavigationBarBody({ show, setShow, activeBadge, setActiveBadge }) {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const [selectedAccordion, setSelectedAccordion] = useState("");

  const studentSubMenuItemsResponsable = [
    {
      title: `${getTranslation(translate, "STUDENTS")}`,
      path: "/student",
      icon: <></>,
      activePath: "/student",
      selectPage: () => {
        changeSelectedPage("/student");
      },
    },
  ];
  const studentSubMenuItemsAgent = [
    {
      title: `${getTranslation(translate, "STUDENTS")}`,
      path: "/student",
      icon: <></>,
      activePath: "/student",
      selectPage: () => {
        changeSelectedPage("/student");
      },
    },
    {
      title: `${getTranslation(translate, "ADD_STUDENT")}`,
      path: "/add_student",
      icon: <></>,
      activePath: "/add_student",
      selectPage: () => {
        changeSelectedPage("/add_student");
      },
    },
  ];
  const diplomaSubMenuItemsResponsable = [
    {
      title: `${getTranslation(translate, "DIPLOMAS")}`,
      path: "/diplomasList",
      icon: <></>,
      activePath: "/diplomasList",
      selectPage: () => {
        changeSelectedPage("/diplomasList");
      },
    },
    {
      title: `${getTranslation(translate, "DIPLOMAS_NOT_CONFIRM")}`,
      path: "/listsAgent",
      icon: <></>,
      activePath: "/listsAgent",
      selectPage: () => {
        changeSelectedPage("/listsAgent");
      },
      isBadgeActive: true,
    },
    {
      title: `${getTranslation(translate, "DIPLOMAS_REJECTED")}`,
      path: "/diplomasRejected",
      icon: <></>,
      activePath: "/diplomasRejected",
      selectPage: () => {
        changeSelectedPage("/diplomasRejected");
      },
    },
  ];
  const diplomaSubMenuItemsAgent = [
    {
      title: `${getTranslation(translate, "DIPLOMAS")}`,
      path: "/diplomasList",
      icon: <></>,
      activePath: "/diplomasList",
      selectPage: () => {
        changeSelectedPage("/diplomasList");
      },
    },
    {
      title: `${getTranslation(translate, "ADD_DIPLOMAS")}`,
      path: "/add_diplomas",
      icon: <></>,
      activePath: "/add_diplomas",
      selectPage: () => {
        changeSelectedPage("/add_diplomas");
      },
    },
    {
      title: `${getTranslation(translate, "DIPLOMAS_NOT_CONFIRM")}`,
      path: "/diplomas_not_confirm",
      icon: <></>,
      activePath: "/diplomas_not_confirm",
      selectPage: () => {
        changeSelectedPage("/diplomas_not_confirm");
      },
    },
    {
      title: `${getTranslation(translate, "DIPLOMAS_REJECTED")}`,
      path: "/diplomasRejected",
      icon: <></>,
      activePath: "/diplomasRejected",
      selectPage: () => {
        changeSelectedPage("/diplomasRejected");
      },
      isBadgeActive: true,
    },
  ];
  const usersSubMenuItemsResponsable = [
    {
      title: `${getTranslation(translate, "USERS")}`,
      path: "/usersList",
      icon: <></>,
      activePath: "/usersList",
      selectPage: () => {
        changeSelectedPage("/usersList");
      },
    },
    {
      title: `${getTranslation(translate, "ADD_USER")}`,
      path: "/add_user",
      icon: <></>,
      activePath: "/add_user",
      selectPage: () => {
        changeSelectedPage("/add_user");
      },
    },
  ];

  const changeSelectedPage = (url) => {
    navigate(url);
  };
  const onButtonClick = (url) => {
    changeSelectedPage(url);
    setSelectedAccordion("");
  };

  const handleLogout = () => {
    navigate("/login");
    localStorage.removeItem("role");
    localStorage.removeItem("token");
  };

  const language = localStorage.getItem("language");

  return (
    <div className=" w-[360px] h-full p-10 bg-white">
      {show && (
        <div
          className="flex justify-end pb-4 cursor-pointer "
          onClick={() => setShow(!show)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      )}
      <div className="flex items-center justify-center">
        <img src={logo} alt="Logo" className="items-center " />
      </div>

      <div className="mt-10">
        <p className="pb-2 pr-2 text-md font-medium text-[#25396f]">
          {getTranslation(translate, "LIST")}
        </p>
        <NavigationBarButton
          title={getTranslation(translate, "DASHBOARD")}
          path="/dashboard"
          action={onButtonClick}
          icon={dashboardIcon}
          language={language}
        />
        {(role === "ROLE_RESP_UNI" || role === "ROLE_AGENT_UNI") && (
          <>
            {role === "ROLE_RESP_UNI" && (
              <>
                <NavigationBarAccordion
                  title={getTranslation(translate, "STUDENT_MANAGEMENT")}
                  icon={studentsIcons}
                  subMenu={studentSubMenuItemsResponsable}
                  selectedAccordion={selectedAccordion}
                  setSelectedAccordion={setSelectedAccordion}
                  accordionName={"studentAccordion"}
                />
              </>
            )}

            {role === "ROLE_AGENT_UNI" && (
              <>
                <NavigationBarAccordion
                  title={getTranslation(translate, "STUDENT_MANAGEMENT")}
                  icon={studentsIcons}
                  subMenu={studentSubMenuItemsAgent}
                  accordionName={"studentAccordion"}
                  selectedAccordion={selectedAccordion}
                  setSelectedAccordion={setSelectedAccordion}
                />
              </>
            )}
            {role === "ROLE_AGENT_UNI" && (
              <>
                <NavigationBarAccordion
                  title={getTranslation(translate, "DIPLOMAS_MANAGEMENT")}
                  icon={diplomaIcon}
                  subMenu={diplomaSubMenuItemsAgent}
                  accordionName={"diplomaAccordion"}
                  selectedAccordion={selectedAccordion}
                  setSelectedAccordion={setSelectedAccordion}
                  isBadgeActive={true}
                />
              </>
            )}
            {role === "ROLE_RESP_UNI" && (
              <>
                <NavigationBarAccordion
                  title={getTranslation(translate, "DIPLOMAS_MANAGEMENT")}
                  icon={diplomaIcon}
                  subMenu={diplomaSubMenuItemsResponsable}
                  accordionName={"diplomaAccordion"}
                  selectedAccordion={selectedAccordion}
                  setSelectedAccordion={setSelectedAccordion}
                  isBadgeActive={true}
                />
              </>
            )}
          </>
        )}
        {(role === "ROLE_RESP_UNI" ||
          role === "ROLE_ADMIN_ALECSO" ||
          role === "ROLE_ADMIN_K2LIS") && (
          <>
            <NavigationBarAccordion
              title={getTranslation(translate, "USERS_MANAGEMENT")}
              icon={usersIcon}
              subMenu={usersSubMenuItemsResponsable}
              accordionName={"usersAccordion"}
              selectedAccordion={selectedAccordion}
              setSelectedAccordion={setSelectedAccordion}
            />
          </>
        )}
        <div className="w-2/3 mb-2 border border-blue-900" />

        <NavigationBarButton
          title={getTranslation(translate, "SETTING")}
          activePath="/settings"
          path="/settings"
          action={onButtonClick}
          icon={<></>}
        />
        <NavigationBarButton
          title={getTranslation(translate, "LOGOUT")}
          action={handleLogout}
          icon={<></>}
          activePath="/"
          path="/"
        />
      </div>
    </div>
  );
}

NavigationBarBody.propTypes = {
  role: PropTypes.string,
  changeSelectedPage: PropTypes.func,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  navigate: PropTypes.func,
  setSelectedPage: PropTypes.func,
  selectedPage: PropTypes.string,
  setOpen: PropTypes.func,
  open: PropTypes.number,
  activeBadge: PropTypes.bool,
  setActiveBadge: PropTypes.func,
};

export default NavigationBarBody;
