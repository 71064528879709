import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import PropTypes from "prop-types";
import { CategoryScale } from "chart.js";
import { getTranslation } from "../../utils/translate/translation";
import Spinner from "../spinner/Spinner";

Chart.register(CategoryScale);
const translate = {
  ar: {
    MONTHLY_VISITS: "  الزيارات الشهرية للتثبت من مصداقية الشهادات",
  },
  en: {
    MONTHLY_VISITS: " Monthly visits to verify the credibility of testimonies",
  },
  fr: {
    MONTHLY_VISITS:
      "Visites mensuelles pour vérifier la crédibilité des témoignages",
  },
};
const BarChart = ({ dataBarChart, loading }) => {
  const options = {
    plugins: {
      legend: {
        display: false,
        responsive: true,
        maintainAspectRatio: false,
      },
    },
    onHover: (event, chartElement) => {
      if (chartElement.length == 1) {
        event.native.target.style.cursor = "pointer";
      }
    },

    scales: {
      y: {
        color: "white",
        drawBorder: false,
        grid: {
          borderColor: "green",
          borderWidth: 3,
          drawBorder: false,
        },
      },
      x: {
        color: "white",
        drawBorder: false,
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div
      className={`bg-white rounded-xl p-3 w-auto ${
        loading ? "" : "flex flex-col items-center justify-center lg:h-[40vh]"
      } `}
    >
      {loading ? (
        <>
          <span className="text-1xl font-bold text-indigo-900">
            {getTranslation(translate, "MONTHLY_VISITS")}
          </span>
          <div className="pt-6 lg:h-[40vh] lg:w-full sm:w-full sm:h-full">
            <Bar data={dataBarChart} options={options} height={90} />
          </div>
        </>
      ) : (
        <Spinner />
      )}

      <div className="flex w-full flex-col gap-4"></div>
    </div>
  );
};
BarChart.propTypes = {
  dataBarChart: PropTypes.object,
  loading: PropTypes.bool,
};
export default BarChart;
