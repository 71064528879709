import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSelectedModeMutation } from "../../store/service/userService";
import { getTranslation } from "../../utils/translate/translation";

import MainButton from "../button/MainButton";
import { toastSucces } from "../toast/Toast";
const translate = {
  ar: {
    TOAST_SUCCES: "تمت العملية بنجاح",
    MODE_ONE: "  رفع ملف اكسل مرفق بملفات pdfs",
    MODE_TWO: "رفع ملف اكسل",
    MODE_THREE: "   إنشاء دبلومة رقمية",
    BUTTON_TITLE: "حفظ التعديلات",
  },
  en: {
    TOAST_SUCCES: "Operation accomplished successfully",
    MODE_ONE: "Upload an excel file attached to pdfs",
    MODE_TWO: "Upload excel file",
    MODE_THREE: " Create a digital diploma",
    BUTTON_TITLE: "Save",
  },
  fr: {
    TOAST_SUCCES: "Opération réussie",
    MODE_ONE: " Télécharger un fichier excel joint aux pdfs",
    MODE_TWO: "Téléchargez un fichier excel",
    MODE_THREE: " Créer un diplôme numérique",
    BUTTON_TITLE: "Enregistrer ",
  },
};
const SelectMode = () => {
  const [selectedMode] = useSelectedModeMutation();
  const modeSelected = useSelector((state) => state.user.mode);
  const [selectMode, setSelectMode] = useState(false);

  const [mode, setMode] = useState({
    modeOne: true,
    modeTwo: true,
    modeThree: true,
  });

  const handleModeClick = (modeName) => {
    setMode((prevMode) => ({
      ...prevMode,
      [modeName]: !prevMode[modeName],
    }));
    setSelectMode(true);
  };

  const onSelectMode = () => {
    selectedMode(mode);
    setSelectMode(false);
    toastSucces(`${getTranslation(translate, "TOAST_SUCCES")}`);
  };

  useEffect(() => {
    setMode(modeSelected || mode);
  }, [modeSelected]);

  return (
    <div className="flex justify-center">
      <div className="max-w-[auto] flex flex-col items-center justify-center w-full p-4 bg-white h-fit rounded-md text-white">
        <div className="w-full">
          <div className="grid-flow-row gap-2 lg:flex lg:justify-between lg:w-full sm:grid auto-rows-max">
            <button
              onClick={() => handleModeClick("modeOne")}
              className={`sm:p-4 px-10 rounded-md lg:w-72 ${
                mode.modeOne
                  ? "bg-[#435ebe] border-white"
                  : "bg-[#f1f0ef] border text-[#607080]"
              }`}
            >
              {getTranslation(translate, "MODE_ONE")}
            </button>
            <button
              onClick={() => handleModeClick("modeTwo")}
              className={`sm:p-4 px-10 rounded-md lg:w-72 ${
                mode.modeTwo
                  ? "bg-[#435ebe] border-white"
                  : "bg-[#f1f0ef] border text-[#607080]"
              }`}
            >
              {getTranslation(translate, "MODE_TWO")}
            </button>
            <button
              onClick={() => handleModeClick("modeThree")}
              className={`sm:p-4 px-10 rounded-md lg:w-72 ${
                mode.modeThree
                  ? "bg-[#435ebe] border-white"
                  : "bg-[#f1f0ef] border text-[#607080]"
              }`}
            >
              {getTranslation(translate, "MODE_THREE")}
            </button>
          </div>
        </div>
        {selectMode && (
          <div className="flex justify-end w-full pt-8">
            <div className="w-40">
              <MainButton
                title={getTranslation(translate, "BUTTON_TITLE")}
                action={onSelectMode}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectMode;
