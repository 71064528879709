import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
import { ThemeProvider } from "@material-tailwind/react";
import "react-toastify/dist/ReactToastify.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
if (!localStorage.getItem("language")) {
  localStorage.setItem("language", "ar");
}

const language = localStorage.getItem("language");
const getDirection = language === "ar" ? "rtl" : "ltr";
root.render(
  <div dir={getDirection}>
    <ThemeProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </div>
);
