import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import { GET_AGENTS } from "../../store/features/diplomasSlice/diplomasSlice";

import { LOADING_USER } from "../../store/features/userSlice/userSlice";
import { useGetAgentsByRespMutation } from "../../store/service/diplomasService";

import { useGetUserMutation } from "../../store/service/userService";
import { getTranslation } from "../../utils/translate/translation";

import AgentTable from "./AgentTable";
const translate = {
  ar: {
    MESSAGE_: " الرجاء الانتظار ، نحن بصدد إجراء العملية",
    PAGE_TITLE: " طلبات إضافة شهادات بانتظار التأكيد",
    MESSAGE_ONE: "عرض إجمالي",
  },
  en: {
    MESSAGE_: "Please wait, we are processing",
    PAGE_TITLE: "Requests to add certificates are awaiting confirmation",
    MESSAGE_ONE: "Total display",
  },
  fr: {
    MESSAGE_: " Veuillez patienter, nous sommes en cours de traitement",
    PAGE_TITLE:
      " Les demandes d'ajout de certificats sont en attente de confirmation",
    MESSAGE_ONE: "Affichage total",
  },
};
const AgentList = () => {
  const dispatch = useDispatch();
  const [getAgents] = useGetAgentsByRespMutation();
  const [agents, setAgent] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const [getUser] = useGetUserMutation();

  /**
   * @function _getUser
   * @description this function to get User
   *
   * @return
   */
  const _getUser = () => {
    getUser().then((res) => {
      dispatch(
        LOADING_USER({
          role: res.data.data.role.name,
          username: res.data.data.username,
          email: res.data.data.email,
          universityName: res.data.universityName,
          speciality: res.data.data.speciality,
          numero: res.data.data.numero,
          mode: res?.data?.data?.university?.mode,
          isAccepted: res.data.data.IsAccepted,
          signature: res?.data?.data?.signature,
          logo: res?.data?.data?.university?.logo,
        })
      );
    });
  };
  const [count, setCount] = useState(0);

  const countNbDiplomas = (data) => {
    let countDip = 0;
    for (let i = 0; i < data.length; i++) {
      countDip += data[i].count;
    }
    setCount(countDip);
  };
  const _getPendingDiplomas = () => {
    getAgents().then((res) => {
      setAgent(res.data.data);
      countNbDiplomas(res.data.data);
      setLoading(true);
      dispatch(GET_AGENTS({ agent: res.data.data, countDiploma: count }));
    });
  };
  useEffect(() => {
    _getPendingDiplomas();
    _getUser();
  }, [count]);
  return (
    <>
      {user.isAccepted ? (
        <>
          <div className="flex flex-col justify-end mt-10 px-[3%] ">
            <span className="mb-4 text-2xl font-bold text-indigo-900">
              {getTranslation(translate, "MESSAGE_")}
            </span>
            <Spinner />
          </div>
        </>
      ) : (
        <>
          <div>
            <div className="flex flex-col justify-end mt-10 px-[3%] ">
              <h1 className="mb-2 text-2xl font-bold text-indigo-900">
                {getTranslation(translate, "PAGE_TITLE")}
              </h1>

              {agents?.length !== 0 ? (
                <>
                  <div className="max-w-full flex flex-col items-center justify-center w-full p-4 bg-white h-fit rounded-md">
                    {loading ? (
                      <div className="w-full">
                        <div>
                          <h3 className="mb-2 font-bold text-blue-900 text-1xl">
                            {getTranslation(translate, "MESSAGE_ONE")}
                          </h3>
                        </div>

                        <AgentTable agents={agents} />
                      </div>
                    ) : (
                      <div className="h-[300px] flex items-center justify-center ">
                        <Spinner />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AgentList;
