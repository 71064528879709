import { toast } from "react-toastify";

const toastSucces = (message) => {
  toast.success(message, {
    position: "top-left",
    theme: "colored",
  });
};

const toastInfo = (message) => {
  toast.success(message, {
    position: "top-left",
    theme: "colored",
  });
};

const toastErrorServer = (message) => {
  toast.error(message, {
    position: "top-left",
    theme: "colored",
  });
};

const toastLogoutServer = (message) => {
  toast.error(message, {
    position: "top-left",
    theme: "colored",
  });
};
export { toastSucces, toastErrorServer, toastLogoutServer, toastInfo };
