/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";

const AcceptedBy = ({ addressResponsable }) => {
  return (
    <div className="flex items-center justify-center w-20 h-10  text-white bg-blue-500 rounded-md cursor-pointer hover:shadow-lg">
      <a
        target="_blank"
        href={`https://testnet.ftmscan.com/address/${addressResponsable}`}
        className="text-blue-900 hover:text-blue-900"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 text-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </a>
    </div>
  );
};

export default AcceptedBy;
