import React, { useId } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GET_AGENTS } from "../../store/features/diplomasSlice/diplomasSlice";
import { getTranslation } from "../../utils/translate/translation";
import { multilanguageReader } from "../../utils/translate/multilanguage-reader";

const translate = {
  ar: {
    AGENT: " الموظف",
    YEAR: " السنة الجامعية",
    TYPE: "نوع الشهادة",
    SPECIALITY: "الاختصاص",

    NB_DIPLOMAS: "عدد الشهادات",
    DATE: "تاريخ الإضافة",
    OPEN: "اطّلاع",
  },
  en: {
    AGENT: " Agent",
    YEAR: "Year",
    TYPE: "Type Diploma",
    SPECIALITY: "Speciality",

    NB_DIPLOMAS: "The number of Diplomas",
    DATE: "Added date",
    OPEN: "Look",
  },
  fr: {
    AGENT: " Agent",
    TYPE: "Type de diplome",
    YEAR: " L'année universitaire",
    SPECIALITY: "Spécialité",

    NB_DIPLOMAS: "Le nombre de Diplomes",
    DATE: "Date ajoutée",
    OPEN: "Regarder",
  },
};
const AgentTable = ({ agents }) => {
  const navigate = useNavigate();
  const disptach = useDispatch();
  const userId = useId();
  const countDiploma = useSelector((state) => state.diploma.countDiploma);
  /**
   * @function getAgentByiD
   * @description this function to get Agent By Id
   *
   * @return
   */
  const getAgentByiD = (
    id,
    username,
    count,
    year,
    type,
    speciality,
    insertionDate,
    createdBy
  ) => {
    navigate(`/diplomas_not_confirm/${id}`);
    disptach(
      GET_AGENTS({
        agent: {
          username,
          count,
          year,
          type,
          speciality,
          insertionDate,
          createdBy,
        },
        countDiploma: countDiploma,
      })
    );
  };
  const language = localStorage.getItem("language");
  return (
    <div className="relative h-auto overflow-x-auto rounded-md sm:rounded-lg">
      <table
        className={
          language === "ar"
            ? " w-full text-sm text-right text-gray-500 dark:text-gray-400 "
            : " w-full text-sm text-left text-gray-500 dark:text-gray-400 "
        }
      >
        <thead className="text-xs text-gray-900 uppercase dark:text-gray-400">
          <tr>
            <th scope="col" className="px-2 py-2">
              {getTranslation(translate, "AGENT")}
            </th>
            <th scope="col" className="px-2 py-2">
              {getTranslation(translate, "YEAR")}
            </th>
            <th scope="col" className="px-2 py-2">
              {getTranslation(translate, "TYPE")}
            </th>
            <th scope="col" className="px-2 py-2">
              {getTranslation(translate, "SPECIALITY")}
            </th>
            <th scope="col" className="px-2 py-2">
              {getTranslation(translate, "NB_DIPLOMAS")}
            </th>
            <th scope="col" className="px-2 py-2">
              {getTranslation(translate, "DATE")}
            </th>
            <th scope="col" className="px-2 py-2"></th>
          </tr>
        </thead>
        <tbody className="overflow-x-hidden h-10vh">
          {agents?.map((agent, key) => (
            <tr
              key={userId}
              className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                (key + 1) % 2 !== 0 ? `bg-[#f2f2f2]` : `bg-white`
              }`}
            >
              <td className="px-2 py-2">{agent?.createdBy?.username}</td>
              <td className="px-2 py-2">{agent?.year}</td>
              <td className="px-2 py-2">{multilanguageReader(agent?.type)}</td>
              <td className="px-2 py-2">
                {multilanguageReader(agent?.speciality)}
              </td>
              <td className="px-2 py-2">{agent?.count}</td>
              <td className="px-2 py-2">
                {agent?.insertionDate === "Invalid date"
                  ? null
                  : agent?.insertionDate?.substr(0, 10)}
              </td>
              <td className="px-2 py-2">
                <button
                  className="p-1 px-3 mx-1 text-xs flex items-center gap-1 font-semibold text-white bg-[#198754] rounded w-fi h-fit"
                  onClick={() =>
                    getAgentByiD(
                      agent?.createdBy?._id,
                      agent?.createdBy?.username,
                      agent?.count,
                      agent?.year,
                      agent?.type,
                      agent?.speciality,
                      agent?.insertionDate,
                      agent?.createdBy
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59"
                    />
                  </svg>
                  {getTranslation(translate, "OPEN")}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
AgentTable.propTypes = {
  agents: PropTypes.array,
};
export default AgentTable;
