export const translate = {
  ar: {
    DIPLOMA: "الشهادة",
    METADATA: "البيانات الوصفية",
    STUDENT: "  الطالب ",
    DATE_OF_BRITH: "تاريخ الولادة",
    PLACE_OF_BRITH: "مكان الولادة",
    NIN: " المعرّف الوطني",
    TYPE: "نوع الشهادة",
    YEAR: "السنة الجامعية",
    SPECIALITY: "الاختصاص",
    HONORS: "الملاحظة",
    AVERAGE: "معدل",
    APPROVAL_DATE: "تاريخ المصادقة",
    SIGNATURE_DATE: "تاريخ التوقيع",
    CLOSE: "غلق النافذة ",
    DIPLOMA_DIGITAL: "شهادة رقمية",
    MODE_ONE: "رفع ملف اكسل مرفق بملفات pdfs",
    MODE_TWO: "رفع ملف اكسل",
    REVOKE: "إبطال",
    REJECTED: "إلغاء",
    DELETE: "حذف",
    UPDATE: "تعديل",
    TOAST_SUCCESS: "تمت العملية بنجاح !",
    EN: "الإنجليزية",
    FR: "الفرنسية",
    AR: "العربية",
    EXAMS_PV_DATE: "  تاريخ لجنة التحكيم الامتحانات",
    INERNSHIP_PV_DATE: " تاريخ هيئة التحكيم للدفاع عن مشروع",
    PAPER_PV_DATE: "   تاريخ هيئة التحكيم التحقق من التدرج المؤرخ",
    DIPLOMA_NAME: "اسم شهادة",
    SAVE: "تأكيد",
    LANGUAGE: "اللغات",
  },
  en: {
    DIPLOMA: "Diploma",
    METADATA: "Metadata",
    STUDENT: "Student",
    DATE_OF_BRITH: "Birth date",
    PLACE_OF_BRITH: "Place of birth",
    NIN: "The national identifier",
    TYPE: "Type of diploma",
    YEAR: "Year",
    SPECIALITY: "Speciality",
    HONORS: "Note",
    AVERAGE: "Mark",
    APPROVAL_DATE: "Approval date",
    SIGNATURE_DATE: "Signature date",
    CLOSE: "Close",
    DIPLOMA_DIGITAL: "Digital Diploma",
    MODE_ONE: "Upload Excel file accompanied by PDF files",
    MODE_TWO: "Upload Excel file",
    REVOKE: "Revoke",
    REJECTED: "Reject",
    DELETE: "Delete",
    UPDATE: "Update",
    TOAST_SUCCESS: "Operation accomplished successfully !",
    EN: "English",
    FR: "French",
    AR: "Arabic",

    EXAMS_PV_DATE: "Examination Committee PV Date",
    INERNSHIP_PV_DATE: "Internship Defense Committee PV Date",
    PAPER_PV_DATE: "Progress Verification Committee PV Date",
    DIPLOMA_NAME: "Diploma Name",
    SAVE: "Confirmation",
    LANGUAGE: "",
  },
  fr: {
    DIPLOMA: "Diplome",
    METADATA: "Métadonnées",
    STUDENT: "Étudiant",
    DATE_OF_BRITH: " Date de naissance",
    PLACE_OF_BRITH: "Lieu de naissance",
    NIN: "L'identifiant national",
    TYPE: "Type de diplome",
    YEAR: " L'année universitaire",
    SPECIALITY: "الاختصاص",
    HONORS: "Note",
    AVERAGE: "Moyenne",
    APPROVAL_DATE: "Date d'approbation",
    SIGNATURE_DATE: "Date de la signature",
    CLOSE: "Ferme la fenêtre",
    DIPLOMA_DIGITAL: "Diplome numérique",
    MODE_ONE: "Télécharger un fichier Excel accompagné de fichiers PDF",
    MODE_TWO: "Télécharger un fichier Excel",
    REVOKE: "Révoquer",
    REJECTED: "Rejeter",
    DELETE: "Supprimer",
    UPDATE: "MISE À JOUR",
    TOAST_SUCCESS: "Opération réussie !",
    EN: "Anglais",
    FR: "Français",
    AR: "Arabe",
    EXAMS_PV_DATE: "Date du PV du Comité d'Examen",
    INERNSHIP_PV_DATE: "Date du PV du Comité de Soutenance de Stage",
    PAPER_PV_DATE: "Date du PV du Comité de Vérification de Progrès",
    DIPLOMA_NAME: "Nom du Diplôme",
    SAVE: "Confirmation",
    LANGUAGE: "",
  },
};
