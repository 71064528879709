import React from "react";
import { Fragment } from "react";
import PropTypes from "prop-types";
import { useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import { getTranslation } from "../../utils/translate/translation";

const fileTypes = ["png", "PNG"];
const translate = {
  ar: {
    DROP_FILES: "قم بإسقاط الملفات هنا للتحميل",
  },
  en: {
    DROP_FILES: "Drop files here to upload",
  },
  fr: {
    DROP_FILES: "Déposer des fichiers ici pour télécharger",
  },
};
const DragAndDropFile = ({ title, onImageChange }) => {
  const file = useRef(null);
  const onButtonClick = () => {
    file?.current?.onClick();
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
  };
  const children = (
    <div
      className=" flex items-center justify-center gap-3 md:w-full h-20 bg-[#f2f2f2] rounded-md p-14  text-center border-dashed border-2 border-gray-500 "
      onClick={onButtonClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-16 h-16 "
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 00-1.883 2.542l.857 6a2.25 2.25 0 002.227 1.932H19.05a2.25 2.25 0 002.227-1.932l.857-6a2.25 2.25 0 00-1.883-2.542m-16.5 0V6A2.25 2.25 0 016 3.75h3.879a1.5 1.5 0 011.06.44l2.122 2.12a1.5 1.5 0 001.06.44H18A2.25 2.25 0 0120.25 9v.776"
        />
      </svg>
      <span className="md:text-2xl ">
        {getTranslation(translate, "DROP_FILES")}
      </span>
    </div>
  );
  // triggers when file is dropped
  return (
    <Fragment>
      <form
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
        className="w-full"
      >
        <span className="text-xl text-blue-900">{title}</span>

        <FileUploader
          handleChange={onImageChange}
          types={fileTypes}
          // eslint-disable-next-line react/no-children-prop
          children={children}
          classes="w-full"
        />
      </form>
    </Fragment>
  );
};
DragAndDropFile.propTypes = {
  title: PropTypes.string,
  setImage: PropTypes.func,
  image: PropTypes.object,
  onImageChange: PropTypes.func,
};
export default DragAndDropFile;
