/* eslint-disable no-unused-vars */
import React from "react";
import { getTranslation } from "../../../../../utils/translate/translation";
import Input from "../../../../input/Input";
import PropTypes from "prop-types";
import { useState } from "react";
import { toastSucces } from "../../../../toast/Toast";
import {
  GET_AGENTS,
  GET_DIPLOMAS,
} from "../../../../../store/features/diplomasSlice/diplomasSlice";
import { useUpdateDiplomaMutation } from "../../../../../store/service/diplomasService";
import { useDispatch, useSelector } from "react-redux";
import {
  isThereCommunValues,
  multilanguageReader,
} from "../../../../../utils/translate/multilanguage-reader";
import { useEffect } from "react";
import Alert from "../../../../alert/Alert";

const UpdateModeOneAndTwo = ({
  translate,
  item,
  setShowUpdate,
  open,
  setOpen,
  setFiltered,
  filtered,
}) => {
  const handleCloseModalUpdate = () => {
    setOpen(!open);
    setShowUpdate(false);
  };
  const setIsUniversityNameValid = () => {
    return true;
  };

  const [type, setType] = useState("");
  const [firstName] = useState(item?.student?.firstName);
  const [mode] = useState(item?.mode);
  const [lastName] = useState(item?.student?.lastName);
  const [dateOfBirth] = useState(item?.student?.dateOfBirth);
  const [countryOfBirth] = useState(item?.student?.countryOfBirth);
  const [datePvExamen, setDatePvExamen] = useState("");
  const [datePvStages, setDatePvStages] = useState("");
  const [datePvMemoire, setDatePvMemoire] = useState("");
  const [diplomaName, setDiplomaName] = useState("");
  const [honors, setHonors] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [year, setYear] = useState("");
  const [average, setAverage] = useState(0);
  const [signatureDate, setSignatureDate] = useState("");
  const [approvalDate, setApprovalDate] = useState("");
  const [idDiploma, setIdDiploma] = useState(item._id);
  const [hashIpfs] = useState(item?.hashIpfs);
  const [language, setLanguage] = useState("");
  const setApprovalDateValid = () => {
    return true;
  };
  const setSignatureDateValid = () => {
    return true;
  };
  useEffect(() => {
    setHonors(
      multilanguageReader(item?.honors) ? multilanguageReader(item?.honors) : ""
    );
    setType(
      multilanguageReader(item?.type) ? multilanguageReader(item?.type) : ""
    );
    setDatePvExamen(item?.examsPvDate ? item?.examsPvDate : "");
    setDatePvStages(item?.internshipPvDate ? item?.internshipPvDate : "");
    setDatePvMemoire(item?.paperPvDate ? item?.paperPvDate : "");
    setDiplomaName(
      multilanguageReader(item?.diplomaName)
        ? multilanguageReader(item?.diplomaName)
        : ""
    );
    setLanguage(
      Object.keys(item?.student?.firstName)[0]
        ? Object.keys(item?.student?.firstName)[0]
        : ""
    );
    setSpeciality(
      multilanguageReader(item?.speciality)
        ? multilanguageReader(item?.speciality)
        : ""
    );
    setYear(item?.year ? item?.year : "");
    setAverage(item?.average ? item?.average : "");
    setSignatureDate(item?.signatureDate ? item?.signatureDate : "");
    setApprovalDate(item?.approvalDate ? item?.approvalDate : "");
    setIdDiploma(item?._id ? item?._id : "");
  }, []);
  const countDiploma = useSelector((state) => state.diploma.countDiploma);
  const [updateDiploma] = useUpdateDiplomaMutation();
  const dispatch = useDispatch();
  const updateDiplomaRejected = () => {
    dispatch(GET_AGENTS({ countDiploma: countDiploma - 1 }));
    updateDiploma({
      diploma: {
        type: { [language]: type },
        idDiploma: idDiploma,
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth,
        countryOfBirth: countryOfBirth,
        honors: { [language]: honors },
        speciality: { [language]: speciality },
        average: average,
        diplomaName: { [language]: diplomaName },
        paperPvDate: datePvMemoire,
        internshipPvDate: datePvStages,
        examsPvDate: datePvExamen,
        year: year,
        signatureDate: signatureDate,
        approvalDate: approvalDate,
        hashIpfs: hashIpfs,
        status: "pending",
        mode: mode,
      },
    }).then(() => {
      handleCloseModalUpdate();
      const _filtered = filtered.filter((diploma) => diploma._id !== item._id);
      dispatch(GET_DIPLOMAS({ diplomas: _filtered }));
      setFiltered(_filtered);
      toastSucces(getTranslation(translate, "TOAST_SUCCESS"));
    });
  };
  const diplomaLanguage = Object.keys(item?.type);
  const StudentLanguage = Object.keys(item?.student?.firstName);
  const languageUpdate = Object.keys(item?.student?.firstName);
  const onChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };
  const exclamationIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
      />
    </svg>
  );
  return (
    <div className="relative flex flex-col w-[1000px] p-2 bg-white border-0 rounded-lg outline-none focus:outline-none">
      <div className="bg-white rounded">
        {isThereCommunValues(diplomaLanguage, StudentLanguage) === false && (
          <Alert
            type={"error"}
            message={" بيانات الطالب والشهادة مسجلة بلغات مختلفة"}
            icon={exclamationIcon}
            info={"يمكنك حفظ الدبلوم بهذه اللغات"}
            languageUpdate={languageUpdate}
          />
        )}
        <div className="items-center justify-center w-full pt-8 lg:flex md:flex h-fit">
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3 md:w-1/2 md:flex">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-md text-[#25396f] font-semibold">
                {getTranslation(translate, "SPECIALITY")}
              </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={speciality}
                inputChange={setSpeciality}
                value={speciality}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3 md:w-1/2 md:flex">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-md text-[#25396f] font-semibold">
                {" "}
                {getTranslation(translate, "TYPE")}
              </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={type}
                inputChange={setType}
                value={type}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3 md:w-1/2 md:flex">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-md text-[#25396f] font-semibold">
                {" "}
                {getTranslation(translate, "HONORS")}{" "}
              </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={honors}
                inputChange={setHonors}
                value={honors}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
        </div>
        <div className="items-center justify-center w-full pt-8 lg:flex md:flex h-fit">
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3 md:w-1/2 md:flex">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-md text-[#25396f] font-semibold">
                {" "}
                {getTranslation(translate, "YEAR")}
              </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={year}
                inputChange={setYear}
                value={year}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>

          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3 md:w-1/2 md:flex">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-md text-[#25396f] font-semibold">
                {" "}
                {getTranslation(translate, "APPROVAL_DATE")}
              </p>
              <Input
                type={"DATE_FORMAT"}
                placeholder={approvalDate}
                inputChange={setApprovalDate}
                value={approvalDate}
                setValid={setApprovalDateValid}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/3 md:w-1/2 md:flex">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-md text-[#25396f] font-semibold">
                {" "}
                {getTranslation(translate, "SIGNATURE_DATE")}{" "}
              </p>
              <Input
                type={"DATE_FORMAT"}
                placeholder={signatureDate}
                inputChange={setSignatureDate}
                value={signatureDate}
                setValid={setSignatureDateValid}
              />
            </div>
          </div>
        </div>
        <div className="items-center justify-center w-full pt-8 lg:flex h-fit">
          <div className="items-center justify-center mx-2 lg:flex lg:w-[auto] md:w-1/2 md:flex">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-md text-[#25396f] font-semibold">
                {" "}
                {getTranslation(translate, "AVERAGE")}
              </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={average.toString()}
                inputChange={setAverage}
                value={average}
                setValid={setIsUniversityNameValid}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/2">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-md text-[#25396f] font-semibold">
                {getTranslation(translate, "INERNSHIP_PV_DATE")}
              </p>
              <Input
                type={"DATE_FORMAT"}
                placeholder={datePvStages}
                inputChange={setDatePvStages}
                value={datePvStages}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/2">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-md text-[#25396f] font-semibold">
                {getTranslation(translate, "PAPER_PV_DATE")}
              </p>
              <Input
                type={"DATE_FORMAT"}
                placeholder={datePvMemoire}
                inputChange={setDatePvMemoire}
                value={datePvMemoire}
              />
            </div>
          </div>
        </div>
        <div className="items-center justify-center w-full pt-8 lg:flex h-fit">
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/2">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-md text-[#25396f] font-semibold">
                {getTranslation(translate, "EXAMS_PV_DATE")}
              </p>
              <Input
                type={"DATE_FORMAT"}
                placeholder={datePvExamen}
                inputChange={setDatePvExamen}
                value={datePvExamen}
              />
            </div>
          </div>
          <div className="items-center justify-center mx-2 lg:flex lg:w-1/2">
            <div className="flex-col items-start justify-center w-full lg:flex">
              <p className="text-md text-[#25396f] font-semibold">
                {getTranslation(translate, "DIPLOMA_NAME")}
              </p>
              <Input
                type={"NO_CONTROL"}
                placeholder={diplomaName}
                inputChange={setDiplomaName}
                value={diplomaName}
              />
            </div>
          </div>

          {isThereCommunValues(diplomaLanguage, StudentLanguage) === false && (
            <div className="items-center justify-center mx-2 lg:flex ">
              <div className="flex-col items-start justify-center w-full lg:flex">
                <p className="text-md text-[#25396f] font-semibold">
                  {getTranslation(translate, "LANGUAGE")}
                </p>
                <select
                  value={language}
                  onChange={(e) => {
                    onChangeLanguage(e);
                  }}
                  className="hover:outline-blue-400 disabled:bg-slate-200 text-ellipsis overflow-hidden focus:outline-blue-600 focus:border-non p-2 mb-4 outline-blue-300/70   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  "
                >
                  {languageUpdate.map((lang, key) => (
                    <option
                      key={key}
                      value={lang === "ar" ? "ar" : lang === "fr" ? "fr" : "en"}
                      className="p-2"
                    >
                      {lang}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex  items-center justify-end  gap-2 p-2 border-t border-solid rounded-b border-slate-200">
        <button
          className="bg-[#e6eaee] text-[#1a2026] flex gap-1 items-center  leading-normal font-medium uppercase text-base p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
          type="button"
          onClick={() => {
            handleCloseModalUpdate();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          {getTranslation(translate, "CLOSE")}
        </button>
        <button
          className="bg-[#198754] text-[white] flex items-center gap-1  leading-normal font-medium uppercase text-base p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
          type="button"
          onClick={() => {
            updateDiplomaRejected();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          {getTranslation(translate, "SAVE")}
        </button>
      </div>
    </div>
  );
};
UpdateModeOneAndTwo.propTypes = {
  item: PropTypes.object,
  translate: PropTypes.object,
  setShowUpdate: PropTypes.func,
  showUpdate: PropTypes.bool,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  filtered: PropTypes.array,
  setFiltered: PropTypes.func,
};
export default UpdateModeOneAndTwo;
