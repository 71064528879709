/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Fragment } from "react";
import { Transition } from "react-transition-group";

import PropTypes from "prop-types";

import NavigationBarBody from "../../NavigationBarComponents/NavigationBarBody";
import useModal from "../../../hooks/useModal";

const SidebarModel = ({ show, setShow, open, setOpen, activeBadge }) => {
  const { isShowing } = useModal();

  return (
    <Fragment>
      <Transition
        show={isShowing.toString()}
        enter={true}
        leave="transition-opacity duration-300"
        timeout={1}
      >
        <div className="fixed  h-[100vh] inset-0 z-40 overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <NavigationBarBody
            show={show}
            setShow={setShow}
            open={open}
            setOpen={setOpen}
            activeBadge={activeBadge}
          />
        </div>
      </Transition>
      <div className="fixed inset-0 z-30 bg-black opacity-60"></div>
    </Fragment>
  );
};
SidebarModel.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  open: PropTypes.number,
  setOpen: PropTypes.func,
  activeBadge: PropTypes.bool,
};

export default SidebarModel;
