import React from "react";

const LanguageChooser = () => {
  const setSelectedLanguage = (language) => {
    localStorage.setItem("language", language);
    window.location.reload();
  };
  const language = localStorage.getItem("language");
  return (
    <div
      className={`absolute  ${
        language === "ar" ? " left-2 " : "right-2"
      }   " top-20 z-20 w-40 h-auto rounded-md bg-white border-2 border-gray-100"`}
    >
      <div className="flex flex-col w-full  p-1">
        <div className="flex items-center w-full justify-between  h-full p-1 hover:bg-[#435ebe] hover:text-white cursor-pointer rounded">
          <span
            onClick={() => {
              setSelectedLanguage("en");
            }}
          >
            English
          </span>
          {language === "en" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          )}
        </div>
        <div className="flex items-center w-full justify-between  h-full p-1 hover:bg-[#435ebe] hover:text-white cursor-pointer rounded">
          <span
            onClick={() => {
              setSelectedLanguage("fr");
            }}
          >
            Français
          </span>
          {language === "fr" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          )}
        </div>
        <div className="flex items-center w-full justify-between  h-full p-1 hover:bg-[#435ebe] hover:text-white cursor-pointer rounded">
          <span
            onClick={() => {
              setSelectedLanguage("ar");
            }}
          >
            العربية{" "}
          </span>
          {language === "ar" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
};

export default LanguageChooser;
