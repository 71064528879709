import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Table from "./Table";
import {
  useBlockAgentMutation,
  useBlockUserMutation,
  useDeleteAgentMutation,
  useDeleteUserMutation,
  useGetAgentsMutation,
  useGetAllUsersMutation,
} from "../../store/service/userService";
import { toast } from "react-toastify";
import { GET_USERS } from "../../store/features/usersSlice/usersSlice";
import { useSendMailCreateMutation } from "../../store/service/authService";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Pagination from "../../components/pagination/Pagination";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Spinner from "../../components/spinner/Spinner";
import VerifyToken from "../../utils/VerifyToken";
import { logout } from "../../utils/logout";
import { toastErrorServer, toastSucces } from "../../components/toast/Toast";

import { filterList } from "../../utils/filter/filterAgentsBySearchCriteria";
import ManageUserModal from "../../components/modal/User/ManageUserModal";
import { getTranslation } from "../../utils/translate/translation";

const animatedComponents = makeAnimated();

const UsersListTable = () => {
  const translation = {
    en: {
      USER_TABLE_TITLE: "User Data",
      SEARCH_PLACEHOLDER: "Search...!",
      CHOOSE_PLACEHOLDER: "Choose...!",
      UNIVERSITY_NAME: "University Name",
      EMAIL: "Email",
      NUMERO: "Phone",
      SPECIALITY: "Management",
      ROLE: "Job Title",
      COUNTRY: "Country",
      SUCCESS_MESSAGE: "Operation completed successfully!",
      SERVER_ERROR: "Server error!",
      TOKEN_EXPIRED: "Your registration period has ended. Please log in again.",
    },
    ar: {
      USER_TABLE_TITLE: "بيانات المستخدمين",
      SEARCH_PLACEHOLDER: "ابحث...!",
      CHOOSE_PLACEHOLDER: "اختر...!",
      UNIVERSITY_NAME: "اسم الجامعة",
      EMAIL: "البريد الإلكتروني",
      NUMERO: "الهاتف",
      SPECIALITY: "الإدارة",
      ROLE: "الوظيفة",
      COUNTRY: "البلد",
      SUCCESS_MESSAGE: "تمت العملية بنجاح!",
      SERVER_ERROR: "خطأ في الخادم!",
      TOKEN_EXPIRED: "تم إنهاء الصلاحية. يرجى تسجيل الدخول مرة اخرى.",
    },
    fr: {
      USER_TABLE_TITLE: "Données Utilisateur",
      SEARCH_PLACEHOLDER: "Recherche...!",
      CHOOSE_PLACEHOLDER: "Choisir...!",
      UNIVERSITY_NAME: "Nom de l'université",
      EMAIL: "Email",
      NUMERO: "Téléphone",
      SPECIALITY: "Gestion",
      ROLE: "Titre du poste",
      COUNTRY: "Pays",
      SUCCESS_MESSAGE: "Opération terminée avec succès!",
      SERVER_ERROR: "Erreur du serveur!",
      TOKEN_EXPIRED:
        "Votre période d'inscription a expiré. Veuillez vous reconnecter.",
    },
  };
  const options = [
    {
      value: "universityName",
      label: getTranslation(translation, "UNIVERSITY_NAME"),
    },
    { value: "email", label: getTranslation(translation, "EMAIL") },
    { value: "numero", label: getTranslation(translation, "NUMERO") },
    { value: "speciality", label: getTranslation(translation, "SPECIALITY") },
    { value: "role", label: getTranslation(translation, "ROLE") },
    { value: "country", label: getTranslation(translation, "COUNTRY") },
  ];

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [getAllUsers] = useGetAllUsersMutation();
  const [getAgents] = useGetAgentsMutation();
  const [sendMailCreate] = useSendMailCreateMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [deleteAgent] = useDeleteAgentMutation();
  const [blockUser] = useBlockUserMutation();
  const [blockAgent] = useBlockAgentMutation();

  const agentsList = useSelector((state) => state.users.agents);

  const [agentId, setAgentId] = useState("");
  const [agents, setAgent] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedFilterInputs, setSelectedFilterInputs] = useState([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [status, setStatus] = useState(false);
  const [timer, setTimer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [loading, setLoading] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const initializePagination = () => {
    let currentPerPage = perPage;
    setPerPage(currentPerPage);
    setCurrentPage(1);
  };
  const handleChange = (e) => {
    setSearchInput(e.target.value);
    initializePagination();
  };
  const handleChangeSelect = (e) => {
    setPerPage(parseInt(e.target.value));
  };

  /**
   * @function resendFunction
   * @description this function resend mail to user
   *
   * @param {*} email
   *
   * @returns toast when success
   */
  const resendFunction = (email) => {
    sendMailCreate({ email }).then((res) => {
      if (res) {
        toastSucces(getTranslation(translation, "SUCCESS_MESSAGE"));
        setTimer(true);
      }
    });
  };

  /**
   * @function _blockUser
   * @description this function block user
   *
   * @param {*} id
   *
   * @returns
   */
  const _blockUser = (id) => {
    if (role === "ROLE_ADMIN_ALECSO") {
      blockUser(id).then(() => {
        getAllUsers().then((res) => {
          setOpen(false);
          setAgent(res.data.data);
          dispatch(GET_USERS({ agents: res.data.data }));
          setOpen(false);
          setTimer(true);
        });
      });
    } else {
      blockAgent(id).then(() => {
        getAgents().then((res) => {
          setOpen(false);
          setAgent(res.data.data);
          dispatch(GET_USERS({ agents: res.data.data }));
          setOpen(false);
          setTimer(true);
        });
      });
    }
  };

  /**
   *
   * @function _deleteUser
   * @description this function delete user
   *
   * @param {*} id
   *
   * @returns
   */
  const _deleteUser = (id) => {
    if (role === "ROLE_ADMIN_ALECSO") {
      deleteUser(id).then((res) => {
        if (res.data) {
          const filtered = agents.filter((agent) => agent.userId !== id);
          dispatch(GET_USERS({ agent: agents }));
          setAgent(filtered);
          setOpen(false);
          setTimer(true);
          toastSucces(getTranslation(translation, "SUCCESS_MESSAGE"));
        }

        if (res.error) {
          toastErrorServer(getTranslation(translation, "SERVER_ERROR"));
          setTimer(true);
        }
      });
    } else {
      deleteAgent(id).then((res) => {
        if (res.data) {
          const filtered = agents.filter((agent) => agent.userId !== id);
          dispatch(GET_USERS({ agents: agents }));
          setAgent(filtered);
          setOpen(false);
          setTimer(true);
          toastSucces(getTranslation(translation, "SUCCESS_MESSAGE"));
        }

        if (res.error) {
          toastErrorServer(getTranslation(translation, "SERVER_ERROR"));
          setTimer(true);
        }
      });
    }
  };

  const handleChangeSearch = (e) => {
    const array = [];
    e?.map((value) => {
      array.push(value.value);
      setSelectedFilterInputs(array);
    });

    if (e?.length == []) {
      array.push("");
      setSelectedFilterInputs([]);
    }
    setCurrentPage(1);
  };

  const openModel = (id, _value, _status) => {
    setOpen(!open);
    setAgentId(id);
    setValue(_value);
    if (_status === true) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  useEffect(() => {
    if (role === "ROLE_RESP_UNI") {
      if (!VerifyToken()) {
        logout();
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
      if (role === "ROLE_ADMIN_ALECSO") {
        getAllUsers().then((res) => {
          dispatch(GET_USERS({ agents: res.data.data }));
          if (searchInput === "" && selectedFilterInputs.length == 0) {
            setLoading(true);
            setAgent(res.data.data);
          }
        });
      } else {
        getAgents().then((res) => {
          dispatch(GET_USERS({ agents: res.data.data }));
          if (searchInput === "" && selectedFilterInputs.length == 0) {
            setLoading(true);
            setAgent(res.data.data);
          }
        });
      }
    } else {
      if (jwtDecode(token).exp * 1000 < Date.now()) {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        toast.error(getTranslation(translation, "TOKEN_EXPIRED"), {
          position: "top-left",
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
      getAllUsers().then((res) => {
        dispatch(GET_USERS({ agents: res.data.data }));
        if (searchInput === "" && selectedFilterInputs.length == 0) {
          setLoading(true);
          setAgent(res.data.data);
        }
      });
    }
  }, [loading]);

  useEffect(() => {
    handleChangeSearch();
    filterList(
      selectedFilterInputs,
      setAgent,
      agentsList,
      searchInput,
      setSearchInput
    );
  }, [searchInput, selectedFilterInputs]);

  return (
    <div className="flex flex-col justify-start ">
      <h1 className="p-4">
        {" "}
        {getTranslation(translation, "USER_TABLE_TITLE")}
      </h1>
      <div className="w-full gap-3 px-2 lg:flex lg:flex-row md:flex md:flex-row sm:flex sm:flex-col">
        <input
          type={"text"}
          className="h-10 transition ease-in-out delay-200 border rounded lg:w-1/4 md:w-1/4 focus:outline-blue-600 "
          placeholder={getTranslation(translation, "SEARCH_PLACEHOLDER")}
          value={searchInput}
          onChange={handleChange}
        />
        <Select
          options={options}
          className="lg:w-2/3 md:w-2/3"
          closeMenuOnSelect={false}
          placeholder={getTranslation(translation, "CHOOSE_PLACEHOLDER")}
          components={animatedComponents}
          onChange={handleChangeSearch}
          styles={{ height: "3rem", borderWidth: "2px" }}
          isMulti
        />
        {agents.length > 5 ? (
          <select
            className="h-10 border rounded lg:w-1/12 md:w-1/12 focus:outline-blue-600 focus:border-none"
            onChange={handleChangeSelect}
            value={perPage}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option defaultValue={30}>30</option>
            <option value={50}>50</option>
          </select>
        ) : null}
      </div>
      <div className="relative pt-10 overflow-x-auto  sm:rounded-lg h-[auto] ">
        {loading ? (
          <Table
            agents={agents}
            open={open}
            setOpen={setOpen}
            setAgentId={setAgentId}
            setAgent={setAgent}
            _blockUser={_blockUser}
            openModel={openModel}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            resendFunction={resendFunction}
            perPage={perPage}
          />
        ) : (
          <div className="h-[300px] flex items-center justify-center ">
            <Spinner />
          </div>
        )}

        {open ? (
          <ManageUserModal
            setOpen={setOpen}
            open={open}
            agentId={agentId}
            _deleteUser={_deleteUser}
            _blockUser={_blockUser}
            status={status}
            timer={timer}
            value={value}
          />
        ) : null}
      </div>
      <div className="flex justify-start">
        {agents?.length > 5 && perPage < agents?.length ? (
          <Pagination
            perPage={perPage}
            total={agents?.length}
            paginate={paginate}
          />
        ) : null}
      </div>
    </div>
  );
};

UsersListTable.propTypes = {
  agents: PropTypes.array,
  agentId: PropTypes.string,
  setAgentId: PropTypes.func,
  setAgent: PropTypes.func,
  setPerPage: PropTypes.func,
};
export default UsersListTable;
