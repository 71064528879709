import React from "react";
import PropTypes from "prop-types";
import { FileUploader } from "react-drag-drop-files";

import { getTranslation } from "../../utils/translate/translation";
import { useState } from "react";
import { useEffect } from "react";
import { Progress } from "@material-tailwind/react";
const fileTypes = ["XLSX", "XLS"];
const translate = {
  ar: {
    DROP_FILES_EXCEL: "أفلت ملف Excel في هذا المستطيل أو اضغط هنا",
  },
  en: {
    DROP_FILES_EXCEL: "Drop your Excel file in this rectangle or click here",
  },
  fr: {
    DROP_FILES_EXCEL:
      "Déposez votre fichier Excel dans ce rectangle ou cliquez ici",
  },
};

function FileInput({ onChange, OnClear, isUploaded, loading }) {
  const [count, setCount] = useState(0);
  const language = localStorage.getItem("language");
  useEffect(() => {
    setInterval(
      () => setCount((oldCount) => (oldCount === 100 ? 100 : oldCount + 1)),
      100
    );
  }, [loading]);
  const onClearInputFile = () => {
    OnClear();
    setCount(0);
  };
  const children = (
    <div
      className={`transition ease-in-out delay-150  flex items-center justify-center w-full p-4 rounded ${
        isUploaded ? "bg-[#0cbe6b] text-white" : "bg-[#f3f3f3] text-black "
      }`}
    >
      {isUploaded ? (
        <div className="flex w-full items-center justify-between gap-2">
          <div className="flex items-center justify-center w-[99%] h-2 bg-[#f1f0ef] rounded-md">
            <Progress value={100} size="sm" />
          </div>
          <p className="text-md font-bold text-[#25396f]">100%</p>
        </div>
      ) : (
        <>
          {loading ? (
            <div className="flex w-full items-center justify-between gap-2">
              <div className="flex items-center justify-center w-[99%] h-2 bg-[#f1f0ef] rounded-md">
                <Progress value={count} size="sm" />
              </div>
              <p className="text-md font-bold text-[#25396f]">{count}%</p>
            </div>
          ) : (
            <p className="text-[#4f4f4f]">
              {getTranslation(translate, "DROP_FILES_EXCEL")}
            </p>
          )}
        </>
      )}
    </div>
  );
  return (
    <div className="relative">
      <FileUploader
        handleChange={onChange}
        onSelect={onChange}
        types={fileTypes}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        classes="w-full"
      />
      {isUploaded && (
        <button
          onClick={onClearInputFile}
          className={`absolute ${
            language === "ar" ? "-left-1" : "-right-1"
          }  -top-2 flex items-center justify-center w-5 h-5 text-white bg-[#dc3545] rounded-full`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      )}
    </div>
  );
}
FileInput.propTypes = {
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  OnClear: PropTypes.func,
  isUploaded: PropTypes.bool,
};
export default FileInput;
