import React, { useState } from "react";
import AddStudentsTable from "./AddStudentsTable";
import AddStudentsUploader from "./AddStudentsUploader";
import ErrorBoundary from "../../utils/ErrorBoundary";

import MainButton from "../../components/button/MainButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DeleteStdentModal from "../../components/modal/Student/DeleteStudentModal";
import AddStudentBeforeErrorModal from "../../components/modal/Student/AddStudentBeforeErrorModal";
import AddStudentAfterErrorModal from "../../components/modal/Student/AddStudentAfterErrorModal";
import { ADD_FROM_CSV } from "../../store/features/studentsSlice/studentsSlice";
import { getTranslation } from "../../utils/translate/translation";

const translate = {
  ar: {
    PAGE_TITLE: "إضافة طالب",
    MENU_ONE: "إضافة جماعية",
    MENU_TWO: "إضافة فردية",
    MESSAGE_: "اضغط على الزر أسفله لتعمير استمارة إضافة طالب.",
    BUTTON_TITLE: "إضافة طالب",
  },
  en: {
    PAGE_TITLE: " Add a student",
    MENU_ONE: "Group addition",
    MENU_TWO: " Single addition",
    MESSAGE_: "Click the button below to fill in the student addition form.",
    BUTTON_TITLE: "Add a student",
  },
  fr: {
    PAGE_TITLE: " Ajouter un étudiant",
    MENU_ONE: "Ajout collectif",
    MENU_TWO: "Ajout  ",
    MESSAGE_:
      "Cliquez sur le bouton ci-dessous pour remplir le formulaire d'ajout d'étudiant.",
    BUTTON_TITLE: "Ajouter un étudiant",
  },
};
function AddStudentsPage() {
  const students = useSelector((state) => state.students);
  const [error, setError] = useState([]);
  const [key, setKey] = useState(0);
  const [errorAdd, setErrorAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(1);
  const [studentToDelete, setStudentToDelete] = useState({});

  const [errorTable] = useState([]);
  const [updateTable] = useState([]);

  let dispatch = useDispatch();
  let navigate = useNavigate();

  /**
   * @function deleteStudent
   * @description this function to open Model and  delete Student
   *
   * @return
   */
  const deleteStudent = () => {
    const studentsTable = [...students.studentsToAdd];
    studentsTable.splice(key, 1);
    dispatch(ADD_FROM_CSV({ studentsToAdd: studentsTable }));
    setOpenModal(!openModal);
  };

  return (
    <div className="flex flex-col justify-end mt-10 px-[3%] ">
      <h1 className="mb-2 text-2xl font-bold text-indigo-900">
        {getTranslation(translate, "PAGE_TITLE")}
      </h1>
      <div className="flex flex-col items-center justify-center w-full p-4 bg-white h-fit">
        <ErrorBoundary>
          <div className="flex w-full pt-8 pr-10">
            <div
              onClick={() => {
                setSelectedMenu(1);
              }}
              className={`cursor-pointer w-36 p-2 mb-2 text-blue-800 ${
                selectedMenu === 1 &&
                `border-b-2 border-b-blue-900 bg-[#f2f7ff]`
              }`}
            >
              {getTranslation(translate, "MENU_ONE")}
            </div>
            <div
              onClick={() => {
                setSelectedMenu(2);
              }}
              className={`cursor-pointer w-36 p-2 mb-2 text-blue-800 ${
                selectedMenu === 2 &&
                `border-b-2 border-b-blue-900 bg-[#f2f7ff]`
              }`}
            >
              {getTranslation(translate, "MENU_TWO")}
            </div>
          </div>
          {selectedMenu === 1 ? (
            <AddStudentsUploader setError={setError} setOpen={setOpen} />
          ) : (
            <div className="flex flex-col w-full p-4 rounded bg-[#f2f7ff] h-fit">
              <p>{getTranslation(translate, "MESSAGE_")}</p>
              <div className="w-40 py-4">
                <MainButton
                  title={getTranslation(translate, "BUTTON_TITLE")}
                  action={() => {
                    navigate("/add_one_student");
                  }}
                />
              </div>
            </div>
          )}
        </ErrorBoundary>
        {selectedMenu === 1 && (
          <ErrorBoundary>
            <div className="w-full ">
              <AddStudentsTable
                errorAdd={errorAdd}
                setErrorAdd={setErrorAdd}
                updateTable={updateTable}
                errorTable={errorTable}
                setOpenModal={setOpenModal}
                openModal={setOpenModal}
                setKey={setKey}
                setStudentToDelete={setStudentToDelete}
              />
            </div>
          </ErrorBoundary>
        )}
      </div>
      {open ? (
        <AddStudentBeforeErrorModal error={error} setOpen={setOpen} />
      ) : null}
      {openModal ? (
        <DeleteStdentModal
          setOpenModal={setOpenModal}
          openModal={openModal}
          deleteStudent={deleteStudent}
          studentToDelete={studentToDelete}
        />
      ) : null}
      {errorAdd ? (
        <AddStudentAfterErrorModal
          setErrorAdd={setErrorAdd}
          errorTable={errorTable}
          updateTable={updateTable}
        />
      ) : null}
    </div>
  );
}

export default AddStudentsPage;
