/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import { toastErrorServer, toastSucces } from "../../components/toast/Toast";
import {
  ADD_FILES,
  ADD_PDFS,
} from "../../store/features/diplomasSlice/diplomasSlice";
import { getTranslation } from "../../utils/translate/translation";

const translate = {
  ar: {
    TOAST_ERROR: " تأكد من عدد ملفات ",
    TOAST_SUCCES: "تمت العملية بنجاح !",
    MESSAGE_TOAST: "الملفات غير مطبقة",
    MESSAGE_:
      "    في مرحلة ثانية قم برفع عدّة ملفات 'PDF' مع تسمية كل ملف بالمعرّف الوطني للطالب حتى يتمّ ربطه مع بيانات الشهادات.",
    MESSAGE_ONE: "أفلت ملفات PDF في هذا المستطيل أو اضغط هنا",
  },
  en: {
    TOAST_ERROR: "Make sure the number of files is correct",
    TOAST_SUCCES: "Operation successful!",
    MESSAGE_TOAST: "The files are not identical",
    MESSAGE_:
      "In a second stage, upload several 'PDF' files, naming each file with the student's national ID so that it can be linked with the certificate data.",
    MESSAGE_ONE: " Drop PDFs into this rectangle or click here  ",
  },
  fr: {
    TOAST_ERROR: "Vérifiez le nombre de fichiers",
    TOAST_SUCCES: "Opération réussie !",
    MESSAGE_TOAST: "Les fichiers ne sont pas identique",
    MESSAGE_:
      " Dans un deuxième temps, téléchargez plusieurs fichiers 'PDF' en nommant chaque fichier avec la carte d'identité nationale de l'étudiant afin qu'il puisse être lié aux données du certificat.    ",
    MESSAGE_ONE: "  Déposez les PDF dans ce rectangle ou cliquez ici  ",
  },
};
function AddDiplomasPDFUploader() {
  let dispatch = useDispatch();
  const diploma = useSelector((state) => state.diploma);

  const [file, setFile] = useState(null);
  let filesName = [];
  const files = useSelector((state) => state.diploma.files);

  /**
   * @function handleChange
   * @description this function to verify pdf with metadata
   *
   * @return
   */
  const handleChange = async (file) => {
    if (file && typeof file[Symbol.iterator] === "function") {
      // Check if file is iterable
      for (const element of file) {
        let filename = {
          file: URL.createObjectURL(element),
          fileName: element.name.slice(0, element.name.length - 4),
          filename: element.name,
        };
        for (let i = 1; i < diploma?.diplomasToAdd?.length + 1; i++) {
          if (filename.fileName === diploma?.diplomasToAdd[i]?.nin) {
            filesName.push(filename);
          }
        }
      }
      if (filesName.length === 0) {
        toastErrorServer(`${getTranslation(translate, "MESSAGE_TOAST")}`);
      }
      const formData = new FormData();
      Array.from(file).forEach((diplome) => {
        return formData.append("PDFdiplomas", diplome);
      });
      let addedPdfs = [...files, ...filesName];
      dispatch(ADD_PDFS({ pdfs: formData }));
      dispatch(ADD_FILES({ files: addedPdfs }));
    }
  };
  /**
   * @function checkPdfLength
   * @description this function to check Pdf Length with metadata
   *
   * @return
   */
  const checkPdfLength = () => {
    if (files?.length > 0 && diploma?.diplomasToAdd?.length > 0) {
      if (files?.length !== diploma?.diplomasToAdd?.length - 1) {
        toastErrorServer(`${getTranslation(translate, "TOAST_ERROR")}`);
      } else {
        toastSucces(`${getTranslation(translate, "TOAST_SUCCES")}`);
      }
    }
  };

  useEffect(() => {
    checkPdfLength();
    handleChange();
  }, [file]);

  /**
   * @function removePdf
   * @description this function to remove all the pdf
   *
   * @return
   */
  const removePdf = () => {
    setFile(null);
    dispatch(ADD_FILES({ files: [] }));
  };

  const fileTypes = ["pdf"];
  return (
    <div className=" flex  flex-col items-center justify-end w-full p-4  rounded bg-[#f2f7ff]">
      <p className="w-full ">{getTranslation(translate, "MESSAGE_")}</p>
      {files?.length > 0 && (
        <>
          <div
            onClick={() => {
              removePdf();
            }}
            className="  flex items-center  justify-center h-5 w-5 top-1 left-1 text-white bg-red-500 rounded-full cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </>
      )}
      <div className="w-full h-auto overflow-auto rounded-md  ">
        <FileUploader
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          multiple
          label={getTranslation(translate, "MESSAGE_ONE")}
          classes="fileUploader"
          // eslint-disable-next-line react/no-children-prop
          children={
            <div
              className={
                "  flex items-center justify-center w-full p-4 bg-[#f1f0ef]   "
              }
            >
              {files?.length > 0 ? (
                <>
                  <div className="w-full bg-gray-200 rounded-md dark:bg-gray-500">
                    <div
                      className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                      style={{ width: "100%" }}
                    >
                      100%
                    </div>
                  </div>
                </>
              ) : (
                <p> {getTranslation(translate, "MESSAGE_ONE")}</p>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
}

export default AddDiplomasPDFUploader;
