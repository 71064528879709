import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { getTranslation } from "../../../../../utils/translate/translation";

import { toastSucces } from "../../../../toast/Toast";
import {
  GET_AGENTS,
  GET_DIPLOMAS,
} from "../../../../../store/features/diplomasSlice/diplomasSlice";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateDiplomaMutation } from "../../../../../store/service/diplomasService";

import { useEffect } from "react";
import Input from "../../../../input/Input";
const UpdateModeThree = ({
  translate,
  item,
  setShowUpdate,
  open,
  setOpen,
  setFiltered,
  filtered,
}) => {
  const setIsUniversityNameValid = () => {
    return true;
  };
  const handleCloseModalUpdate = () => {
    setOpen(!open);
    setShowUpdate(false);
  };
  const [firstName] = useState(item?.student?.firstName);
  const [mode] = useState(item?.mode);
  const [lastName] = useState(item?.student?.lastName);
  const [dateOfBirth] = useState(item?.student?.dateOfBirth);
  const [countryOfBirth] = useState(item?.student?.countryOfBirth);
  const [datePvExamen, setDatePvExamen] = useState("");
  const [datePvStages, setDatePvStages] = useState("");
  const [datePvMemoire, setDatePvMemoire] = useState("");

  const [specialityEn, setSpecialityEn] = useState("");
  const [specialityFr, setSpecialityFr] = useState("");
  const [specialityAr, setSpecialityAr] = useState("");
  const [typeEn, setTypeEn] = useState("");
  const [typeFr, setTypeFr] = useState("");
  const [typeAr, setTypeAr] = useState("");
  const [honorsEn, setHonorsEn] = useState("");
  const [honorsFr, setHonorsFr] = useState("");
  const [honorsAr, setHonorsAr] = useState("");
  const [diplomaNameEn, setDiplomaNameEn] = useState("");
  const [diplomaNameFr, setDiplomaNameFr] = useState("");
  const [diplomaNameAr, setDiplomaNameAr] = useState("");
  const [year, setYear] = useState("");
  const [average, setAverage] = useState(0);
  const [signatureDate, setSignatureDate] = useState("");
  const [approvalDate, setApprovalDate] = useState("");
  const [idDiploma, setIdDiploma] = useState(item._id);
  const [hashIpfs] = useState(item?.hashIpfs);
  useEffect(() => {
    setDatePvExamen(item?.examsPvDate ? item?.examsPvDate : "");
    setDatePvStages(item?.internshipPvDate ? item?.internshipPvDate : "");
    setDatePvMemoire(item?.paperPvDate ? item?.paperPvDate : "");

    setSpecialityEn(item?.speciality?.en ? item?.speciality?.en : "");
    setSpecialityFr(item?.speciality?.fr ? item?.speciality?.fr : "");
    setSpecialityAr(item?.speciality?.ar ? item?.speciality?.ar : "");
    setTypeEn(item?.type?.en ? item?.type?.en : "");
    setTypeFr(item?.type?.fr ? item?.type?.fr : "");
    setTypeAr(item?.type?.ar ? item?.type?.ar : "");
    setHonorsEn(item?.honors.en ? item?.honors.en : "");
    setHonorsFr(item?.honors.fr ? item?.honors.fr : "");
    setHonorsAr(item?.honors.ar ? item?.honors.ar : "");
    setDiplomaNameEn(item?.diplomaName?.en ? item?.diplomaName?.en : "");
    setDiplomaNameFr(item?.diplomaName?.fr ? item?.diplomaName?.fr : "");
    setDiplomaNameAr(item?.diplomaName?.ar ? item?.diplomaName?.ar : "");

    setYear(item?.year ? item?.year : "");
    setAverage(item?.average ? item?.average : "");
    setSignatureDate(item?.signatureDate ? item?.signatureDate : "");
    setApprovalDate(item?.approvalDate ? item?.approvalDate : "");
    setIdDiploma(item?._id ? item?._id : "");
  }, []);
  const countDiploma = useSelector((state) => state.diploma.countDiploma);
  const [updateDiploma] = useUpdateDiplomaMutation();
  const dispatch = useDispatch();
  const updateDiplomaRejected = () => {
    dispatch(GET_AGENTS({ countDiploma: countDiploma - 1 }));
    updateDiploma({
      diploma: {
        type: { en: typeEn, fr: typeFr, ar: typeAr },
        idDiploma: idDiploma,
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth,
        countryOfBirth: countryOfBirth,
        honors: { en: honorsEn, fr: honorsFr, ar: honorsAr },
        speciality: { en: specialityEn, fr: specialityFr, ar: specialityAr },
        average: average,
        diplomaName: {
          en: diplomaNameEn,
          fr: diplomaNameFr,
          ar: diplomaNameAr,
        },
        paperPvDate: datePvMemoire,
        internshipPvDate: datePvStages,
        examsPvDate: datePvExamen,
        year: year,
        signatureDate: signatureDate,
        approvalDate: approvalDate,
        hashIpfs: hashIpfs,
        status: "pending",
        mode: mode,
      },
    });

    handleCloseModalUpdate();
    const _filtered = filtered.filter((diploma) => diploma._id !== item._id);
    dispatch(GET_DIPLOMAS({ diplomas: _filtered }));
    setFiltered(_filtered);
    toastSucces(getTranslation(translate, "TOAST_SUCCESS"));
  };
  const setApprovalDateValid = () => {
    return true;
  };
  const setSignatureDateValid = () => {
    return true;
  };
  return (
    <div className="relative flex flex-col lg:w-[1300px] md:w-[auto]  h-fit bg-white border-0 rounded-lg outline-none focus:outline-none">
      <div className="bg-white rounded">
        <div className="  w-full p-2 lg:flex md:flex h-fit gap-5 ">
          <div className="flex flex-col  gap-2 w-1/2 ">
            <p className="font-bold text-blue-900">
              * {getTranslation(translate, "SPECIALITY")}
            </p>
            <div className="flex items-center justify-between w-full ">
              <p className=" text-blue-900">
                * {getTranslation(translate, "EN")}
              </p>
              <div className="w-2/3 h-10">
                <Input
                  type={"NO_CONTROL"}
                  placeholder={specialityEn}
                  inputChange={setSpecialityEn}
                  value={specialityEn}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-between w-full ">
              <p className=" text-blue-900">
                * {getTranslation(translate, "FR")}{" "}
              </p>
              <div className="w-2/3 h-10">
                <Input
                  type={"NO_CONTROL"}
                  placeholder={specialityFr}
                  inputChange={setSpecialityFr}
                  value={specialityFr}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-between w-full ">
              <p className=" text-blue-900">
                * {getTranslation(translate, "AR")}
              </p>
              <div className="w-2/3 h-10">
                <Input
                  type={"NO_CONTROL"}
                  placeholder={specialityAr}
                  inputChange={setSpecialityAr}
                  value={specialityAr}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col  gap-2 w-1/2 ">
            <p className=" font-bold text-blue-900">
              {getTranslation(translate, "TYPE")}
            </p>
            <div className="flex items-center justify-between w-full ">
              <p className=" text-blue-900">
                * {getTranslation(translate, "EN")}
              </p>
              <div className="w-2/3 h-10">
                <Input
                  type={"NO_CONTROL"}
                  placeholder={typeEn}
                  inputChange={setTypeEn}
                  value={typeEn}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-between w-full ">
              <p className=" text-blue-900">
                * {getTranslation(translate, "FR")}{" "}
              </p>
              <div className="w-2/3 h-10">
                <Input
                  type={"NO_CONTROL"}
                  placeholder={typeFr}
                  inputChange={setTypeFr}
                  value={typeFr}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-between w-full ">
              <p className=" text-blue-900">
                * {getTranslation(translate, "AR")}
              </p>
              <div className="w-2/3 h-10">
                <Input
                  type={"NO_CONTROL"}
                  placeholder={typeAr}
                  inputChange={setTypeAr}
                  value={typeAr}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="  w-full p-2 lg:flex md:flex h-fit gap-5 ">
          <div className="flex flex-col  gap-2 w-1/2 ">
            <p className="font-bold text-blue-900">
              {" "}
              {getTranslation(translate, "HONORS")}{" "}
            </p>
            <div className="flex items-center justify-between w-full ">
              <p className=" text-blue-900">
                * {getTranslation(translate, "EN")}{" "}
              </p>
              <div className="w-2/3 h-10">
                <Input
                  type={"NO_CONTROL"}
                  placeholder={honorsEn}
                  inputChange={setHonorsEn}
                  value={honorsEn}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-between w-full ">
              <p className=" text-blue-900">
                * {getTranslation(translate, "FR")}{" "}
              </p>
              <div className="w-2/3 h-10">
                <Input
                  type={"NO_CONTROL"}
                  placeholder={honorsFr}
                  inputChange={setHonorsFr}
                  value={honorsFr}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-between w-full ">
              <p className=" text-blue-900">
                * {getTranslation(translate, "AR")}{" "}
              </p>
              <div className="w-2/3 h-10">
                <Input
                  type={"NO_CONTROL"}
                  placeholder={honorsAr}
                  inputChange={setHonorsAr}
                  value={honorsAr}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col  gap-2 w-1/2 ">
            <p className="font-bold text-[#25396f]">
              {" "}
              {getTranslation(translate, "DIPLOMA_NAME")}{" "}
            </p>
            <div className="flex items-center justify-between w-full ">
              <p className=" text-blue-900">
                * {getTranslation(translate, "EN")}{" "}
              </p>
              <div className="w-2/3 h-10">
                <Input
                  type={"NO_CONTROL"}
                  placeholder={diplomaNameEn}
                  inputChange={setDiplomaNameEn}
                  value={diplomaNameEn}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-between w-full ">
              <p className=" text-blue-900">
                * {getTranslation(translate, "FR")}{" "}
              </p>
              <div className="w-2/3 h-10">
                <Input
                  type={"NO_CONTROL"}
                  placeholder={diplomaNameFr}
                  inputChange={setDiplomaNameFr}
                  value={diplomaNameFr}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
            <div className="flex items-center justify-between w-full ">
              <p className=" text-blue-900">
                * {getTranslation(translate, "AR")}{" "}
              </p>
              <div className="w-2/3 h-10">
                <Input
                  type={"NO_CONTROL"}
                  placeholder={diplomaNameAr}
                  inputChange={setDiplomaNameAr}
                  value={diplomaNameAr}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="  w-full p-2 lg:flex md:flex h-fit gap-5 ">
          <div className="flex flex-col  gap-2 w-1/2 ">
            <div className="flex items-center justify-between w-full ">
              <p className="font-bold text-blue-900">
                {getTranslation(translate, "YEAR")}
              </p>
              <div className="w-2/3 h-10">
                <Input
                  type={"NO_CONTROL"}
                  placeholder={year}
                  inputChange={setYear}
                  value={year}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col  gap-2 w-1/2 ">
            <div className="flex items-center justify-between w-full ">
              <p className="font-bold text-blue-900">
                {" "}
                {getTranslation(translate, "AVERAGE")}
              </p>
              <div className="w-2/3 h-10">
                <Input
                  type={"NO_CONTROL"}
                  placeholder={average.toString()}
                  inputChange={setAverage}
                  value={average}
                  setValid={setIsUniversityNameValid}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="  w-full p-2 lg:flex md:flex h-fit gap-5 ">
          <div className="flex flex-col  gap-2 w-1/2 ">
            <div className="flex items-center justify-between w-full ">
              <p className="font-bold text-blue-900">
                {getTranslation(translate, "APPROVAL_DATE")}
              </p>
              <div className="w-1/3 h-10">
                <Input
                  type={"DATE_FORMAT"}
                  placeholder={approvalDate}
                  inputChange={setApprovalDate}
                  value={approvalDate}
                  setValid={setApprovalDateValid}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col  gap-2 w-1/2 ">
            <div className="flex items-center justify-between w-full ">
              <p className="font-bold text-blue-900">
                {" "}
                {getTranslation(translate, "SIGNATURE_DATE")}{" "}
              </p>
              <div className="w-1/3 h-10">
                <Input
                  type={"DATE_FORMAT"}
                  placeholder={signatureDate}
                  inputChange={setSignatureDate}
                  value={signatureDate}
                  setValid={setSignatureDateValid}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="  w-full p-2 lg:flex md:flex h-fit gap-5 ">
          <div className="flex flex-col  gap-2 w-1/2 ">
            <div className="flex items-center justify-between w-full ">
              <p className="font-bold text-blue-900">
                {getTranslation(translate, "EXAMS_PV_DATE")}
              </p>
              <div className="w-1/3 h-10">
                <Input
                  type={"DATE_FORMAT"}
                  placeholder={datePvExamen}
                  inputChange={setDatePvExamen}
                  value={datePvExamen}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col  gap-2 w-1/2 ">
            <div className="flex items-center justify-between w-full ">
              <p className="font-bold text-blue-900">
                {getTranslation(translate, "INERNSHIP_PV_DATE")}
              </p>
              <div className="w-1/3 h-10">
                <Input
                  type={"DATE_FORMAT"}
                  placeholder={datePvStages}
                  inputChange={setDatePvStages}
                  value={datePvStages}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="  w-full p-2 lg:flex md:flex h-fit gap-5 ">
          <div className="flex flex-col  gap-2 w-1/2 ">
            <div className="flex items-center justify-between w-full ">
              <p className="font-bold text-blue-900">
                {getTranslation(translate, "PAPER_PV_DATE")}
              </p>
              <div className="w-1/3 h-10">
                <Input
                  type={"DATE_FORMAT"}
                  placeholder={datePvMemoire}
                  inputChange={setDatePvMemoire}
                  value={datePvMemoire}
                />
              </div>
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
      <div className="flex items-center gap-3 justify-end p-2 border-t border-solid rounded-b border-slate-200">
        <button
          className="bg-[#e6eaee] text-[#1a2026] flex gap-1 items-center hover:bg-[#e1e3e5]  leading-normal font-medium uppercase text-base p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
          type="button"
          onClick={() => handleCloseModalUpdate()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          {getTranslation(translate, "CLOSE")}
        </button>
        <button
          className="bg-[#198754] text-[white] flex gap-1 items-center leading-normal font-medium uppercase text-base p-2 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 cursor-not-allowed"
          type="button"
          onClick={() => {
            updateDiplomaRejected();
          }}
          disabled={
            typeAr === "" ||
            typeEn === "" ||
            typeFr === "" ||
            specialityAr === "" ||
            specialityEn === "" ||
            specialityFr === "" ||
            diplomaNameAr === "" ||
            diplomaNameEn === "" ||
            diplomaNameFr === "" ||
            honorsAr === "" ||
            honorsEn === "" ||
            honorsFr === "" ||
            year === "" ||
            datePvExamen === "" ||
            datePvMemoire === "" ||
            signatureDate === "" ||
            approvalDate === "" ||
            average === "" ||
            datePvStages === ""
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          {getTranslation(translate, "SAVE")}
        </button>
      </div>
    </div>
  );
};
UpdateModeThree.propTypes = {
  item: PropTypes.object,
  translate: PropTypes.object,
  setShowUpdate: PropTypes.func,
  showUpdate: PropTypes.bool,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  filtered: PropTypes.array,
  setFiltered: PropTypes.func,
};
export default UpdateModeThree;
