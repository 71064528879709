/* eslint-disable semi */
/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: false,
  role: null,
  email: null,
  university: null,
  numero: null,
  speciality: null,
  username: null,
  isAccepted: null,
  mode: [],
  modeSelected: null,
  logo: null,
  signature: null,
  language: null,
  responsableWallet: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    LOADING_USER: (state, action) => {
      state.accessToken = true;
      state.role = action.payload.role;
      state.username = action.payload.username;
      state.numero = action.payload.numero;
      state.email = action.payload.email;
      state.university = action.payload.university;
      state.speciality = action.payload.speciality;
      state.mode = action.payload.mode;
      state.isAccepted = action.payload.isAccepted;
      state.logo = action.payload.logo;
      state.signature = action.payload.signature;
      state.responsableWallet = action.payload.responsableWallet;
    },
    UPDATE_MODE: (state, action) => {
      state.mode = action.payload.mode;
    },
    CHANGE_LANGUAGE: (state, action) => {
      state.language = action.payload.language;
    },
  },
});

// Action creators are generated for each case reducer function
export const { LOADING_USER, UPDATE_MODE, CHANGE_LANGUAGE } = userSlice.actions;

export default userSlice.reducer;
