import React from "react";
import { getTranslation } from "../../../../utils/translate/translation";
import PropTypes from "prop-types";
const HeaderModal = ({ translate, item }) => {
  return (
    <div className="flex items-start justify-between p-2 bg-[#435ebe] border-slate-200 rounded-t-md ">
      <h3 className="text-lg font-semibold text-white">
        {getTranslation(translate, "DIPLOMA")}
      </h3>
      <h3 className="text-lg font-semibold text-white">
        {item.mode === "modeThree"
          ? `${getTranslation(translate, "DIPLOMA_DIGITAL")}`
          : item.mode === "modeTwo"
          ? `${getTranslation(translate, "MODE_TWO")}`
          : `${getTranslation(translate, "MODE_ONE")}`}
      </h3>
    </div>
  );
};
HeaderModal.propTypes = {
  item: PropTypes.object,
  translate: PropTypes.object,
};
export default HeaderModal;
