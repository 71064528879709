/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useDispatch } from "react-redux";
import { GET_DIPLOMAS } from "../../store/features/diplomasSlice/diplomasSlice";
import { DownArrow, UpArrow } from "../../assets/svg/Index";
import { getTranslation } from "../../utils/translate/translation";
import { multilanguageReader } from "../../utils/translate/multilanguage-reader";

const translate = {
  ar: {
    NAME: "الطالب(ة)",
    NIN: "   المعرف الوطني",
    TYPE: "نوع الشهادة	",
    SPECIALITY: "الاختصاص",
    HONORS: "الملاحظة",
    SIGNATURE_DATE: "تاريخ الإمضاء",
    DIPLOMA: "الشهادة",
    PROFILE: "حساب ",
  },
  en: {
    NAME: "Student",
    NIN: "ID",
    TYPE: " Type Diploma",
    SPECIALITY: "Speciality",
    HONORS: "Note",
    SIGNATURE_DATE: "Signature date",
    DIPLOMA: "Diploma",
    PROFILE: "Profile",
  },
  fr: {
    NAME: "Étudiant",
    NIN: "Identifiant",
    TYPE: "Type de Diplome",
    SPECIALITY: "Spécialité",
    HONORS: "Note",
    SIGNATURE_DATE: "Date de la signature",
    DIPLOMA: "Diplome",
    PROFILE: "Profil",
  },
};
const Table = ({
  filtered,
  getDiploma,
  handleOpen,
  setFiltered,
  currentPage,
  perPage,
}) => {
  const dispatch = useDispatch();
  const [nameClicked, setNameClicked] = useState(false);
  const [typeClicked, setTypeClicked] = useState(false);
  const [specialityClicked, setSpecialityClicked] = useState(false);
  const [dateClicked, setDateClicked] = useState(false);
  const getStudent = (id) => {
    window.open("/student/" + id, "_blank");
  };
  const sortByName = (field, status) => {
    return (a, b) => {
      if (a.student[field]?.en > b.student[field]?.en) {
        return status ? -1 : 1;
      }
      if (b.student[field]?.en > a.student[field]?.en) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };
  const sortBySpeciality = (field, status) => {
    return (a, b) => {
      if (a[field]?.en > b[field]?.en) {
        return status ? -1 : 1;
      }
      if (b[field]?.en > a[field]?.en) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };
  const sortByType = (field, status) => {
    return (a, b) => {
      if (a[field].en > b[field].en) {
        return status ? -1 : 1;
      }
      if (b[field].en > a[field].en) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };
  const onNameClick = () => {
    setNameClicked(!nameClicked);
    const sorted = [...filtered].sort(sortByName("firstName", nameClicked));
    setFiltered(sorted);
    dispatch(GET_DIPLOMAS({ diplomas: sorted }));
  };

  const onSpecialityClick = () => {
    setSpecialityClicked(!specialityClicked);
    const sorted = [...filtered].sort(
      sortBySpeciality("speciality", specialityClicked)
    );
    setFiltered(sorted);
    dispatch(GET_DIPLOMAS({ diplomas: sorted }));
  };

  const onDateClick = () => {
    setDateClicked(!dateClicked);
    if (dateClicked) {
      const sorted = [...filtered].sort((a, b) => {
        return moment(b.signatureDate).isBefore(moment(a.signatureDate))
          ? 1
          : -1;
      });
      setFiltered(sorted);
      dispatch(GET_DIPLOMAS({ diplomas: sorted }));
    } else {
      const sorted = [...filtered].sort((a, b) => {
        return moment(a.signatureDate).isBefore(moment(b.signatureDate))
          ? 1
          : -1;
      });
      setFiltered(sorted);
      dispatch(GET_DIPLOMAS({ diplomas: sorted }));
    }
  };

  const onTypeClick = () => {
    setTypeClicked(!typeClicked);
    const sorted = [...filtered].sort(sortByType("type", typeClicked));
    setFiltered(sorted);
    dispatch(GET_DIPLOMAS({ diplomas: sorted }));
  };
  const language = localStorage.getItem("language");
  return (
    <table
      className={
        language === "ar"
          ? "w-full text-sm text-right text-[#607080] dark:text-gray-400 "
          : "w-full text-sm text-left text-[#607080] dark:text-gray-400 "
      }
    >
      <thead className="text-xs font-bold text-[#607080] uppercase dark:bg-gray-700 dark:text-gray-400 border-b border-[#dedede]">
        <tr>
          <th scope="col" className="px-2 py-3 cursor-pointer">
            <div
              className="flex items-center justify-start"
              onClick={onNameClick}
            >
              <span> {getTranslation(translate, "NAME")}</span>
              {nameClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-2 py-3">
            {getTranslation(translate, "NIN")}
          </th>
          <th scope="col" className="px-2 py-3 cursor-pointer">
            <div
              className="flex items-center justify-start"
              onClick={onTypeClick}
            >
              <span> {getTranslation(translate, "TYPE")} </span>
              {typeClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-1 py-3 cursor-pointer">
            <div
              className="flex items-center justify-start"
              onClick={onSpecialityClick}
            >
              <span> {getTranslation(translate, "SPECIALITY")}</span>
              {specialityClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-2 py-3">
            {getTranslation(translate, "HONORS")}
          </th>
          <th scope="col" className="px-2 py-3 cursor-pointer">
            <div
              className="flex items-center justify-start"
              onClick={onDateClick}
            >
              <span> {getTranslation(translate, "SIGNATURE_DATE")} </span>
              {dateClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-2 py-3"></th>
        </tr>
      </thead>
      <tbody className="overflow-x-hidden h-10vh">
        {filtered
          ?.slice(currentPage * perPage - perPage, currentPage * perPage)
          .map((item, key) => (
            <tr
              key={key}
              className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                (key + 1) % 2 !== 0 ? `bg-[#f2f2f2]` : `bg-white`
              }`}
            >
              <td className="px-2 py-2">
                {multilanguageReader(item?.student?.firstName)}{" "}
                {multilanguageReader(item?.student?.lastName)}
              </td>
              <td className="px-2 py-2">{item?.student?.nin}</td>
              <td className="px-2 py-2">{multilanguageReader(item?.type)}</td>
              <td className="px-2 py-2">
                {multilanguageReader(item?.speciality)}
              </td>
              <td className="px-2 py-2">{multilanguageReader(item?.honors)}</td>
              <td className="px-2 py-2">
                {item?.signatureDate === "Invalid date"
                  ? null
                  : item?.signatureDate}
              </td>
              <td className="flex items-center justify-center px-2 py-2">
                {item?.hashIpfs ? (
                  <button
                    className="px-2 py-1 mx-1 text-xs flex items-center gap-1 font-bold text-white bg-[#198754] rounded w-fi h-fit"
                    onClick={() => {
                      getDiploma({ item });
                      handleOpen();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                      />
                    </svg>
                    {getTranslation(translate, "DIPLOMA")}
                  </button>
                ) : (
                  <button
                    className="px-2 py-1 mx-1 text-xs flex items-center gap-1 font-bold text-white bg-[#198754] rounded w-fi h-fit"
                    onClick={() => {
                      getDiploma({ item });
                      handleOpen();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                      />
                    </svg>

                    {getTranslation(translate, "DIPLOMA")}
                  </button>
                )}

                <button
                  className="px-2 py-1 mx-1 text-xs flex items-center gap-1 font-bold text-white bg-[#0dcaf0] rounded w-fi h-fit"
                  onClick={() => {
                    getStudent(item?.student?._id);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                    />
                  </svg>

                  {getTranslation(translate, "PROFILE")}
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
Table.propTypes = {
  filtered: PropTypes.array,
  getDiploma: PropTypes.func,
  handleOpen: PropTypes.func,
};
export default Table;
