/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  // eslint-disable-next-line no-unused-vars
  const [language, setLanguage] = useState(localStorage.getItem("language"));
  const navigate = useNavigate();
  useEffect(() => {
    // Function to handle the localStorage change event
    // const handleStorageChange = (event) => {
    //   // Check if the changed item is the one you're interested in
    //   if (event.key === "language") {
    //     // Handle the change here
    //     console.log('localStorage "language" changed:', event.newValue);
    //     // Perform any actions based on the new value of the "language" item
    //     // For example, update the state or trigger a component re-render
    //     // ...
    //     // navigate("/");
    //     window.location.reload(false);
    //   }
    // };
    // // Listen to the "storage" event
    // window.addEventListener("storage", handleStorageChange);
    // // Clean up the event listener when the component unmounts
    // return () => {
    //   window.removeEventListener("storage", handleStorageChange);
    // };
  }, []);
  const renderPageByLangugae = () => {
    if (language === "en") {
      return (
        <iframe
          src="/LandingPageEn.html"
          width="100%"
          height="100%"
          title="HTML Page"
          style={{ minHeight: "100vh" }}
        />
      );
    }
    if (language === "fr") {
      return (
        <iframe
          src="/LandingPageFR.html"
          width="100%"
          height="100%"
          title="HTML Page"
          style={{ minHeight: "100vh" }}
        />
      );
    }
    if (language === "ar") {
      return (
        <iframe
          src="/LandingPage.html"
          width="100%"
          height="100%"
          title="HTML Page"
          style={{ minHeight: "100vh" }}
        />
      );
    }
  };
  return <>{renderPageByLangugae()}</>;
}

export default LandingPage;
