/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import ModalLayout from "../../../../layouts/ModalLayout";
import { getTranslation } from "../../../../utils/translate/translation";
import { multilanguageReader } from "../../../../utils/translate/multilanguage-reader";

const translate = {
  ar: {
    DELETE: "حذف",
    CONFIRM_DELETE: " أنت تؤكد حذف شهادة ",
    YES: "نعم",
    NO: "لا",
  },
  en: {
    DELETE: "Delete",
    CONFIRM_DELETE: "You confirm deletion of a Diploma",
    YES: "Yes",
    NO: "No",
  },
  fr: {
    DELETE: "Supprimer",
    CONFIRM_DELETE: "Vous confirmez la suppression d'un Diplome",
    YES: "Oui",
    NO: "Non",
  },
};
const DeleteDiplomaModal = ({
  setOpenModal,
  openModal,
  deleteDiploma,
  DiplomaToDelete,
}) => {
  return (
    <div className="relative w-auto max-w-3xl mx-auto my-6">
      {/*content*/}
      <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
        {/*header*/}
        <div className="flex items-start justify-between p-3 bg-[#dc3545] border-b border-solid rounded-t border-slate-200">
          <h3 className="text-3xl flex items-center w-full justify-between font-semibold text-white">
            {getTranslation(translate, "DELETE")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-7 h-7"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </h3>
        </div>
        {/*body*/}
        <div className="relative flex-auto p-4">
          <h2 className="mb-5 text-2xl text-black">
            {getTranslation(translate, "CONFIRM_DELETE")}
            {multilanguageReader(
              DiplomaToDelete?.item?.student?.firstName
            )}{" "}
            {multilanguageReader(DiplomaToDelete?.item?.student?.lastName)}{" "}
            {DiplomaToDelete?.metadata?.firstName}{" "}
            {DiplomaToDelete?.metadata?.lastName}
          </h2>
        </div>
        {/*footer*/}
        <div className="flex items-center justify-end p-2 gap-2 border-t border-solid rounded-b border-slate-200">
          <button
            className="px-6 py-2 mb-1 mr-1 text-sm flex  justify-between w-full items-center  font-bold text-white uppercase transition-all duration-150 ease-linear bg-[#198754] rounded shadow outline-none active:bg-red-900 hover:shadow-lg focus:outline-none"
            onClick={() => {
              deleteDiploma();
            }}
          >
            {" "}
            {getTranslation(translate, "YES")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </button>
          <button
            onClick={() => setOpenModal(!openModal)}
            className="px-6 py-2 mb-1 mr-1 text-sm flex  justify-between w-full items-center  font-bold text-white uppercase transition-all duration-150 ease-linear bg-[#dc3545] rounded shadow outline-none active:bg-red-900 hover:shadow-lg focus:outline-none"
          >
            {getTranslation(translate, "NO")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
DeleteDiplomaModal.propTypes = {
  setOpenModal: PropTypes.func,
  openModal: PropTypes.bool,
  deleteDiploma: PropTypes.func,
  DiplomaToDelete: PropTypes.object,
};
export default DeleteDiplomaModal;
