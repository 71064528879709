import React, { useState } from "react";
import PropTypes from "prop-types";
import NavigationBar from "../coreUI/NavigationBar/NavigationBar";
import ProtectedRoute from "../utils/ProtectedRouters/ProtectedRouter";
import Header from "./Header";
import { menuIcon } from "../assets/svg/Index";

function Layout({ children }) {
  const [show, setShow] = useState(false);
  return (
    <ProtectedRoute>
      <div className="flex items-start justify-between w-full h-full ">
        <NavigationBar show={show} setShow={setShow} />

        <main className="w-full h-full min-h-screen bg-[#f2f7ff] p-[1rem] ">
          <div
            className="cursor-pointer   flex  items-center justify-jus w-full pr-[1%] lg:hidden"
            onClick={() => setShow(!show)}
          >
            {menuIcon}
          </div>
          <Header />
          {children}
        </main>
      </div>
    </ProtectedRoute>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
