import moment from "moment";
import { convertNumbers2English } from "../converts/convert";

function excelSerialNumberToDate(serialNumber) {
  if (serialNumber) {
    const dateObj = moment(serialNumber);
    const formattedDate = dateObj.format("DD/MM/YYYY");
    if (formattedDate !== "Invalid date") {
      return convertNumbers2English(formattedDate.toString());
    } else if (
      moment(convertNumbers2English(serialNumber.toString())).format(
        "DD-MM-YYYY"
      ) !== "Invalid date"
    ) {
      return serialNumber;
    } else {
      const SECONDS_IN_DAY = 86400;
      const MS_IN_DAY = SECONDS_IN_DAY * 1000;
      const EPOCH_DIFF = 25569;

      // Convert Excel serial number to Unix timestamp in milliseconds
      const unixTimestamp = (serialNumber - EPOCH_DIFF) * MS_IN_DAY;

      // Create a new Date object from the Unix timestamp
      const dateObj = new Date(unixTimestamp);

      // Format the date as a string in the desired format
      const year = dateObj.getFullYear();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      const dateString = `${day}/${month}/${year}`;
      return dateString;
    }
  } else {
    return "";
  }
}

export { excelSerialNumberToDate };
