import React from "react";
import PropTypes from "prop-types";
import imageSrc from "../assets/login.jpg";

function RegisterLayout({ children }) {
  return (
    <div className="lg:flex items-start justify-between sm:flex sm:justify-center  w-full bg-white min-h-screen">
      <main className="w-[75%]">{children}</main>
      <div className="hidden w-full lg:flex ">
        <img src={imageSrc} className="h-[100vh] w-full" alt="" />
      </div>
    </div>
  );
}

RegisterLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default RegisterLayout;
