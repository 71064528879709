import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  GET_DIPLOMAS,
  SET_SELECTED_DIPL,
} from "../../store/features/diplomasSlice/diplomasSlice";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();
import Table from "./Table";

import Pagination from "../../components/pagination/Pagination";
import { useNavigate } from "react-router";

import {
  useGetAcceptedDiplomaByAgentMutation,
  useGetAcceptedDiplomaByResponsableMutation,
} from "../../store/service/diplomasService";

import Spinner from "../../components/spinner/Spinner";
import VerifyToken from "../../utils/VerifyToken";
import { logout } from "../../utils/logout";
import { getTranslation } from "../../utils/translate/translation";
import { multilanguageReader } from "../../utils/translate/multilanguage-reader";
import {
  convertNumbers2English,
  convertSlash2Dash,
} from "../../utils/converts/convert";
import AcceptedDiplomaModalModeOne from "../../components/modal/Diploma/modeOne/AcceptedDiplomaModalModeOne";
import AcceptedDiplomaModalModeTwo from "../../components/modal/Diploma/modeTwo/AcceptedDiplomaModalModeTwo";
import AcceptedDiplomaModalModeThree from "../../components/modal/Diploma/modeThree/AcceptedDiplomaModalModeThree";
const translate = {
  ar: {
    NIN: "   المعرف الوطني",
    TYPE: "نوع الشهادة	",
    SPECIALITY: "الاختصاص",
    HONORS: "الملاحظة",
    SIGNATURE_DATE: "تاريخ الإمضاء",
    SEARCH: "ابحث  ...!",
    CHOOSE: "اختار ...!",
    MODE_ONE: "نمط الأول ",
    MODE_TWO: "نمط الثاني",
    MODE_THREE: "نمط الثالث",
  },
  en: {
    NIN: "ID",
    TYPE: " Type Diploma	",
    SPECIALITY: "Speciality",
    HONORS: "Note",
    SIGNATURE_DATE: "Signature date",
    SEARCH: "Search  ...!",
    CHOOSE: "Choose ...!",
    MODE_ONE: "Mode One",
    MODE_TWO: "Mode Two",
    MODE_THREE: "Mode Three",
  },
  fr: {
    NIN: "Identifiant",
    TYPE: "Type de Diplome",
    SPECIALITY: "Spécialité",
    HONORS: "Note",
    SIGNATURE_DATE: "Date de la signature",
    SEARCH: "Recherche  ...!",
    CHOOSE: "Choisir ...!",
    MODE_ONE: "premier mode",
    MODE_TWO: "Mode Deux",
    MODE_THREE: " Mode trois",
  },
};
const DiplomasTable = () => {
  const [getDiplomaAccepted] = useGetAcceptedDiplomaByResponsableMutation();
  const [getDiplomaAcceptedByAgent] = useGetAcceptedDiplomaByAgentMutation();
  const [filtered, setFiltered] = useState([]);

  const disptach = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState([]);
  const diplomas = useSelector((state) => state.diploma?.diplomas);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  useEffect(() => {
    if (role === "ROLE_RESP_UNI") {
      if (!VerifyToken()) {
        logout();
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }

      getDiplomaAccepted().then((res) => {
        disptach(GET_DIPLOMAS({ diplomas: res.data.data }));
        if (search === "" && searchBy.length == 0) {
          setFiltered(res.data.data);
          setLoading(true);
        }
      });
    } else {
      if (!VerifyToken()) {
        logout();
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }

      getDiplomaAcceptedByAgent().then((res) => {
        disptach(GET_DIPLOMAS({ diplomas: res.data.data }));
        if (search === "" && searchBy.length == 0) {
          setFiltered(res.data.data);
          setLoading(true);
        }
      });
    }
  }, [loading]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const searchby = () => {
    const _filtered = diplomas.filter(
      (diploma) =>
        (searchBy.includes("nin") &&
          diploma?.student.nin.includes(convertNumbers2English(search))) ||
        (searchBy.includes("type") && diploma?.type?.en.includes(search)) ||
        (searchBy.includes("signatureDate") &&
          diploma?.signatureDate?.includes(convertSlash2Dash(search))) ||
        (searchBy.includes("speciality") &&
          diploma?.speciality?.en
            ?.toLowerCase()
            .includes(search?.toLowerCase())) ||
        (searchBy.includes("honors") &&
          diploma?.honors?.en?.toLowerCase().includes(search?.toLowerCase()))
    );
    setFiltered(_filtered);
  };

  const filterage = () => {
    if (searchBy.length > 0) {
      for (let i = 0; i < searchBy.length; i++) {
        if (
          searchBy[i].includes("type") ||
          searchBy[i].includes("nin") ||
          searchBy[i].includes("speciality") ||
          searchBy[i].includes("honors") ||
          searchBy[i].includes("signatureDate")
        ) {
          searchby();
        }
      }
    } else if (setSearch != "") {
      const _filtered = diplomas.filter(
        (diploma) =>
          multilanguageReader(diploma?.student?.firstName)
            .toLowerCase()
            .includes(search?.toLowerCase()) ||
          multilanguageReader(diploma?.student?.lastName)
            .toLowerCase()
            .includes(search?.toLowerCase())
      );
      setFiltered(_filtered);
    }
  };

  const [filterMode, setFilterMode] = useState("");
  const modeFiltrage = () => {
    if (filterMode === "all") {
      setLoading(!loading);
    }
    const _filtered = diplomas.filter((diploma) =>
      diploma.mode.includes(filterMode)
    );
    setFiltered(_filtered);
  };

  const dispatch = useDispatch();
  const getDiploma = (diploma) => {
    dispatch(SET_SELECTED_DIPL({ selectedDipl: diploma }));
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const diploma = useSelector((state) => state.diploma?.selectedDipl);

  const item = diploma.item;

  useEffect(() => {
    filterage();
  }, [search, currentPage, searchBy]);

  const handleChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };
  const handleChangeMode = (e) => {
    setFilterMode(e.target.value);
  };
  useEffect(() => {
    modeFiltrage();
  }, [filterMode, currentPage]);
  const handleChangeSearch = (e) => {
    const array = [];
    e?.map((value) => {
      array.push(value.value);
      setSearchBy(array);
    });

    if (e?.length == []) {
      array.push("");
      setSearchBy([]);
    }
    setCurrentPage(1);
  };
  const handleChangeSelect = (e) => {
    setCurrentPage(1);
    setPerPage(e.target.value.toString());
    setLoading(!loading);
  };
  useEffect(() => {
    handleChangeSearch();
  }, [searchBy]);
  const options = [
    { value: "nin", label: `${getTranslation(translate, "NIN")}` },
    { value: "type", label: `${getTranslation(translate, "TYPE")}` },
    {
      value: "speciality",
      label: `${getTranslation(translate, "SPECIALITY")}`,
    },
    { value: "honors", label: `${getTranslation(translate, "HONORS")}` },
    {
      value: "signatureDate",
      label: `${getTranslation(translate, "SIGNATURE_DATE")}`,
    },
  ];
  return (
    <div>
      <div className="flex flex-col justify-start py-5  ">
        <div className="lg:flex lg:flex-row md:flex md:flex-row sm:flex sm:flex-col  w-full gap-3 px-2">
          <input
            type={"text"}
            className="lg:w-1/4 md:w-1/4 h-10 transition ease-in-out delay-200 border rounded focus:outline-blue-600 "
            placeholder={getTranslation(translate, "SEARCH")}
            value={search}
            onChange={handleChange}
          />
          <Select
            options={options}
            className="lg:w-2/3  md:w-2/3"
            closeMenuOnSelect={false}
            placeholder={getTranslation(translate, "CHOOSE")}
            components={animatedComponents}
            onChange={handleChangeSearch}
            styles={{ height: "3rem", borderWidth: "2px" }}
            isMulti
          />
          <select
            onChange={handleChangeMode}
            className="lg:w-1/12 md:w-1/12 h-10 border rounded focus:outline-blue-600 focus:border-none"
          >
            <option value="all">{getTranslation(translate, "CHOOSE")}</option>
            <option value="modeOne">
              {getTranslation(translate, "MODE_ONE")}
            </option>
            <option value="modeTwo">
              {getTranslation(translate, "MODE_TWO")}
            </option>
            <option value="modeThree">
              {" "}
              {getTranslation(translate, "MODE_THREE")}
            </option>
          </select>
          {filtered.length > 5 ? (
            <select
              className="lg:w-1/12 md:w-1/12 h-10 border rounded focus:outline-blue-600 focus:border-none"
              onChange={handleChangeSelect}
              type="number"
              value={perPage}
            >
              <option type="number" value={5}>
                5
              </option>
              <option type="number" value={10}>
                10
              </option>
              <option type="number" value={20}>
                20
              </option>
              <option type="number" defaultValue={30}>
                30
              </option>

              <option type="number" value={50}>
                50
              </option>
            </select>
          ) : null}
        </div>

        <div className="relative h-auto  overflow-x-auto sm:rounded-lg">
          {loading ? (
            <Table
              filtered={filtered}
              getDiploma={getDiploma}
              handleOpen={handleOpen}
              currentPage={currentPage}
              perPage={perPage}
              setFiltered={setFiltered}
            />
          ) : (
            <div className="h-[300px] flex items-center justify-center ">
              <Spinner />
            </div>
          )}

          <div className="flex justify-start">
            {filtered.length > 5 && perPage < filtered.length ? (
              <Pagination
                perPage={perPage}
                total={filtered.length}
                paginate={paginate}
              />
            ) : null}
          </div>
        </div>
      </div>

      {open && item.mode === "modeOne" && (
        <AcceptedDiplomaModalModeOne
          item={item}
          diploma={diploma.item}
          setOpen={setOpen}
          open={open}
          filtered={filtered}
          setFiltered={setFiltered}
          role={role}
        />
      )}
      {open && item.mode === "modeTwo" && (
        <AcceptedDiplomaModalModeTwo
          item={item}
          diploma={diploma.item}
          setOpen={setOpen}
          open={open}
          filtered={filtered}
          setFiltered={setFiltered}
          role={role}
        />
      )}
      {open && item.mode === "modeThree" && (
        <AcceptedDiplomaModalModeThree
          item={item}
          diploma={diploma.item}
          setOpen={setOpen}
          open={open}
          filtered={filtered}
          setFiltered={setFiltered}
          role={role}
        />
      )}
    </div>
  );
};
DiplomasTable.propTypes = {
  search: PropTypes.string,
  setCurrentPage: PropTypes.func,
  currentPage: PropTypes.number,
  filtered: PropTypes.array,
  handleChange: PropTypes.func,
  setFiltered: PropTypes.func,
  setSearch: PropTypes.func,
  setLoading: PropTypes.func,
  setPerPage: PropTypes.func,
  perPage: PropTypes.string,
};
export default DiplomasTable;
