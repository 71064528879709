import React, { useEffect, useState } from "react";
import logo from "../../assets/certified_logo.png";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import { useNavigate, useParams } from "react-router-dom";
import jwt from "jwt-decode";
import axios from "axios";
import {
  useRegisterAgentUniMutation,
  useRegisterRespUniMutation,
} from "../../store/service/usersService";

import {
  homeIcon,
  mailIcon,
  passwordIcon,
  userIcon,
} from "../../assets/svg/Index";
import { toastSucces } from "../../components/toast/Toast";
import { getTranslation } from "../../utils/translate/translation";

const translate = {
  ar: {
    TOAST_SUCCES: "تمت العملية بنجاح",
    PAGE_TITLE: "مستخدم جديد",
    MESSAGE_: "هذا الرابط صالح لـ48 ساعة من تاريخ إرساله",
    PASSWORD: "كلمة العبور",
    PASSWORD_CONFIRM: "تأكيد كلمة العبور",
    BUTTON_TITLE: "تسجيل",
    MESSAGE_ONE: " هذا الرابط غير صالح .",
  },
  en: {
    TOAST_SUCCES: "Operation accomplished successfully",
    PAGE_TITLE: "New user",
    MESSAGE_: "This link is valid for 48 hours from the date it was sent",
    PASSWORD: "Password",
    PASSWORD_CONFIRM: "Confirm Password",
    BUTTON_TITLE: "Registration",
    MESSAGE_ONE: "This link is not valid.",
  },
  fr: {
    TOAST_SUCCES: "Opération réussie",
    PAGE_TITLE: "Nouvel utilisateur",
    MESSAGE_: "Ce lien est valable 48 heures à compter de la date d'envoi",
    PASSWORD: "Mot de passe",
    PASSWORD_NEW_CONFIRM: "Confirmez le mot de passe",
    BUTTON_TITLE: "Inscription",
    MESSAGE_ONE: " Ce lien n'est pas valide.",
  },
};
function RegisterPage() {
  let { token } = useParams();
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [rePassword, setRePassword] = useState("");
  // eslint-disable-next-line no-unused-vars
  const setIsRePasswordValid = () => {
    return false;
  };
  const [isLoading, setIsLoading] = useState(false);
  const [register] = useRegisterRespUniMutation();
  const [registerAgent] = useRegisterAgentUniMutation();

  const registerResUniversitaire = () => {
    if (jwt(token).userRole !== "Agent") {
      setIsLoading(true);
      let data = {
        email,
        password,
      };
      register(data).then((res) => {
        if (res.data) {
          setIsLoading(false);
          navigate("/login");
          toastSucces(`${getTranslation(translate, "TOAST_SUCCES")}`);
        } else {
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(true);
      let data = {
        email,
        password,
      };
      registerAgent(data).then((res) => {
        if (res.data) {
          setIsLoading(false);
          navigate("/login");
          toastSucces(`${getTranslation(translate, "TOAST_SUCCES")}`);
        } else {
          setIsLoading(false);
        }
      });
    }
    localStorage.clear();
  };

  const getuser = async () => {
    let res = await axios({
      method: "GET",
      // eslint-disable-next-line no-undef
      url: process.env.REACT_APP_URL + "/api/user/getUserRegister",
      headers: {
        "x-access-token": `${token}`,
      },
    });
    if (res.data.data.status === "pending") {
      setRegister(true);
    }
  };
  const [resgister, setRegister] = useState(false);
  useEffect(() => {
    getuser();
  }, []);
  return (
    <>
      {resgister ? (
        <div className="flex flex-col items-start justify-start space-y-8 p-14">
          <img src={logo} alt="" className="mb-2" />
          <h1 className="text-5xl font-bold text-blue-800">
            {getTranslation(translate, "PAGE_TITLE")}
          </h1>
          <div>
            <p> {getTranslation(translate, "MESSAGE_")}</p>
          </div>
          <Input
            icon={homeIcon}
            placeholder={jwt(token).userToDecode?.universityName}
            disabled
            type={"DISABLED"}
          />
          <Input
            icon={userIcon}
            placeholder={jwt(token).userToDecode?.username}
            disabled
            type={"DISABLED"}
          />
          <Input
            icon={mailIcon}
            inputChange={setEmail}
            value={jwt(token).userToDecode?.email}
            disabled
            type={"DISABLED"}
          />
          <Input
            icon={passwordIcon}
            placeholder={getTranslation(translate, "PASSWORD")}
            password
            inputChange={setPassword}
            value={password}
            type="PASSWORD"
            setValid={setIsPasswordValid}
          />
          <Input
            icon={passwordIcon}
            placeholder={getTranslation(translate, "PASSWORD_NEW_CONFIRM")}
            password
            inputChange={setRePassword}
            value={rePassword}
            type="PASSWORD"
            setValid={setIsRePasswordValid}
          />
          <MainButton
            disabled={!isPasswordValid || password !== rePassword}
            title={getTranslation(translate, "BUTTON_TITLE")}
            action={registerResUniversitaire}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <>
          <div className="flex flex-col items-start justify-start space-y-8 p-14">
            <img src={logo} alt="" className="mb-2" />
            <h1 className="text-5xl font-bold text-blue-800">
              {getTranslation(translate, "PAGE_TITLE")}
            </h1>
            <div>
              <p> {getTranslation(translate, "MESSAGE_ONE")}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default RegisterPage;
