import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTranslation } from "../../utils/translate/translation";

import AgentDetails from "./AgentDetails";
import DiplomasTable from "./DiplomasTable";
const translate = {
  ar: {
    PAGE_TITLE: "طلبات إضافة شهادات بانتظار التأكيد",
    PAGE_TITLE_ONE: "عرض تفصيلي",
    PAGE_TITLE_TWO: " قائمة الشهادات",
  },
  en: {
    PAGE_TITLE: "Requests to add certificates are awaiting confirmation",
    PAGE_TITLE_ONE: "Detailed view ",
    PAGE_TITLE_TWO: "Diplomas list",
  },
  fr: {
    PAGE_TITLE:
      "Les demandes d'ajout de certificats sont en attente de confirmation",
    PAGE_TITLE_ONE: "Vue détaillée",
    PAGE_TITLE_TWO: " Liste des Diplomes",
  },
};
const DiplomasList = () => {
  const params = useParams();

  const agent = useSelector((state) => state.diploma.agent);
  const [filtered, setFiltered] = useState([]);
  return (
    <>
      <div>
        <div className="flex flex-col justify-end mt-10 px-[3%] ">
          <h1 className="mb-2 text-2xl font-bold text-[#25396f]">
            {getTranslation(translate, "PAGE_TITLE")}
          </h1>
          <div className="max-w-[auto] flex flex-col items-center justify-center w-full p-4 bg-white h-fit rounded-md">
            <div className="w-full">
              <div>
                <h5 className="px-6 font-bold text-[#607080] ">
                  {" "}
                  {getTranslation(translate, "PAGE_TITLE_ONE")}
                </h5>
                <AgentDetails agent={agent} setFiltered={setFiltered} />
              </div>
              <div>
                <h5 className="px-6 font-bold text-[#607080]  ">
                  {getTranslation(translate, "PAGE_TITLE_TWO")}
                </h5>
                <DiplomasTable
                  filtered={filtered}
                  setFiltered={setFiltered}
                  totalStudents={filtered.length}
                  agentId={params.id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiplomasList;
