/* eslint-disable react/prop-types */
import React from "react";

export default function LanguageInput({
  setSelectedLanguage,
  setShowMenu,
  selectedLanguage,
}) {
  const language = localStorage.getItem("language");
  return (
    <div
      className={`absolute  ${
        language === "ar" ? " right-2 " : "left-2"
      }   " top-11 z-20 w-40 h-auto rounded-md bg-white border-2 border-gray-100"`}
    >
      <div className="flex flex-col p-1">
        <span
          className="w-full h-full p-1 hover:bg-[#435ebe] hover:text-white cursor-pointer rounded"
          onClick={() => {
            setSelectedLanguage("en");
            setShowMenu(false);
          }}
        >
          <div className="flex w-full justify-between items-center">
            English{" "}
            {selectedLanguage === "en" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            )}
          </div>
        </span>
        <span
          className="w-full h-full p-1 hover:bg-[#435ebe] hover:text-white cursor-pointer rounded"
          onClick={() => {
            setSelectedLanguage("fr");
            setShowMenu(false);
          }}
        >
          <div className="flex w-full justify-between items-center">
            Français{" "}
            {selectedLanguage === "fr" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            )}
          </div>
        </span>
        <span
          className="w-full h-full p-1 hover:bg-[#435ebe] hover:text-white cursor-pointer rounded"
          onClick={() => {
            setSelectedLanguage("ar");
            setShowMenu(false);
          }}
        >
          <div className="flex w-full justify-between items-center">
            العربية{" "}
            {selectedLanguage === "ar" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            )}
          </div>
        </span>
      </div>
    </div>
  );
}
