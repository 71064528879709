/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { useState } from "react";
import MainButton from "../../../components/button/MainButton";
import Input from "../../../components/input/Input";
import SelectGender from "../../../components/input/SelectGender";

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useUpdateStudentMutation } from "../../../store/service/studentsService";
import { GET_STUDENTS } from "../../../store/features/studentsSlice/studentsSlice";
import { toastSucces } from "../../../components/toast/Toast";
import { useDispatch } from "react-redux";
import { getTranslation } from "../../../utils/translate/translation";
const UpdateForm = ({ translate, student, isLoading, setStudent }) => {
  const navigate = useNavigate();
  const [nameFr, setNameFr] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [lastNameEn, setLastNameEn] = useState("");
  const [lastNameFr, setLastNameFr] = useState("");
  const [lastNameAr, setLastNameAr] = useState("");

  const [isNameValid, setIsNameValid] = useState(false);

  const [isLastNameValid, setIsLastNameValid] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  const [nationaltyEn, setNationaltyEn] = useState("");
  const [nationaltyFr, setNationaltyFr] = useState("");
  const [nationaltyAr, setNationaltyAr] = useState("");
  const [isNationaltyValid, setIsNationaltyValid] = useState(false);
  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [isDateOfBirthValid, setIsDateOfBirthValid] = useState(false);
  const [nin, setNin] = useState(0);

  const [sexEn, setSexEn] = useState("");
  const [sexFr, setSexFr] = useState("");
  const [sexAr, setSexAr] = useState("");

  const [countryOfBirthEn, setCountryOfBirthEn] = useState("");
  const [countryOfBirthFr, setCountryOfBirthFr] = useState("");
  const [countryOfBirthAr, setCountryOfBirthAr] = useState("");
  const [isCountryOfBirthValid, setIsCountryOfBirthValid] = useState(false);
  const [updateStudent] = useUpdateStudentMutation();
  useEffect(() => {
    setNameEn(
      isLoading || student?.firstName?.en ? student?.firstName?.en : ""
    );
    setNameFr(
      isLoading || student?.firstName?.fr ? student?.firstName?.fr : ""
    );
    setNameAr(
      isLoading || student?.firstName?.ar ? student?.firstName?.ar : ""
    );
    setLastNameEn(
      isLoading || student?.lastName?.en ? student?.lastName?.en : ""
    );
    setLastNameFr(
      isLoading || student?.lastName?.fr ? student?.lastName?.fr : ""
    );
    setLastNameAr(
      isLoading || student?.lastName?.ar ? student?.lastName?.ar : ""
    );
    setCountryOfBirthEn(
      isLoading || student?.countryOfBirth?.en
        ? student?.countryOfBirth?.en
        : ""
    );
    setEmail(isLoading || student?.email ? student?.email : "");
    setSexEn(isLoading || student?.sex?.en ? student?.sex?.en : "");
    setSexFr(isLoading || student?.sex?.fr ? student?.sex?.fr : "");
    setSexAr(isLoading || student?.sex?.ar ? student?.sex?.ar : "");
    setCountryOfBirthAr(
      isLoading || student?.countryOfBirth?.ar
        ? student?.countryOfBirth?.ar
        : ""
    );
    setCountryOfBirthFr(
      isLoading || student?.countryOfBirth?.fr
        ? student?.countryOfBirth?.fr
        : ""
    );
    setNationaltyEn(
      isLoading || student?.nationalty?.en ? student?.nationalty?.en : ""
    );
    setNationaltyFr(
      isLoading || student?.nationalty?.fr ? student?.nationalty?.fr : ""
    );
    setNationaltyAr(
      isLoading || student?.nationalty?.ar ? student?.nationalty?.ar : ""
    );

    setPhone(isLoading || student?.phone ? student?.phone : "");
    setDateOfBirth(
      isLoading || student?.dateOfBirth ? student?.dateOfBirth : ""
    );

    setNin(isLoading || student?.nin ? student?.nin : "");
  }, [isLoading]);
  const dispatch = useDispatch();
  const update = () => {
    let data = {
      firstName: { en: nameEn, fr: nameFr, ar: nameAr },
      lastName: { en: lastNameEn, fr: lastNameFr, ar: lastNameAr },
      nin: nin,
      dateOfBirth: dateOfBirth,
      countryOfBirth: {
        en: countryOfBirthEn,
        fr: countryOfBirthFr,
        ar: countryOfBirthAr,
      },
      sex: { en: sexEn, fr: sexFr, ar: sexAr },
      nationalty: { en: nationaltyEn, fr: nationaltyFr, ar: nationaltyAr },
      email: email,
      phone: phone,
    };
    setStudent(data);
    updateStudent({
      student: {
        firstName: { en: nameEn, fr: nameFr, ar: nameAr },
        lastName: { en: lastNameEn, fr: lastNameFr, ar: lastNameAr },
        nin: nin,
        dateOfBirth: dateOfBirth,
        countryOfBirth: {
          en: countryOfBirthEn,
          fr: countryOfBirthFr,
          ar: countryOfBirthAr,
        },
        sex: { en: sexEn, fr: sexFr, ar: sexAr },
        nationalty: { en: nationaltyEn, fr: nationaltyFr, ar: nationaltyAr },
        email: email,
        phone: phone,
      },
    });

    dispatch(GET_STUDENTS({ students: [student] }));
    toastSucces(getTranslation(translate, "SUCCESS_MESSAGE"));
    navigate("/student");
  };
  return (
    <Fragment>
      <div className="items-center justify-center w-full gap-3 pt-8 lg:flex h-fit">
        <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
          <div className="flex-col items-start justify-center w-full lg:flex">
            <p className="text-xl font-semibold text-[#25396f]">
              {getTranslation(translate, "FIRST_NAME")}
            </p>
            <div className="flex justify-between w-full ">
              <p className="text-blue-900 ">
                {" "}
                {getTranslation(translate, "ENGLISH")}
              </p>
              <div className="w-3/4">
                <Input
                  type={"NAME"}
                  placeholder={nameEn}
                  inputChange={setNameEn}
                  value={nameEn ? nameEn : " "}
                  setValid={setIsNameValid}
                />
              </div>
            </div>
            <div className="flex justify-between w-full ">
              <p className="text-blue-900 ">
                {getTranslation(translate, "ARABIC")}
              </p>
              <div className="w-3/4">
                <Input
                  type={"NAME"}
                  placeholder={nameAr}
                  inputChange={setNameAr}
                  value={nameAr ? nameAr : " "}
                  setValid={setIsNameValid}
                />
              </div>
            </div>
            <div className="flex justify-between w-full ">
              <p className="text-blue-900 ">
                {" "}
                {getTranslation(translate, "FRENCH")}{" "}
              </p>
              <div className="w-3/4">
                <Input
                  type={"NAME"}
                  placeholder={nameFr}
                  inputChange={setNameFr}
                  value={nameFr ? nameFr : " "}
                  setValid={setIsNameValid}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
          <div className="flex-col items-start justify-center w-full lg:flex">
            <p className="text-xl font-semibold text-[#25396f]">
              {getTranslation(translate, "LAST_NAME")}
            </p>
            <div className="flex justify-between w-full ">
              <p className="text-blue-900 ">
                {" "}
                {getTranslation(translate, "ENGLISH")}
              </p>
              <div className="w-3/4">
                <Input
                  type={"NAME"}
                  placeholder={lastNameEn}
                  inputChange={setLastNameEn}
                  value={lastNameEn ? lastNameEn : " "}
                  setValid={setIsLastNameValid}
                />
              </div>
            </div>
            <div className="flex justify-between w-full ">
              <p className="text-blue-900 ">
                {getTranslation(translate, "ARABIC")}
              </p>
              <div className="w-3/4">
                <Input
                  type={"NAME"}
                  placeholder={lastNameAr}
                  inputChange={setLastNameAr}
                  value={lastNameAr ? lastNameAr : " "}
                  setValid={setIsLastNameValid}
                />
              </div>
            </div>
            <div className="flex justify-between w-full ">
              <p className="text-blue-900 ">
                {getTranslation(translate, "FRENCH")}{" "}
              </p>
              <div className="w-3/4">
                <Input
                  type={"NAME"}
                  placeholder={lastNameFr}
                  inputChange={setLastNameFr}
                  value={lastNameFr ? lastNameFr : " "}
                  setValid={setIsLastNameValid}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
          <div className="flex-col items-start justify-center w-full lg:flex">
            <p className="text-xl font-semibold text-[#25396f]">
              {" "}
              {getTranslation(translate, "PLACE_OF_BIRTH")}
            </p>
            <div className="flex justify-between w-full ">
              <p className="text-blue-900 ">
                {" "}
                {getTranslation(translate, "ENGLISH")}
              </p>
              <div className="w-3/4">
                <Input
                  type={"NAME"}
                  placeholder={countryOfBirthEn}
                  inputChange={setCountryOfBirthEn}
                  value={countryOfBirthEn ? countryOfBirthEn : " "}
                  setValid={setIsCountryOfBirthValid}
                />
              </div>
            </div>
            <div className="flex justify-between w-full ">
              <p className="text-blue-900 ">
                {getTranslation(translate, "ARABIC")}
              </p>
              <div className="w-3/4">
                <Input
                  type={"NAME"}
                  placeholder={countryOfBirthAr}
                  inputChange={setCountryOfBirthAr}
                  value={countryOfBirthAr ? countryOfBirthAr : " "}
                  setValid={setIsCountryOfBirthValid}
                />
              </div>
            </div>
            <div className="flex justify-between w-full ">
              <p className="text-blue-900 ">
                {getTranslation(translate, "FRENCH")}{" "}
              </p>
              <div className="w-3/4">
                <Input
                  type={"NAME"}
                  placeholder={countryOfBirthFr}
                  inputChange={setCountryOfBirthFr}
                  value={countryOfBirthFr ? countryOfBirthFr : " "}
                  setValid={setIsCountryOfBirthValid}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="items-center justify-center w-full gap-3 pt-8 lg:flex h-fit">
        <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
          <div className="flex-col items-start justify-center w-full lg:flex">
            <p className="text-xl font-semibold text-[#25396f]">
              {getTranslation(translate, "GENDER")}
            </p>
            <div className="flex justify-between w-full ">
              <p className="text-blue-900 ">
                {getTranslation(translate, "ENGLISH")}
              </p>
              <div className="w-3/4">
                <SelectGender
                  value={sexEn ? sexEn : " "}
                  setSex={setSexEn}
                  language="en"
                />
              </div>
            </div>
            <div className="flex justify-between w-full ">
              <p className="text-blue-900 ">
                {getTranslation(translate, "ARABIC")}
              </p>
              <div className="w-3/4">
                <SelectGender
                  value={sexAr ? sexAr : " "}
                  setSex={setSexAr}
                  language="ar"
                />
              </div>
            </div>
            <div className="flex justify-between w-full ">
              <p className="text-blue-900 ">
                {getTranslation(translate, "FRENCH")}{" "}
              </p>
              <div className="w-3/4">
                <SelectGender
                  value={sexFr ? sexFr : " "}
                  setSex={setSexFr}
                  language="fr"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
          <div className="flex-col items-start justify-center w-full lg:flex">
            <p className="text-xl font-semibold text-[#25396f]">
              {getTranslation(translate, "NATIONALITY")}
            </p>
            <div className="flex justify-between w-full ">
              <p className="text-blue-900 ">
                {getTranslation(translate, "ENGLISH")}
              </p>
              <div className="w-3/4">
                <Input
                  type={"NAME"}
                  placeholder={nationaltyEn}
                  inputChange={setNationaltyEn}
                  value={nationaltyEn ? nationaltyEn : " "}
                  setValid={setIsNationaltyValid}
                />
              </div>
            </div>
            <div className="flex justify-between w-full ">
              <p className="text-blue-900 ">
                {getTranslation(translate, "ARABIC")}
              </p>
              <div className="w-3/4">
                <Input
                  type={"NAME"}
                  placeholder={nationaltyAr}
                  inputChange={setNationaltyAr}
                  value={nationaltyAr ? nationaltyAr : " "}
                  setValid={setIsNationaltyValid}
                />
              </div>
            </div>
            <div className="flex justify-between w-full ">
              <p className="text-blue-900 ">
                {getTranslation(translate, "FRENCH")}{" "}
              </p>
              <div className="w-3/4">
                <Input
                  type={"NAME"}
                  placeholder={nationaltyFr}
                  inputChange={setNationaltyFr}
                  value={nationaltyFr ? nationaltyFr : " "}
                  setValid={setIsNationaltyValid}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
          <div className="flex-col items-start justify-center w-full lg:flex">
            <div className="flex justify-between w-full ">
              <p className="text-sm font-semibold text-blue-900 ">
                {getTranslation(translate, "PHONE")}
              </p>
              <div className="w-3/4">
                <Input
                  type={"NUMBER"}
                  placeholder={phone}
                  inputChange={setPhone}
                  value={phone}
                  setValid={setIsPhoneValid}
                />
              </div>
            </div>
            <div className="flex justify-between w-full ">
              <p className="text-sm font-semibold text-blue-900">
                {getTranslation(translate, "DATE_OF_BIRTH")}
              </p>
              <div className="w-3/4">
                <Input
                  type={"DATE_FORMAT"}
                  placeholder={dateOfBirth}
                  inputChange={setDateOfBirth}
                  value={dateOfBirth}
                  setValid={setIsDateOfBirthValid}
                />
              </div>
            </div>
            <div className="flex justify-between w-full ">
              <p className="text-sm font-semibold text-blue-900">
                {getTranslation(translate, "EMAIL")}
              </p>
              <div className="w-3/4">
                <Input
                  type={"EMAIL"}
                  placeholder={email}
                  inputChange={setEmail}
                  value={email}
                  setValid={setIsEmailValid}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="items-center justify-end w-full lg:flex">
        <div className="p-2 lg:flex lg:w-1/3 sm:w-full">
          <MainButton
            title={getTranslation(translate, "UPDATE_STUDENT")}
            action={() => {
              update();
            }}
          />
        </div>
        <div className="p-2 lg:w-40  sm:w-full">
          <button
            type="submit"
            onClick={() => {
              navigate("/student");
            }}
            className="bg-[#e6eaee] text-[#1a2026] w-full leading-normal font-normal uppercase text-base p-2 rounded-md shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
          >
            {getTranslation(translate, "BACK_TO_LIST")}
          </button>
        </div>
      </div>
    </Fragment>
  );
};
UpdateForm.propTypes = {
  translate: PropTypes.object,
  isLoading: PropTypes.bool,
  student: PropTypes.object,
  setStudent: PropTypes.func,
};
export default UpdateForm;
