/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import ModalLayout from "../../../../layouts/ModalLayout";
import { getTranslation } from "../../../../utils/translate/translation";

const translate = {
  ar: {
    MISTAKE: "خطأ",
    MESSAGE_0: "لم يقع احتساب المعرفات التالية لوجدها",
    NIN: "المعرف",
    MESSAGE_1: "في الأسطر التالية ليس هنالك طلبة بالمعرف المعطى",
    LINE: "السطر",
    COLUMNS: "الخانات",
    ERROR: "الخطأ",
    MESSAGE_2: "في الأسطر التالية ليس هنالك طلبة بالمعرف المعطى",
    MESSAGE_3: "يرجى التحقق من الأسطر",
    CLOSE: "أغلق النافذة",
  },
  en: {
    MISTAKE: "Mistake",
    MESSAGE_0: "The following identifiers are not counted alone",
    NIN: "Identifier",
    MESSAGE_1: "In the following lines there are no students with the given ID",
    LINE: "Line",
    COLUMNS: "Columns",
    ERROR: "Error",
    MESSAGE_2: "In the following lines there are no students with the given ID",
    MESSAGE_3: "Please check the lines",
    CLOSE: "Close",
  },
  fr: {
    MISTAKE: "Erreur",
    MESSAGE_: "Les identifiants suivants ne sont pas comptés seuls",
    NIN: "Identifiant",
    MESSAGE_1:
      "Dans les lignes suivantes, il n'y a pas d'étudiants avec l'ID donnés",
    LINE: "Ligne",
    COLUMNS: "Colonnes",
    ERROR: "Erreur",
    MESSAGE_2:
      "Dans les lignes suivantes, il n'y a pas d'étudiants avec l'ID donné",
    MESSAGE_3: "Veuillez vérifier les lignes",
    CLOSE: "Ferme",
  },
};
const AddDiplomaErrorModal = ({
  missingFields,
  setOpen,
  nonExistingStudents,
  alreadyExistingDiplomas,
  setAlreadyExistingDiplomas,
  setNonExistingStudents,
  setMissingFields,
  open,
}) => {
  const remove = () => {
    setMissingFields([]);
    setAlreadyExistingDiplomas([]);
    setNonExistingStudents([]);
    setOpen(!open);
  };

  return (
    <ModalLayout>
      <div className="relative w-[700px] mx-auto my-6 max-w-auto">
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-sm outline-none focus:outline-none">
          <div className="flex items-start justify-between p-3 bg-[#dc3545] border-b border-solid rounded-t border-slate-200">
            <h3 className="text-2xl font-semibold text-white">
              {getTranslation(translate, "MISTAKE")}
            </h3>
          </div>

          <div className="relative p-4 flex-auto max-h-[60vh] overflow-y-auto ">
            {alreadyExistingDiplomas?.length !== 0 && (
              <div className="pt-2 pb-3">
                <span className="pb-3 text-2xl font-bold">
                  {getTranslation(translate, "MESSAGE_0")}
                </span>

                <div className="grid justify-start pt-4 pb-3 text-right">
                  {alreadyExistingDiplomas?.map((e) => (
                    <div
                      key={e.ligne}
                      className="grid justify-start text-right"
                    >
                      <span className="font-semibold text-blue-900">
                        {getTranslation(translate, "NIN")} {e?.nin}
                      </span>
                    </div>
                  ))}
                </div>
                {alreadyExistingDiplomas?.length !== 0 &&
                  missingFields?.length !== 0 && (
                    <hr className="text-red-900" />
                  )}
              </div>
            )}
            {missingFields?.length !== 0 && (
              <div className="pt-3">
                <span className="pb-3 text-2xl font-bold">
                  {getTranslation(translate, "MESSAGE_1")}
                </span>

                <div className="grid justify-start pt-4 text-right">
                  {missingFields?.map((e) => (
                    <div
                      key={e.ligne}
                      className="grid justify-start text-right"
                    >
                      <span className="font-semibold text-blue-900">
                        {getTranslation(translate, "LINE")} : {e.ligne}
                      </span>
                      {e?.colonnes?.length > 0 ? (
                        <span className="font-semibold">
                          {getTranslation(translate, "COLUMNS")} : {e.colonnes}
                        </span>
                      ) : null}
                      {e?.err?.length > 0 ? (
                        <span className="font-semibold">
                          {getTranslation(translate, "ERROR")} : {e.err}
                        </span>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="grid justify-start text-right">
              {nonExistingStudents.length !== 0 && (
                <span className="font-bold">
                  {getTranslation(translate, "MESSAGE_2")}{" "}
                </span>
              )}

              {nonExistingStudents?.map((e) => (
                <div key={e.ligne} className="flex justify-start text-right ">
                  <span className="font-semibold text-blue-700 inline-block">
                    {getTranslation(translate, "LINE")} : {e.ligne}
                  </span>
                </div>
              ))}
            </div>
            <div className="flex justify-center pt-4">
              <span className="text-2xl">
                {getTranslation(translate, "MESSAGE_3")}
              </span>
            </div>
          </div>

          <div className="flex items-center justify-center p-2 border-t border-solid rounded-b border-slate-200">
            <button
              className="px-5 py-2 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-[#dc3545] rounded shadow outline-none active:bg-gray-900 hover:shadow-lg focus:outline-none"
              onClick={() => remove()}
            >
              {getTranslation(translate, "CLOSE")}
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
AddDiplomaErrorModal.propTypes = {
  setOpen: PropTypes.func,
  missingFields: PropTypes.array,
  nonExistingStudents: PropTypes.array,
  setMissingFields: PropTypes.func,
  alreadyExistingDiplomas: PropTypes.array,
  setAlreadyExistingDiplomas: PropTypes.func,
  open: PropTypes.bool,
  errorObj: PropTypes.array,
  setNonExistingStudents: PropTypes.func,
};
export default AddDiplomaErrorModal;
