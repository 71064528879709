/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useDispatch } from "react-redux";
import { GET_STUDENTS } from "../../store/features/studentsSlice/studentsSlice";
import { DownArrow, UpArrow } from "../../assets/svg/Index";
import { useNavigate } from "react-router-dom";
import { multilanguageReader } from "../../utils/translate/multilanguage-reader";
import { getTranslation } from "../../utils/translate/translation";
import { convertStringToNumber } from "../../utils/converts/convertStringToNumber";

const Table = ({
  filtered,
  role,
  handleOpen,
  setUserId,
  setDateOfBrith,
  perPage,
  currentPage,
  setId,
  setFiltered,
}) => {
  const translate = {
    ar: {
      FIRST_NAME: "الاسم الأول",
      LAST_NAME: "اسم العائلة",
      NATIONAL_ID: "المعرف الوطني",
      DATE_OF_BIRTH: "تاريخ الولادة",
      PLACE_OF_BIRTH: "مكان الولادة",
      GENDER: "الجنس",
      NATIONALITY: "الجنسية",
      PHONE: "الهاتف",
      EMAIL: "البريد الالكتروني",
      EDIT: "تعديل",
      ACCOUNT: "الحساب",
      DELETE: "حذف",
      NO_ENTRIES_FOUND: "لم يتم العثور على بيانات!",
    },
    fr: {
      FIRST_NAME: "Prénom",
      LAST_NAME: "Nom de famille",
      NATIONAL_ID: "Identité nationale",
      DATE_OF_BIRTH: "Date de naissance",
      PLACE_OF_BIRTH: "Lieu de naissance",
      GENDER: "Sexe",
      NATIONALITY: "Nationalité",
      PHONE: "Téléphone",
      EMAIL: "Adresse e-mail",
      EDIT: "Modifier",
      ACCOUNT: "Compte",
      DELETE: "Supprimer",
      NO_ENTRIES_FOUND: "Aucune entrée trouvée !",
    },
    en: {
      FIRST_NAME: "First Name",
      LAST_NAME: "Last Name",
      NATIONAL_ID: "National ID",
      DATE_OF_BIRTH: "Date of Birth",
      PLACE_OF_BIRTH: "Place of Birth",
      GENDER: "Gender",
      NATIONALITY: "Nationality",
      PHONE: "Phone",
      EMAIL: "Email",
      EDIT: "Edit",
      ACCOUNT: "Account",
      DELETE: "Delete",
      NO_ENTRIES_FOUND: "No entries found!",
    },
  };

  const dispatch = useDispatch();
  const [nomClicked, setNomClicked] = useState(false);
  const [prenomClicked, setPrenomClicked] = useState(false);
  const [birthDateClicked, setBirthDateClicked] = useState(false);
  const navigate = useNavigate();
  const getStudent = (id) => {
    window.open("/student/" + id, "_blank");
  };
  const sortByName = (field, status) => {
    return (a, b) => {
      if (multilanguageReader(a[field]) > multilanguageReader(b[field])) {
        return status ? -1 : 1;
      }
      if (multilanguageReader(b[field]) > multilanguageReader(a[field])) {
        return status ? 1 : -1;
      }
      return 0;
    };
  };
  const onNomCLick = () => {
    setNomClicked(!nomClicked);
    const sorted = [...filtered].sort(sortByName("firstName", nomClicked));
    setFiltered(sorted);
    dispatch(GET_STUDENTS({ students: sorted }));
  };
  const onPrenomCLick = () => {
    setPrenomClicked(!prenomClicked);
    const sorted = [...filtered].sort(sortByName("lastName", prenomClicked));
    setFiltered(sorted);
    dispatch(GET_STUDENTS({ students: sorted }));
  };
  const onDateOfBirthClick = () => {
    setBirthDateClicked(!birthDateClicked);
    if (birthDateClicked) {
      const sorted = [...filtered].sort((a, b) => {
        return moment(b.dateOfBirth).isBefore(moment(a.dateOfBirth)) ? 1 : -1;
      });
      setFiltered(sorted);
      dispatch(GET_STUDENTS({ students: sorted }));
    } else {
      const sorted = [...filtered].sort((a, b) => {
        return moment(a.dateOfBirth).isBefore(moment(b.dateOfBirth)) ? 1 : -1;
      });
      setFiltered(sorted);
      dispatch(GET_STUDENTS({ students: sorted }));
    }
  };

  const perpage = parseInt(perPage);
  const language = localStorage.getItem("language");
  return (
    <table
      className={
        language === "ar"
          ? "w-full text-sm text-right text-[#607080] dark:text-gray-400"
          : "w-full text-sm text-left text-[#607080] dark:text-gray-400"
      }
    >
      <thead className="text-xs text-[#607080] uppercase  dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th
            scope="col"
            className="flex items-center px-6 py-2 cursor-pointer "
            onClick={onNomCLick}
          >
            {getTranslation(translate, "FIRST_NAME")}
            {nomClicked ? (
              <div className="cursor-pointer ">
                <DownArrow />
              </div>
            ) : (
              <div className="cursor-pointer ">
                <UpArrow />
              </div>
            )}
          </th>
          <th
            scope="col"
            className="px-6 py-1 cursor-pointer"
            onClick={onPrenomCLick}
          >
            <div className="flex items-center ">
              <span> {getTranslation(translate, "LAST_NAME")} </span>
              {prenomClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>
          <th scope="col" className="px-6 py-2">
            {getTranslation(translate, "NATIONAL_ID")}
          </th>
          <th
            scope="col"
            className="px-6 py-2 cursor-pointer"
            onClick={onDateOfBirthClick}
          >
            <div className="flex items-center ">
              <span> {getTranslation(translate, "DATE_OF_BIRTH")}</span>
              {birthDateClicked ? (
                <div className="cursor-pointer ">
                  <DownArrow />
                </div>
              ) : (
                <div className="cursor-pointer ">
                  <UpArrow />
                </div>
              )}
            </div>
          </th>

          <th scope="col" className="px-6 py-1">
            {getTranslation(translate, "PLACE_OF_BIRTH")}
          </th>
          <th scope="col" className="px-6 py-2">
            {getTranslation(translate, "GENDER")}
          </th>
          <th scope="col" className="px-6 py-2">
            {getTranslation(translate, "NATIONALITY")}
          </th>
          <th scope="col" className="px-6 py-2">
            {getTranslation(translate, "PHONE")}
          </th>
          <th scope="col" className="px-6 py-2">
            {getTranslation(translate, "EMAIL")}
          </th>

          <th scope="col" className="px-4 py-2"></th>
        </tr>
      </thead>
      <tbody className="overflow-x-hidden h-10vh ">
        {filtered !== [] ? (
          filtered
            ?.slice(currentPage * perpage - perpage, currentPage * perpage)
            .map((student, key) => (
              <tr
                key={key}
                className={` border-b dark:bg-gray-900 dark:border-gray-700 ${
                  (key + 1) % 2 !== 0 ? `bg-[#f2f2f2]` : `bg-white`
                }`}
              >
                <td className="px-6 py-1 ">
                  {multilanguageReader(student?.firstName)}
                </td>
                <td className="px-6 py-1 ">
                  {multilanguageReader(student?.lastName)}
                </td>
                <td className="px-6 py-1 ">{student?.nin}</td>
                <td className="px-6 py-1 ">
                  {student?.dateOfBirth === "Invalid date"
                    ? null
                    : student?.dateOfBirth}
                </td>
                <td className="px-6 py-1 ">
                  {multilanguageReader(student?.countryOfBirth)}
                </td>
                <td className="px-6 py-1 ">
                  {multilanguageReader(student?.sex)}
                </td>
                <td className="px-6 py-1 ">
                  {multilanguageReader(student?.nationalty)}
                </td>
                <td className="px-6 py-1 ">
                  {" "}
                  {convertStringToNumber(student?.phone)}
                </td>
                <td className="px-6 py-1 ">{student?.email}</td>
                <td className="flex items-center justify-center px-6 py-2">
                  {role === "ROLE_RESP_UNI" && (
                    <button
                      onClick={() => {
                        navigate(`/updatestudent/${student?._id}`);
                      }}
                      className="px-2 py-1  mx-1 flex gap-1 items-center font-semibold text-white bg-[#435ebe] rounded text-md w-fi h-fit"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                      {getTranslation(translate, "EDIT")}
                    </button>
                  )}
                  <button
                    onClick={() => {
                      getStudent(student?._id);
                    }}
                    className="px-2 py-1 font-semibold flex gap-1 items-center text-white bg-[#0dcaf0] rounded text-md w-fi h-fit"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                      />
                    </svg>
                    {getTranslation(translate, "ACCOUNT")}
                  </button>

                  {role === "ROLE_RESP_UNI" && (
                    <>
                      <button
                        onClick={() => {
                          handleOpen();
                          setUserId(student?.nin);
                          setDateOfBrith(student?.dateOfBirth);
                          setId(student?._id);
                        }}
                        className="px-2 py-1  mx-1 flex gap-1 items-center font-semibold text-white bg-[#dc3545] rounded text-md w-fi h-fit"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                        {getTranslation(translate, "DELETE")}
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td>{getTranslation(translate, "NO_ENTRIES_FOUND")}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
Table.propTypes = {
  filtered: PropTypes.array,
  perPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  role: PropTypes.string,
  navigate: PropTypes.func,
  handleOpen: PropTypes.func,
  setUserId: PropTypes.func,
  setDateOfBrith: PropTypes.func,
  setFiltered: PropTypes.func,
  setId: PropTypes.func,
};
export default Table;
