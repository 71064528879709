/* eslint-disable react/prop-types */
import React, { useState } from "react";
import QRCode from "qrcode.react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { toast } from "react-toastify";
import Spinner from "../../components/spinner/Spinner";
import diploma_digital_preview from "../../assets/certified.png";
import { getTranslation } from "../../utils/translate/translation";
import {
  isThereCommunValues,
  multilanguageReader,
} from "../../utils/translate/multilanguage-reader";
import AcceptedDiplomaModalModeTwo from "../../components/modal/Diploma/modeTwo/AcceptedDiplomaModalModeTwo";
import AcceptedDiplomaModalModeOne from "../../components/modal/Diploma/modeOne/AcceptedDiplomaModalModeOne";
import AcceptedDiplomaModalModeThreeByStudent from "../../components/modal/Diploma/modeThree/AcceptedDiplomaModalModeThreeByStudent";

// eslint-disable-next-line no-unused-vars, react/prop-types
function Diploma(diploma) {
  const translate = {
    ar: {
      STUDENTS_LIST: "قائمة الطلبة",
      SPECIALTY: "الاختصاص:",
      ACADEMIC_YEAR: "السنة الجامعية:",
      NOTE: "الملاحظة:",
      AVERAGE_TOTAL: "المعدل / المجموع:",
      DIPLOMA_ID: "معرّف الشهادة",
      UNIVERSITY: "الجامعة:",
      VIEW: "اطّلاع",
      COPY_LINK: "نسخ الرابط",
      VERIFICATION_LINK: "رابط التحقق",
      DIPLOMA_ID_BTN: "معرف الشهادة",
      QR_CODE: "معرّف الشهادة",
      LINK_TO_VERIFICATION: "رابط التحقق",
      DOWNLOAD_QR_CODE: "تحميل رمز الاستجابة السريعة",
      DIPLOMA_INFO: "معلومات الشهادة",
      LINK_COPIED: "تم نسخ الرابط ",
    },
    en: {
      STUDENTS_LIST: "Students List",
      SPECIALTY: "Specialty:",
      ACADEMIC_YEAR: "Academic Year:",
      NOTE: "Note:",
      AVERAGE_TOTAL: "Average / Total:",
      DIPLOMA_ID: "Diploma ID",
      UNIVERSITY: "University",
      VIEW: "View",
      COPY_LINK: "Copy Link",
      VERIFICATION_LINK: "Verification Link",
      DIPLOMA_ID_BTN: "Diploma ID",
      QR_CODE: "QR Code",
      LINK_TO_VERIFICATION: "Verification Link",
      DOWNLOAD_QR_CODE: "Download QR Code",
      DIPLOMA_INFO: "Diploma Information",
      LINK_COPIED: "Link Copied",
    },
    fr: {
      STUDENTS_LIST: "Liste des étudiants",
      SPECIALTY: "Spécialité :",
      ACADEMIC_YEAR: "Année académique :",
      NOTE: "Remarque :",
      AVERAGE_TOTAL: "Moyenne / Total :",
      DIPLOMA_ID: "Identifiant du diplôme",
      UNIVERSITY: "Université :",
      VIEW: "Voir",
      COPY_LINK: "Copier le lien",
      VERIFICATION_LINK: "Lien de vérification",
      DIPLOMA_ID_BTN: "Identifiant du diplôme",
      QR_CODE: "Code QR",
      LINK_TO_VERIFICATION: "Lien de vérification",
      DOWNLOAD_QR_CODE: "Télécharger le code QR",
      DIPLOMA_INFO: "Informations sur le diplôme",
      LINK_COPIED: "Lien copié",
    },
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };
  const [
    linkToVerificationPageQrCodeClicked,
    setLinkToVerificationPageQrCodeClicked,
  ] = useState(false);
  const [QrCodeClicked, setQrCodeClicked] = useState(false);
  const onLinkVerificationClick = () => {
    setLinkToVerificationPageQrCodeClicked(
      !linkToVerificationPageQrCodeClicked
    );
    setQrCodeClicked(false);
  };
  const onQrCodeClicked = () => {
    setQrCodeClicked(!QrCodeClicked);
    setLinkToVerificationPageQrCodeClicked(false);
  };
  const copy = async () => {
    await navigator.clipboard.writeText(
      `https://saddakni.alecso.org/verification/${diploma?.diploma?._id}`
    );
    toast.info(getTranslation(translate, "LINK_COPIED"), {
      position: "top-left",
      theme: "colored",
      icon: false,
      autoClose: 1000,
    });
  };
  const downloadQRCode = (fileName) => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = fileName + `qr.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <>
      {diploma?.diploma?.status === "rejected" ||
      diploma?.diploma?.status === "pending-blockchain" ||
      diploma?.diploma?.status === "pending" ||
      diploma?.diploma?.status === "revoked" ||
      isThereCommunValues(
        Object.keys(diploma?.diploma?.type),
        Object.keys(diploma?.diploma?.student?.firstName)
      ) === false ? (
        <></>
      ) : (
        <>
          <div className="flex flex-col  bg-white h-full rounded-xl diplomaInfo lg:w-[auto] md:w-[auto] sm:w-fit">
            {diploma?.diploma?.hashIpfs ? (
              <Document
                file={
                  // eslint-disable-next-line no-undef
                  process.env.REACT_APP_URL +
                  "/ipfs/" +
                  diploma?.diploma?.hashIpfs
                }
                loading={
                  <div className="flex items-center justify-center w-full">
                    <Spinner />
                  </div>
                }
                className="flex items-center justify-center"
              >
                <Page pageNumber={1} />
              </Document>
            ) : null}
            {diploma?.diploma?.mode === "modeThree" ? (
              <img
                src={diploma_digital_preview}
                alt=""
                className="w-[450px] h-[250px] digital-diploma-prview rounded"
              />
            ) : null}
            {diploma?.diploma?.mode === "modeTwo" ? (
              <img
                src={diploma_digital_preview}
                alt=""
                className="w-[450px] h-[250px] digital-diploma-prview"
              />
            ) : null}
            <span className=" px-4 py-4 text-xl  font-bold text-[#25396f]">
              {multilanguageReader(diploma?.diploma?.speciality)?.toUpperCase()}
            </span>
            <div className="w-full pr-16 mb-4">
              <div className="flex w-full py-4  space-x-2 border-b border-b-[#dfdfdf] ">
                <div className="flex items-start text-sm font-bold ">
                  <span className="mr-4 text-[#607080]">
                    {" "}
                    {getTranslation(translate, "SPECIALITY")}
                  </span>
                  <span className="mr-2 text-[#607080] ">
                    {multilanguageReader(diploma?.diploma?.speciality)}
                  </span>
                </div>
              </div>
              <div className="flex w-full py-4 space-x-2 border-b border-b-[#dfdfdf] ">
                <div className="flex items-start text-sm font-bold ">
                  <span className="mr-4 text-[#607080]">
                    {getTranslation(translate, "ACADEMIC_YEAR")}
                  </span>
                  <span className="mr-2 text-[#607080] ">
                    {" "}
                    {diploma?.diploma?.year}
                  </span>
                </div>
              </div>
              <div className="flex w-full py-4 space-x-2 border-b border-b-[#dfdfdf] ">
                <div className="flex items-start text-sm font-bold ">
                  <span className="mr-4 text-[#607080]">
                    {" "}
                    {getTranslation(translate, "NOTE")}
                  </span>
                  <span className="mr-2 text-[#607080] ">
                    {" "}
                    {multilanguageReader(diploma?.diploma?.honors)}
                  </span>
                </div>
              </div>
              <div className="flex w-full py-4 space-x-2 border-b border-b-[#dfdfdf] ">
                <div className="flex items-start text-sm font-bold ">
                  <span className="mr-4 text-[#607080]">
                    {getTranslation(translate, "AVERAGE_TOTAL")}
                  </span>
                  <span className="mr-2 text-[#607080] ">
                    {" "}
                    {diploma.diploma?.average}
                  </span>
                </div>
              </div>
              {diploma?.diploma?.idDiploma ? (
                <div className="flex w-full py-4 space-x-2 border-b border-b-[#dfdfdf] ">
                  <div className="flex items-start text-sm font-bold ">
                    <span className="mr-4 text-[#607080]">
                      {" "}
                      {getTranslation(translate, "DIPLOMA_ID")}
                    </span>
                    <span className="mr-2 text-[#607080] ">
                      {`${diploma?.diploma?.idDiploma?.slice(
                        0,
                        8
                      )}...${diploma?.diploma?.idDiploma?.slice(-4)}`}
                    </span>
                  </div>
                </div>
              ) : null}
              <div className="flex w-full py-4 space-x-2 border-b border-b-[#dfdfdf] ">
                <div className="flex items-start text-sm font-bold ">
                  <span className="mr-4 text-[#607080]">
                    {getTranslation(translate, "UNIVERSITY")}
                  </span>
                  <span className="mr-2 text-[#607080] ">
                    {diploma?.diploma?.university?.name}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex w-full py-4 space-x-2 border-b-[#dfdfdf] ">
              <button
                onClick={() => {
                  handleOpen();
                }}
                className="h-10 p-2 mx-2 text-sm  text-white bg-[#198754] rounded-md w-fit"
              >
                {getTranslation(translate, "VIEW")}
              </button>
              <button
                className="h-10 p-2 mx-2 text-sm  text-white bg-[#0dcaf0] rounded-md w-fit"
                onClick={() => copy()}
              >
                {getTranslation(translate, "COPY_LINK")}
              </button>

              {diploma?.diploma?.hashTx ? (
                <button
                  onClick={onLinkVerificationClick}
                  className="h-10 p-2 mx-2 text-sm text-white bg-red-300 rounded-md w-fit"
                >
                  {getTranslation(translate, "VERIFICATION_LINK")}
                </button>
              ) : null}

              {diploma?.diploma?.hashTx ? (
                <button
                  onClick={onQrCodeClicked}
                  className="h-10 p-2 mx-2 text-sm text-white bg-[#9694ff] rounded-md w-fit"
                >
                  {getTranslation(translate, "DIPLOMA_ID")}
                </button>
              ) : null}
            </div>

            {linkToVerificationPageQrCodeClicked && (
              <>
                <div className="items-center justify-center w-full h-fit">
                  <QRCode
                    id="qr-gen"
                    value={
                      diploma?.diploma?.mode === "modeThree"
                        ? `https://saddakni.alecso.org/digital_diploma/${diploma?.diploma?._id}`
                        : `https://saddakni.alecso.org/verification/${diploma?.diploma?._id}`
                    }
                    size={256}
                    level={"H"}
                    includeMargin={true}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  />
                </div>
                {diploma?.diploma?.mode === "modeThree" ? (
                  <a
                    href={`https://saddakni.alecso.org/digital_diploma/${diploma?.diploma?._id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {getTranslation(translate, "VERIFICATION_LINK")}
                  </a>
                ) : (
                  <a
                    href={`https://saddakni.alecso.org/verification/${diploma?.diploma?._id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {getTranslation(translate, "VERIFICATION_LINK")}
                  </a>
                )}
                <div className="pb-2">
                  <button
                    className="h-10 p-2 mx-2 text-sm text-white bg-green-300 rounded-md w-fit"
                    onClick={() => {
                      downloadQRCode(diploma?.diploma?.hashIpfs);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>
                  </button>
                </div>
              </>
            )}
            {QrCodeClicked && (
              <>
                <span className="p-2 text-blue-900 text-md">
                  {getTranslation(translate, "DIPLOMA_ID")}
                  <span className="font-bold"> {diploma?.diploma?._id}</span>
                </span>
              </>
            )}
            {open && diploma?.diploma?.mode === "modeOne" && (
              <AcceptedDiplomaModalModeOne
                item={diploma?.diploma}
                diploma={diploma.item}
                setOpen={setOpen}
                open={open}
              />
            )}
            {open && diploma?.diploma?.mode === "modeTwo" && (
              <AcceptedDiplomaModalModeTwo
                item={diploma?.diploma}
                diploma={diploma.item}
                setOpen={setOpen}
                open={open}
              />
            )}

            {open && diploma?.diploma?.mode === "modeThree" && (
              <div className="w-full p-2">
                <AcceptedDiplomaModalModeThreeByStudent
                  item={diploma?.diploma}
                  diploma={diploma.item}
                  setOpen={setOpen}
                  open={open}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Diploma;
