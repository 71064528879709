/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  GET_AGENTS,
  GET_DIPLOMAS,
  SET_SELECTED_DIPL,
} from "../../store/features/diplomasSlice/diplomasSlice";

import {
  useGetDiplomasMutation,
  useUpdateDiplomasMutation,
} from "../../store/service/diplomasService";

import { Table } from "./Table";
import { useNavigate } from "react-router";

import Pagination from "../../components/pagination/Pagination";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { toastErrorServer, toastSucces } from "../../components/toast/Toast";
import VerifyToken from "../../utils/VerifyToken";
import { logout } from "../../utils/logout";

import { getTranslation } from "../../utils/translate/translation";
import { multilanguageReader } from "../../utils/translate/multilanguage-reader";
import {
  convertNumbers2English,
  convertSlash2Dash,
} from "../../utils/converts/convert";
import PendingDiplomaModalModeOne from "../../components/modal/Diploma/modeOne/PendingDiplomaModalModeOne";
import PendingDiplomaModalModeTwo from "../../components/modal/Diploma/modeTwo/PendingDiplomaModalModeTwo";
import PendingDiplomaModalModeThree from "../../components/modal/Diploma/modeThree/PendingDiplomaModalModeThree";

const translate = {
  ar: {
    TOAST_SUCCES: "تمت العملية بنجاح !",
    TOAST_ERROR: "هنالك خطأ من السرفر",
    NIN: "   المعرف الوطني",
    TYPE: "نوع الشهادة	",
    SPECIALITY: "الاختصاص",
    HONORS: "الملاحظة",
    SIGNATURE_DATE: "تاريخ الإمضاء",
    SEARCH: "ابحث  ...!",
    CHOOSE: "اختار ...!",
  },
  en: {
    TOAST_SUCCES: "Operation accomplished successfully",
    TOAST_ERROR: "There is an error from the server",
    NIN: "ID",
    TYPE: " Type Diploma	",
    SPECIALITY: "Speciality",
    HONORS: "Note",
    SIGNATURE_DATE: "Signature date",
    SEARCH: "Search  ...!",
    CHOOSE: "Choose ...!",
  },
  fr: {
    TOAST_SUCCES: "Opération réussie",
    TOAST_ERROR: "Il y a une erreur du serveur",
    NIN: "Identifiant",
    TYPE: "Type de Diplome",
    SPECIALITY: "Spécialité",
    HONORS: "Note",
    SIGNATURE_DATE: "Date de la signature",
    SEARCH: "Recherche  ...!",
    CHOOSE: "Choisir ...!",
  },
};

export default function DiplomasTable({ filtered, setFiltered, agentId }) {
  const [isloading, setLoading] = useState(false);
  const navigate = useNavigate();
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState([]);
  const [getDiplomas] = useGetDiplomasMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!VerifyToken()) {
      logout();
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      getDiplomas(agentId).then((res) => {
        disptach(GET_DIPLOMAS({ diplomas: res.data.data }));
        if (search === "" && searchBy.length == 0) {
          setFiltered(res.data.data);
          setIsLoading(true);
        }
      });
    }
  }, [isloading]);

  const animatedComponents = makeAnimated();

  const diplomas = useSelector((state) => state.diploma.diplomas);

  const [updateDiplomas] = useUpdateDiplomasMutation();
  const disptach = useDispatch();
  const getDiploma = (item) => {
    disptach(SET_SELECTED_DIPL({ selectedDipl: item }));
  };

  const agentIsActive = useSelector((state) => state.diploma.agent);
  const countDiploma = useSelector((state) => state.diploma.countDiploma);
  const updateDiplomaRejectOrAccept = (item) => {
    disptach(SET_SELECTED_DIPL({ selectedDipl: item }));
    updateDiplomas({
      decision: "rejected",
      diplomas: [item.item],
      createdBy: agentIsActive.createdBy,
    }).then((res) => {
      if (!res.error) {
        disptach(
          GET_AGENTS({
            agent: agentIsActive,
            countDiploma: countDiploma - 1,
          })
        );
        filter(item.item);
        setOpen(false);
        toastSucces(`${getTranslation(translate, "TOAST_SUCCES")}`);
      } else {
        toastErrorServer(`${getTranslation(translate, "TOAST_ERROR")}`);
      }
    });
  };

  const filter = (diploma) => {
    const filtered = diplomas.filter((item) => item !== diploma);
    disptach(GET_DIPLOMAS({ diplomas: filtered }));

    setFiltered(filtered);
  };
  const [open, setOpen] = useState(false);

  const [showUpdate, setShowUpdate] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
    if (showUpdate === true) {
      setShowUpdate(false);
    }
  };
  const searchby = () => {
    const _filtered = diplomas.filter(
      (diploma) =>
        (searchBy.includes("nin") &&
          diploma.student.nin.includes(convertNumbers2English(search))) ||
        (searchBy.includes("type") && diploma.type?.en.includes(search)) ||
        (searchBy.includes("signatureDate") &&
          diploma.signatureDate.includes(convertSlash2Dash(search))) ||
        (searchBy.includes("speciality") &&
          diploma.speciality?.en
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (searchBy.includes("honors") &&
          diploma.honors?.en.toLowerCase().includes(search.toLowerCase()))
    );
    setFiltered(_filtered);
  };
  const filterage = () => {
    if (searchBy.length > 0) {
      for (let i = 0; i < searchBy.length; i++) {
        if (
          searchBy[i].includes("type") ||
          searchBy[i].includes("nin") ||
          searchBy[i].includes("speciality") ||
          searchBy[i].includes("honors") ||
          searchBy[i].includes("signatureDate")
        ) {
          searchby();
        }
      }
    } else if (setSearch != "") {
      const _filtered = diplomas.filter(
        (diploma) =>
          multilanguageReader(diploma?.student?.firstName)
            .toLowerCase()
            .includes(search?.toLowerCase()) ||
          multilanguageReader(diploma?.student?.lastName)
            .toLowerCase()
            .includes(search?.toLowerCase())
      );
      setFiltered(_filtered);
    }
  };

  useEffect(() => {
    filterage();
  }, [search, currentPage, searchBy]);

  const handleChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleChangeSearch = (e) => {
    const array = [];
    e?.map((value) => {
      array.push(value.value);
      setSearchBy(array);
    });

    if (e?.length == []) {
      array.push("");
      setSearchBy([]);
    }
    setCurrentPage(1);
  };
  const handleChangeSelect = (e) => {
    setCurrentPage(1);
    setPerPage(e.target.value.toString());

    setIsLoading(!isLoading);
  };
  useEffect(() => {
    handleChangeSearch();
  }, [searchBy, isLoading]);

  const diploma = useSelector((state) => state.diploma.selectedDipl);

  const item = diploma.item;
  const role = localStorage.getItem("role");
  useEffect(() => {
    filterage();
  }, [search, currentPage]);
  const options = [
    { value: "nin", label: `${getTranslation(translate, "NIN")}` },
    { value: "type", label: `${getTranslation(translate, "TYPE")}` },
    {
      value: "speciality",
      label: `${getTranslation(translate, "SPECIALITY")}`,
    },
    { value: "honors", label: `${getTranslation(translate, "HONORS")}` },
    {
      value: "signatureDate",
      label: `${getTranslation(translate, "SIGNATURE_DATE")}`,
    },
  ];
  return (
    <>
      <div className="flex flex-col justify-start py-5 ">
        <div className="lg:flex lg:flex-row md:flex md:flex-row sm:flex sm:flex-col  w-full gap-3 px-7">
          <input
            type={"text"}
            className="lg:w-1/4 md:w-1/4  h-10 transition ease-in-out delay-200 border rounded focus:outline-blue-600 "
            placeholder={getTranslation(translate, "SEARCH")}
            value={search}
            onChange={handleChange}
          />
          <Select
            options={options}
            className="lg:w-2/3 md:w-2/3"
            closeMenuOnSelect={false}
            placeholder={getTranslation(translate, "CHOOSE")}
            components={animatedComponents}
            onChange={handleChangeSearch}
            styles={{ height: "3rem", borderWidth: "2px" }}
            isMulti
          />
          {filtered.length > 5 ? (
            <select
              className="lg:w-1/12 md:w-1/12 h-10 border rounded focus:outline-blue-600 focus:border-none"
              onChange={handleChangeSelect}
              type="number"
              value={perPage}
            >
              <option type="number" value={5}>
                5
              </option>
              <option type="number" value={10}>
                10
              </option>
              <option type="number" value={20}>
                20
              </option>
              <option type="number" defaultValue={30}>
                30
              </option>

              <option type="number" value={50}>
                50
              </option>
            </select>
          ) : null}
        </div>
        <div className=" h-auto p-4 overflow-x-auto sm:rounded-lg">
          <Table
            filtered={filtered}
            getDiploma={getDiploma}
            handleOpen={handleOpen}
            updateDiplomaRejectOrAccept={updateDiplomaRejectOrAccept}
            isLoading={isLoading}
            setFiltered={setFiltered}
            currentPage={currentPage}
            perPage={perPage}
          />

          <div className="flex justify-start">
            {filtered.length > 5 && perPage < filtered.length ? (
              <Pagination
                perPage={perPage}
                total={filtered.length}
                paginate={paginate}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div>
        {open && item?.mode === "modeOne" && (
          <PendingDiplomaModalModeOne
            diploma={diploma.item}
            item={item}
            handleOpen={handleOpen}
            showUpdate={showUpdate}
            setShowUpdate={setShowUpdate}
            setLoading={setLoading}
            updateDiplomaRejectOrAccept={updateDiplomaRejectOrAccept}
            setOpen={setOpen}
            role={role}
          />
        )}
        {open && item?.mode === "modeTwo" && (
          <PendingDiplomaModalModeTwo
            diploma={diploma.item}
            item={item}
            handleOpen={handleOpen}
            showUpdate={showUpdate}
            setShowUpdate={setShowUpdate}
            setLoading={setLoading}
            updateDiplomaRejectOrAccept={updateDiplomaRejectOrAccept}
            setOpen={setOpen}
            role={role}
          />
        )}
        {open && item?.mode === "modeThree" && (
          <PendingDiplomaModalModeThree
            diploma={diploma.item}
            item={item}
            handleOpen={handleOpen}
            showUpdate={showUpdate}
            setShowUpdate={setShowUpdate}
            setLoading={setLoading}
            updateDiplomaRejectOrAccept={updateDiplomaRejectOrAccept}
            setOpen={setOpen}
            role={role}
          />
        )}
      </div>
    </>
  );
}
