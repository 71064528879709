/* eslint-disable no-unused-vars */
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainButton from "../../components/button/MainButton";
import Input from "../../components/input/Input";
import { ADD_FROM_CSV } from "../../store/features/studentsSlice/studentsSlice";
import { convertNumbers2English } from "../../utils/converts/convert";

import { excelSerialNumberToDate } from "../../utils/dateUtils/convertDateTimeStambyFormatDate";
import readDateFromLeftToRight from "../../utils/dateUtils/readDateFromLeftToRight";
import { getTranslation } from "../../utils/translate/translation";
import {
  isNotEmptyAndContainsOnlyLetters,
  isValidDate,
} from "../../utils/Validators/Validators";

const translate = {
  ar: {
    PAGE_TITLE: "إضافة طالب",
    FIRSTNAME: "الاسم الأول ",
    LASTNAME: "اسم العائلة ",
    NIN: "المعرف الوطني ",
    DATE_OF_BRITH: "تاريخ الولادة",
    BRITH_PLACE: "مكان الولادة ",
    GENDER: "الجنس",
    NATIONALITY: "الجنسية",
    EMAIL: "البريد الالكتروني ",
    PHONE: "الهاتف",
    BUTTON_TITLE: "تعديل",
    BACK: "  الرجوع إلى القائمة",
  },
  en: {
    PAGE_TITLE: "Add a student  ",
    FIRSTNAME: "First Name",
    LASTNAME: "Last Name",
    NIN: "Identifier",
    DATE_OF_BRITH: "Birth date",
    BRITH_PLACE: "Place of birth ",
    GENDER: "Sex",
    NATIONALITY: "Nationality",
    EMAIL: "Email",
    PHONE: "Phone",
    BUTTON_TITLE: "Update",
    BACK: "Back to list",
  },
  fr: {
    PAGE_TITLE: "Ajouter un étudiant",
    FIRSTNAME: "Prénom",
    LASTNAME: "Nom",
    NIN: "Identifiant ",
    DATE_OF_BRITH: "Date de naissance",
    BRITH_PLACE: "Lieu de naissance",
    GENDER: "Sexe",
    NATIONALITY: "Nationalité",
    EMAIL: "Email",
    PHONE: "Téléphone",
    BUTTON_TITLE: "Mise à jour",
    BACK: "Retour à la liste",
  },
};
function UpdateOneStudent() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const students = useSelector((state) => state.students);
  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");
  const [CID, setCID] = useState("");
  const [birthdate, setbirthdate] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [isBirthPlaceValid, setIsBirthPlaceValid] = useState(false);
  const [gender, setGender] = useState("");
  const [nationalty, setNationality] = useState("");
  const [isNationalityValid, setIsNationalityValid] = useState(false);
  const [numTel, setNumTel] = useState("");
  const [isNumTelValid, setIsNumTelValid] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  /**
   * @function initializeStudentData
   * @description this function to initialise Student Data
   *
   * @return
   */
  const initializeStudentData = () => {
    setName(
      students?.studentSelected?.firstName
        ? students.studentSelected?.firstName
        : ""
    );
    setSurName(
      students?.studentSelected?.lastName
        ? students.studentSelected?.lastName
        : ""
    );
    setCID(students?.studentSelected?.nin ? students.studentSelected?.nin : "");
    setbirthdate(
      students?.studentSelected?.dateOfBirth
        ? students.studentSelected?.dateOfBirth
        : ""
    );
    setGender(
      students?.studentSelected?.sex ? students?.studentSelected?.sex : ""
    );
    setNationality(
      students?.studentSelected?.nationalty
        ? students.studentSelected?.nationalty
        : ""
    );
    setNumTel(
      students?.studentSelected?.phone
        ? students.studentSelected?.phone?.toString()
        : ""
    );
    setEmail(
      students?.studentSelected?.email ? students?.studentSelected?.email : ""
    );
    setBirthPlace(
      students?.studentSelected?.countryOfBirth
        ? students.studentSelected?.countryOfBirth
        : ""
    );
  };

  /**
   * @function returnToAddStudentsPage
   * @description this function to return to page add Student
   *
   * @return
   */
  const returnToAddStudentsPage = () => {
    navigate("/add_student");
  };

  /**
   * @function writeDate
   * @description this function to check the date is valid or not
   *
   * @param(date)
   *
   * @return
   */
  function writeDate(date) {
    const dateObj = moment(
      convertNumbers2English(date.toString()),
      "DD-MM-YYYY",
      "YYYY-MM-DD",
      "MM-DD-YYYY"
    );
    const formattedDate = dateObj.format("DD-MM-YYYY");
    return formattedDate;
  }

  /**
   * @function updateStudent
   * @description this function to update student is the store
   *
   * @return
   */
  const updateStudent = () => {
    let studentsTable = [...students.studentsToAdd];
    let updatedStudent = {
      firstName: name,
      lastName: surName,
      nin: CID,
      dateOfBirth:
        writeDate(excelSerialNumberToDate(birthdate)) === "Invalid date"
          ? readDateFromLeftToRight(convertNumbers2English(birthdate))
          : writeDate(excelSerialNumberToDate(birthdate)),
      countryOfBirth: birthPlace,
      nationalty: nationalty,
      email: email,
      sex: gender,
      phone: numTel,
    };
    studentsTable[students.studentSelected.key] = updatedStudent;
    dispatch(ADD_FROM_CSV({ studentsToAdd: studentsTable }));
    navigate("/add_student");
  };

  useEffect(() => {
    if (students.studentSelected === null) {
      returnToAddStudentsPage();
    } else {
      initializeStudentData();
    }
  }, []);

  return (
    <>
      <div className="flex flex-col p-[3%]">
        <h1 className=" text-2xl text-[#25396f] font-semibold">
          {getTranslation(translate, "PAGE_TITLE")}
        </h1>
        <div className="bg-white rounded">
          <div className="items-center justify-center w-full pt-8 lg:flex h-fit">
            <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
              <div className="flex-col items-start justify-center w-full lg:flex">
                <p className="text-md text-[#25396f]">
                  {getTranslation(translate, "FIRSTNAME")}
                </p>
                <Input
                  type={"NAME"}
                  placeholder={name}
                  inputChange={setName}
                  value={name}
                />
              </div>
            </div>
            <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
              <div className="flex-col items-start justify-center w-full lg:flex">
                <p className="text-md text-[#25396f]">
                  {getTranslation(translate, "LASTNAME")}
                </p>
                <Input
                  type={"NAME"}
                  placeholder={surName}
                  inputChange={setSurName}
                  value={surName}
                />
              </div>
            </div>
            <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
              <div className="flex-col items-start justify-center w-full lg:flex">
                <p className="text-md text-[#25396f]">
                  {getTranslation(translate, "NIN")}{" "}
                </p>
                <Input
                  type={"NO_CONTROL"}
                  placeholder={CID}
                  inputChange={setCID}
                  value={CID}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="items-center justify-center w-full pt-8 lg:flex h-fit">
            <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
              <div className="flex-col items-start justify-center w-full lg:flex">
                <p className="text-md text-[#25396f]">
                  {" "}
                  {getTranslation(translate, "DATE_OF_BRITH")}{" "}
                </p>
                <Input
                  type={"DATE_FORMAT"}
                  placeholder={birthdate}
                  inputChange={setbirthdate}
                  value={birthdate}
                />
              </div>
            </div>
            <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
              <div className="flex-col items-start justify-center w-full lg:flex">
                <p className="text-md text-[#25396f]">
                  {" "}
                  {getTranslation(translate, "BRITH_PLACE")}{" "}
                </p>
                <Input
                  type={"NAME"}
                  placeholder={birthPlace}
                  inputChange={setBirthPlace}
                  value={birthPlace}
                  setValid={setIsBirthPlaceValid}
                />
              </div>
            </div>
            <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
              <div className="flex-col items-start justify-center w-full lg:flex">
                <p className="text-md text-[#25396f]">
                  {" "}
                  {getTranslation(translate, "GENDER")}{" "}
                </p>
                <Input
                  type={"NAME"}
                  placeholder={gender}
                  inputChange={setGender}
                  value={gender}
                  isSelect
                  isSelecType={"pays"}
                />
              </div>
            </div>
          </div>
          <div className="items-center justify-center w-full pt-8 lg:flex h-fit">
            <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
              <div className="flex-col items-start justify-center w-full lg:flex">
                <p className="text-md text-[#25396f]">
                  {" "}
                  {getTranslation(translate, "NATIONALITY")}{" "}
                </p>
                <Input
                  type={"NAME"}
                  placeholder={nationalty}
                  inputChange={setNationality}
                  value={nationalty}
                  setValid={setIsNationalityValid}
                />
              </div>
            </div>
            <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
              <div className="flex-col items-start justify-center w-full lg:flex">
                <p className="text-md text-[#25396f]">
                  {" "}
                  {getTranslation(translate, "EMAIL")}{" "}
                </p>
                <Input
                  type={"EMAIL"}
                  placeholder={email}
                  inputChange={setEmail}
                  value={email}
                  setValid={setIsEmailValid}
                />
              </div>
            </div>
            <div className="items-center justify-center mx-2 lg:flex lg:w-1/3">
              <div className="flex-col items-start justify-center w-full lg:flex">
                <p className="text-md text-[#25396f]">
                  {" "}
                  {getTranslation(translate, "PHONE")}{" "}
                </p>
                <Input
                  type={"NUMBER"}
                  placeholder={numTel}
                  inputChange={setNumTel}
                  value={numTel}
                  setValid={setIsNumTelValid}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end w-full">
            <div className="w-40 p-2">
              <MainButton
                title={getTranslation(translate, "BUTTON_TITLE")}
                action={() => {
                  updateStudent();
                }}
                disabled={
                  !isNotEmptyAndContainsOnlyLetters(name) ||
                  !isNotEmptyAndContainsOnlyLetters(surName) ||
                  !isValidDate(birthdate) ||
                  (!isNumTelValid && numTel !== "") ||
                  (!isBirthPlaceValid && birthPlace !== "") ||
                  (!isNationalityValid && nationalty !== "") ||
                  (!isEmailValid && email !== "")
                }
                isLoading={false}
              />
            </div>
            <div className="w-40 p-2">
              <button
                type="submit"
                onClick={() => {
                  navigate("/add_student");
                }}
                className={
                  "w-full h-10 text-black bg-[#dadada] shadow-lg rounded-md hover:bg-[#e7e7e7]"
                }
              >
                {getTranslation(translate, "BACK")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateOneStudent;
