/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import MainButton from "../../components/button/MainButton";

import {
  toastErrorServer,
  toastInfo,
  toastSucces,
} from "../../components/toast/Toast";
import { ADD_DIPLOMAS_FROM_CSV } from "../../store/features/diplomasSlice/diplomasSlice";

import { useAddDiplomasMutation } from "../../store/service/diplomasService";
import ErrorBoundary from "../../utils/ErrorBoundary";
import { getTranslation } from "../../utils/translate/translation";

import AddDiplomasTable from "./AddDiplomasTable";
import AddDiplomasUploader from "./AddDiplomasUploader";
import { useEffect } from "react";
import AddDiplomaErrorModal from "../../components/modal/Diploma/Error/AddDiplomaErrorModal";
import DeleteDiplomaModal from "../../components/modal/Diploma/confirmDiplomaModal/DeleteDiplomaModal";
import ModalLayout from "../../layouts/ModalLayout";
const translate = {
  ar: {
    TOAST_SUCCESS: "تمت العملية بنجاح !",
    TOAST_INFO: "العملية ستستغرق بعض الوقت ",
    TOAST_ERROR: "فترة التسجيل الخاصة بك انتهت. أعد الدخول مجدداً ",
    TOAST_ERROR_SERVER: "هنالك خطأ من السرفر  ",
    TOAST_ERROR_MENU_1: "يرجى إضافة شهائد",
    TOAST_ERROR_MENU_2: "يرجى ألتثبت فالبيانات ، يجب رفع ملف شهادة لكل طالب",
    PAGE_TITLE: "إضافة الشهادات",
    MENU_1: "بيانات الشهادات",
    MENU_2: "ملفات الشهادات",
    MENU_3: "تأكيد الإضافة",
    MESSAGE_:
      " يُرجى التثبت في الجدول أدناه من بيانات الشهادات وتطابقها مع ملفات الPDF. علما أن الضغط على زرّ 'تأكيد الإضافة' سيقوم بتحويل بيانات الشهادات المرتبطة بملفات PDF إلى المدير من أجل الاعتماد. وستبقى باقي البيانات الغير مرتبطة بملفات PDF بطور الانتظار إلى أن يتم ربطها أو حذفها يدويا.",
    BUTTON_TITLE: "تأكيد الإضافة",
    MESSAGE_ONE: "   الرجاء الانتظار ، نحن بصدد إجراء العملية",
  },
  en: {
    TOAST_SUCCESS: "Operation successful!",
    TOAST_INFO: "The operation will take some time",
    TOAST_ERROR: "Your registration period has ended. Please log in again",
    TOAST_ERROR_SERVER: "There is an error from the server",
    TOAST_ERROR_MENU_1: "Please add certificates",
    TOAST_ERROR_MENU_2:
      "Please check the data, a certificate file must be uploaded for each student",
    PAGE_TITLE: "Add Certificates",
    MENU_1: "Certificate Data",
    MENU_2: "Certificate Files",
    MENU_3: "Confirmation",
    MESSAGE_:
      "Please verify the table below for certificate data and match it with the PDF files. Note that clicking on the 'Confirmation' button will submit the certificate data associated with PDF files to the administrator for approval. Any remaining data not linked to PDF files will remain pending until they are manually linked or deleted.",
    BUTTON_TITLE: "Confirmation",
    MESSAGE_ONE: "Please wait, we are processing the operation",
  },
  fr: {
    TOAST_SUCCESS: "Opération réussie !",
    TOAST_INFO: "L'opération prendra un certain temps",
    TOAST_ERROR:
      "Votre période d'inscription est terminée. Veuillez vous reconnecter",
    TOAST_ERROR_SERVER: "Une erreur est survenue du serveur",
    TOAST_ERROR_MENU_1: "Veuillez ajouter des certificats",
    TOAST_ERROR_MENU_2:
      "Veuillez vérifier les données, un fichier de certificat doit être téléchargé pour chaque étudiant",
    PAGE_TITLE: "Ajouter des Certificats",
    MENU_1: "Données des Certificats",
    MENU_2: "Fichiers de Certificats",
    MENU_3: "Confirmation",
    MESSAGE_:
      "Veuillez vérifier le tableau ci-dessous pour les données des certificats et les faire correspondre aux fichiers PDF. Notez que le clic sur le bouton 'Confirmation' soumettra les données de certificat associées aux fichiers PDF à l'administrateur pour approbation. Toute donnée restante non liée aux fichiers PDF restera en attente jusqu'à ce qu'elle soit liée ou supprimée manuellement.",
    BUTTON_TITLE: "Confirmation",
    MESSAGE_ONE: "Veuillez patienter, nous traitons l'opération",
  },
};
const AddDiplomasWithExcel = ({ selectedMenu, setSelectedMenu }) => {
  let dispatch = useDispatch();

  const [missingFields, setMissingFields] = useState([]);
  const [key, setKey] = useState(0);
  const [alreadyExistingDiplomas, setAlreadyExistingDiplomas] = useState([]);
  const [nonExistingStudents, setNonExistingStudents] = useState([]);
  const [DiplomaToDelete, setDiplomaToDelete] = useState({});
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [openError, setOpenError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const mode = useSelector((state) => state.diploma.modeSelected);
  const diploma = useSelector((state) => state.diploma);
  let [addDiplomas] = useAddDiplomasMutation();
  function mergeObjects(arr) {
    const grouped = arr.reduce((acc, curr) => {
      if (!acc[curr.nin]) {
        acc[curr.nin] = {
          nin: curr.nin,
          type: {},
          speciality: {},
          honors: {},
          year: curr.year,
          signatureDate: curr.signatureDate,
          diplomaName: {},
          paperPvDate: curr.paperPvDate,
          internshipPvDate: curr.internshipPvDate,
          examsPvDate: curr.examsPvDate,
          approvalDate: curr.approvalDate,
          average: curr.average,
          mode: mode,
        };
      }
      acc[curr.nin].type[curr.language] = curr.type;
      acc[curr.nin].speciality[curr.language] = curr.speciality;
      acc[curr.nin].honors[curr.language] = curr.honors;
      acc[curr.nin].diplomaName[curr.language] = curr.diplomaName;

      return acc;
    }, {});

    return Object.values(grouped);
  }
  /**
   * @function uploadJSON
   * @description convert table to json
   *
   *
   * @returns data
   */
  const uploadJSON = () => {
    let diplomasData = [...diploma.diplomasToAddTwo];
    diplomasData.shift();
    let data = mergeObjects(diplomasData, mode);
    return data;
  };
  /**
   *
   * @param {*} token
   */
  // eslint-disable-next-line no-unused-vars
  const addDiplomasApiCall = async () => {
    setIsLoading(true);
    await addDiplomas({ diplomasToAdd: uploadJSON(), mode: "modeTwo" }).then(
      async (res) => {
        dispatch(ADD_DIPLOMAS_FROM_CSV({ diplomasToAddTwo: [] }));
        if (res.data) {
          toastSucces(`${getTranslation(translate, "TOAST_SUCCESS")}`);

          setIsLoading(!isLoading);
        } else if (res.error.originalStatus === 502) {
          toastInfo(`${getTranslation(translate, "TOAST_INFO")}`);
        } else {
          toastErrorServer(
            `${getTranslation(translate, "TOAST_ERROR_SERVER")}`
          );

          setIsLoading(false);
        }

        setIsLoading(false);
      }
    );
  };
  /**
   * @function deleteDiploma
   * @description Delete one ligne in the Table
   *
   *
   * @returns
   */
  const deleteDiploma = () => {
    let diplomasTable = [...diploma.diplomasToAddTwo];
    diplomasTable.splice(key, 1);
    dispatch(ADD_DIPLOMAS_FROM_CSV({ diplomasToAddTwo: diplomasTable }));
    setOpenModal(!openModal);
  };
  useEffect(() => {
    if (selectedMenu === 2) {
      setSelectedMenu(2);
    }
  }, [selectedMenu]);
  return (
    <div className="flex flex-col justify-end mt-10 px-[3%] ">
      <h1 className="mb-2 text-2xl font-bold text-blue-900">
        {getTranslation(translate, "PAGE_TITLE")}
      </h1>
      <div className="flex flex-col items-center justify-center w-full p-4 bg-white rounded h-fit">
        <ErrorBoundary>
          <AddDiplomasUploader
            setMissingFields={setMissingFields}
            setNonExistingStudents={setNonExistingStudents}
            setOpen={setOpen}
            setAlreadyExistingDiplomas={setAlreadyExistingDiplomas}
            isLoadingData={isLoadingData}
            setIsLoadingData={setIsLoadingData}
          />
        </ErrorBoundary>
        <div className="w-full">
          <ErrorBoundary>
            <AddDiplomasTable
              isLoadingData={isLoadingData}
              setKey={setKey}
              setOpenModal={setOpenModal}
              setDiplomaToDelete={setDiplomaToDelete}
            />
          </ErrorBoundary>
        </div>
        <div className="flex flex-col w-full p-4 rounded bg-[#f2f7ff] h-fit">
          <p>{getTranslation(translate, "MESSAGE_")}</p>
          <div className="w-40 py-4">
            <MainButton
              title={getTranslation(translate, "BUTTON_TITLE")}
              action={() => {
                addDiplomasApiCall();
              }}
              isLoading={isLoading}
              disabled={diploma?.diplomasToAddTwo?.length == 0}
            />
          </div>
        </div>
      </div>
      {open && (
        <AddDiplomaErrorModal
          missingFields={missingFields}
          setOpen={setOpen}
          nonExistingStudents={nonExistingStudents}
          setMissingFields={setMissingFields}
          alreadyExistingDiplomas={alreadyExistingDiplomas}
          setAlreadyExistingDiplomas={setAlreadyExistingDiplomas}
          open={open}
          setNonExistingStudents={setNonExistingStudents}
        />
      )}
      {openModal && (
        <ModalLayout>
          <DeleteDiplomaModal
            setOpenModal={setOpenModal}
            openModal={openModal}
            deleteDiploma={deleteDiploma}
            DiplomaToDelete={DiplomaToDelete}
          />
        </ModalLayout>
      )}
    </div>
  );
};
AddDiplomasWithExcel.propTypes = {
  selectedMenu: PropTypes.number,
  setSelectedMenu: PropTypes.func,
};
export default AddDiplomasWithExcel;
