import { useRef, useState } from "react";
import DiplomaTemplate from "./DiplomaTemplate";
import PropTypes from "prop-types";
import LanguageChooser from "../../components/language-chooser/LanguageChooser";
// ES2015 module syntax
import ReactToPrint from "react-to-print";
import PrintButton from "../../components/PrintButton/PrintButton";
import FantomScannerLink from "../../components/fantom-scanner-link/FantomScannerLink";
import {
  getCommunValues,
  isThereCommunValues,
} from "../../utils/translate/multilanguage-reader";
import { Fragment } from "react";

function DigitalDiplomaPreview({ diploma }) {
  const diplomaTypeKey = Object.keys(diploma?.type);
  const FirstNameKey = Object.keys(diploma?.student?.firstName);

  const communValues = getCommunValues(diplomaTypeKey, FirstNameKey);

  const [selectedLanguage, setSelectedLanguage] = useState(
    Object.values(communValues).length === 0
      ? "en"
      : Object.values(communValues)[0]
  );

  let componentRef = useRef(null);

  return (
    <div className="flex items-start justify-between w-full h-full p-1 ">
      <div className="flex items-center justify-between h-full w-fit ">
        <div className="flex flex-col items-start justify-start h-full p-1 ">
          {diploma?.status === "accepted" &&
            isThereCommunValues(diplomaTypeKey, FirstNameKey) === true && (
              <ReactToPrint
                trigger={() => (
                  <div>
                    <PrintButton />
                  </div>
                )}
                content={() => componentRef.current}
              />
            )}

          {diploma?.student &&
            isThereCommunValues(diplomaTypeKey, FirstNameKey) === true && (
              <LanguageChooser
                setSelectedLanguage={setSelectedLanguage}
                firstnameObject={diploma?.student?.firstName}
                diplomaType={diploma?.type}
              />
            )}
          {diploma?.status === "accepted" &&
            isThereCommunValues(diplomaTypeKey, FirstNameKey) === true && (
              <FantomScannerLink hashTx={diploma?.hashTx} />
            )}
        </div>
      </div>
      {isThereCommunValues(diplomaTypeKey, FirstNameKey) === true ? (
        <div ref={componentRef} className=" ">
          <DiplomaTemplate
            diploma={diploma}
            selectedLanguage={selectedLanguage}
          />
        </div>
      ) : (
        <Fragment>
          <div className="">
            <div className="text-[#dc3545] font-semibold flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                />
              </svg>

              <span className="text-[#dc3545] font-semibold">
                بيانات الطالب والشهادة مسجلة بلغات مختلفة
              </span>
            </div>
            <div className="">
              <span className="text-[#657584] font-medium">
                لغات الطلاب المحفوظة :
              </span>
              <div className="pt-1">
                {FirstNameKey.map((language, key) => (
                  <Fragment key={key}>
                    <div className="flex w-full h-full items-center gap-1">
                      <div className="w-2 h-2 bg-[#607080] rounded-lg "></div>
                      <div className="flex gap-2">
                        <span>
                          {language === "ar"
                            ? "العربية"
                            : language === "fr"
                            ? "Français"
                            : "Anglais"}
                        </span>
                      </div>
                    </div>
                  </Fragment>
                ))}
              </div>
              <span className="text-[#657584] font-medium">
                لغات شهادة المحفوظة :
              </span>
              <div className="pt-1">
                {diplomaTypeKey.map((language, key) => (
                  <Fragment key={key}>
                    <div className="flex w-full h-full items-center gap-1">
                      <div className="w-2 h-2 bg-[#607080] rounded-lg "></div>
                      <div className="flex gap-2">
                        <span>
                          {language === "ar"
                            ? "العربية"
                            : language === "fr"
                            ? "Français"
                            : "Anglais"}
                        </span>
                      </div>
                    </div>
                  </Fragment>
                ))}
              </div>
              <span>ملاحظة: تحتاج إلى حفظ الدبلوم بلغة الطالب المحفوظة</span>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}
DigitalDiplomaPreview.propTypes = {
  diploma: PropTypes.object,
};
export default DigitalDiplomaPreview;
